import { PHONE_VALIDATION_RULE, ZIP_VALIDATION_RULE } from '@med-fe/util';

export const locationConfig: any = (isCreateMode = false) => ({
  region: {
    label: 'Region',
    isVisible: true,
    type: 'select',
    placeholder: 'Select a Region',
  },
  district: {
    label: 'District',
    isVisible: true,
    type: 'select',
    placeholder: 'Select a District',
  },
  county: {
    label: 'County',
    isVisible: true,
  },
  locationName: {
    label: 'Location Name',
    isVisible: true,
    type: 'text',
    rules: [{ required: true, message: 'Please enter Location Name' }],
  },
  locationNumber: {
    label: 'Location Number',
    isVisible: isCreateMode,
    rules: [{ required: true, message: 'Please enter Location Number' }],
  },
  street: {
    label: 'Street',
    isVisible: true,
  },
  street2: {
    label: 'Street 2',
    isVisible: true,
  },
  city: {
    label: 'City',
    isVisible: true,
  },
  state: {
    label: 'State',
    isVisible: true,
    rules: [{ required: true, message: 'Please enter State' }],
  },
  zip: {
    label: 'ZIP',
    isVisible: true,
    rules: [ZIP_VALIDATION_RULE],
  },
  phone: {
    label: 'Phone',
    isVisible: true,
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
  },
  fax: {
    label: 'Fax',
    isVisible: true,
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
  },
  directDial: {
    label: 'Direct Dial',
    isVisible: true,
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
  },
  timezone: {
    label: 'Timezone',
    isVisible: true,
  },
  status: {
    label: 'Status',
    isVisible: true,
    type: 'select',
  },
  ctc: {
    label: 'CTC',
    isVisible: true,
    type: 'checkbox',
  },
});
