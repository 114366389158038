import React from 'react';
import styled from 'styled-components';

function ExpandIcon({ isActive }) {
  return (
    <StyledImg
      isActive={isActive}
      src={'./icons/arrow.svg'}
      alt={isActive ? 'expanded' : 'collapsed'}
    />
  )
}

const StyledImg = styled.img`
  transform: ${({ isActive }) => `translateY(-50%) rotate(${isActive ? 0 : 180}deg)`};
  transition: transform 0.24s;
  position: absolute;
  top: 50%;
  right: 16px;
`;

export default ExpandIcon;
