/* eslint-disable @typescript-eslint/no-explicit-any */
import { BASE_URL } from './constants/api.constants';
import axios from 'axios';

class Api {
  generateFullUrl(url: string) {
    const urlObj = new URL(`${BASE_URL}${url}`);
    return urlObj.toString();
  }

  get = async (url: string) => {
    const response = await axios.get(this.generateFullUrl(url));

    return response.data;
  };

  post = async (url: string, payload: any, params?: any) => {
    const response = await axios.post(this.generateFullUrl(url), payload, { params });

    return response.data;
  };

  delete = async (url: string, payload?: any) => {
    const response = await axios.delete(this.generateFullUrl(url), { data: payload });

    return response.data;
  };

  put = async (url: string, payload: any, params?: any) => {
    const response = await axios.put(this.generateFullUrl(url), payload, { params });

    return response.data;
  };

  patch = async (url: string, payload?: any) => {
    const response = await axios.patch(this.generateFullUrl(url), payload);

    return response.data;
  };

  downloadFile = async (url: string, payload: any, method?) => {
    const { fileName, type, ...data } = payload;

    await axios({
      url: this.generateFullUrl(url),
      data,
      method: method || 'POST',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName || 'file'}.${type || 'xlsx'}`);
      document.body.appendChild(link);
      link.click();
    });
  };
}

export default new Api();
