import {
  FETCH_DOCTORS,
  FETCH_DOCTORS_FAILED,
  FETCH_DOCTORS_SUCCESS,
  SET_SEARCH_DOCTORS,
  FETCH_DOCTOR_BY_ID,
  FETCH_DOCTOR_BY_ID_SUCCESS,
  FETCH_DOCTOR_BY_ID_FAILED,
  UPDATE_DOCTOR,
  UPDATE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAILED,
  UPLOAD_DOCTOR_PHOTO,
  UPLOAD_DOCTOR_PHOTO_SUCCESS,
  UPLOAD_DOCTOR_PHOTO_FAILED,
  SET_SELECTED_DOCTOR_SPECIALTIES,
} from './doctors-actions';
import { DEFAULT_GRID_SIZE } from '@med-fe/util';
import { DoctorDto } from '../../models/doctor-dto';
import { Paginated } from '../../models/pagination-dto';
import { SpecialtyEquipmentDto } from '../../models/specialty-equipment-dto';

export const DOCTORS_STATE_KEY = 'DOCTORS_REDUCER';

export interface IDoctorsState {
  loading: boolean;
  loadingDoctor: boolean;
  doctors: Paginated<DoctorDto>;
  doctor: any;
  search: ISearchDoctors;
  error: any;
  selectedDoctorSpecialties: {
    specialtyProgramPart: boolean;
    specialties: SpecialtyEquipmentDto[];
  } | null;
}

const defaultDoctors = {
  total: 0,
  pages: 1,
  contents: [],
};

export interface ISearchDoctors {
  page: number;
  search: string;
  size: number;
  employeeSearch: string;
  regionIds: any[];
  districtIds: any[];
}

export const defaultSearchDoctors: ISearchDoctors = {
  page: 0,
  search: '',
  size: DEFAULT_GRID_SIZE,
  employeeSearch: '',
  regionIds: [],
  districtIds: [],
};

const defaultState: IDoctorsState = {
  loading: false,
  loadingDoctor: false,
  doctors: defaultDoctors,
  doctor: {},
  search: defaultSearchDoctors,
  error: null,
  selectedDoctorSpecialties: null,
};

export const doctorsReducer = (state = defaultState, { type, payload }: any) => {
  switch (type) {
    case FETCH_DOCTORS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        doctors: payload,
      };
    case FETCH_DOCTORS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case SET_SEARCH_DOCTORS:
      return {
        ...state,
        search: { ...state.search, ...payload },
      };
    case FETCH_DOCTOR_BY_ID:
      return {
        ...state,
        loadingDoctor: true,
      };
    case FETCH_DOCTOR_BY_ID_SUCCESS:
      return {
        ...state,
        loadingDoctor: false,
        doctor: payload,
      };
    case FETCH_DOCTOR_BY_ID_FAILED:
      return {
        ...state,
        loadingDoctor: false,
        error: payload,
      };
    case UPDATE_DOCTOR:
      return {
        ...state,
        loadingDoctor: true,
      };
    case UPDATE_DOCTOR_SUCCESS:
      const updatedDoctors = state.doctors.contents.map((doctor) =>
        doctor.id === payload.id
          ? {
              ...doctor,
              id: payload.id,
              name: payload.name,
              employeeNumber: payload.employeeNumber,
              npi: payload.npi,
              startDate: payload.startDate,
              endDate: payload.endDate,
              photoUrl: payload.photoUrl,
              status: payload.status,
            }
          : doctor
      );

      return {
        ...state,
        loadingDoctor: false,
        doctor: payload,
        doctors: {
          ...state.doctors,
          contents: updatedDoctors,
        },
      };
    case UPDATE_DOCTOR_FAILED:
      return {
        ...state,
        loadingDoctor: false,
        error: payload,
      };
    case UPLOAD_DOCTOR_PHOTO:
      return {
        ...state,
        loadingDoctor: true,
      };
    case UPLOAD_DOCTOR_PHOTO_SUCCESS:
      const updatedDoctorsImage = state.doctors.contents.map((doctor) =>
        doctor.id === payload.id
          ? {
              ...doctor,
              photoUrl: payload.photoUrl,
            }
          : doctor
      );

      return {
        ...state,
        loadingDoctor: false,
        doctors: {
          ...state.doctors,
          contents: updatedDoctorsImage,
        },
      };
    case UPLOAD_DOCTOR_PHOTO_FAILED:
      return {
        ...state,
        loadingDoctor: false,
        error: payload,
      };
    case SET_SELECTED_DOCTOR_SPECIALTIES:
      return {
        ...state,
        selectedDoctorSpecialties: payload,
      };
    default:
      return state;
  }
};
