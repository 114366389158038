import styled from 'styled-components';

export const StyledDatesWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
  }

  & > span {
    width: 20px;
  }
`;

export const StyledArrowWrapper = styled.span`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 5px;
`;
