import moment, { Moment } from 'moment';

export const eightWeeksNumbers: number[] = [0, 1, 2, 3, 4, 5, 6, 7];

export const getMomentDate = (date) => moment(date);

export const getDateFromMoment = (date) => moment(date).format('yyyy-MM-DD');

export const getIsSameOrBefore = (currentDate, date) => moment(currentDate).isSameOrBefore(date, 'd');

export const getInvalidDates = (date: Moment) => {
  return (
    date < moment('31/12/2000', 'DD/MM/YYYY').endOf('day') || date > moment('31/12/2050', 'DD/MM/YYYY').endOf('day')
  );
};
