export const internetEquipmentDetailsConfig: any = () => ({
  routerModel: {
    label: 'Router Model',
    type: 'text',
  },
  routerModelCount: {
    label: 'Router Model Count',
    type: 'text',
  },
  switches: {
    label: 'Switches',
    type: 'text',
  },
  waps: {
    label: 'WAPs',
    type: 'text',
  },
  vvxPhones: {
    label: 'VVX Phones',
    type: 'text',
  },
  cordlessPhones: {
    label: 'Cordless Phones',
    type: 'text',
  },
});
