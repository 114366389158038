import styled from 'styled-components';
import { Drawer } from 'antd';

export const StyledDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0;
  }

  & .ant-list-item {
    display: flex;
    font-size: 12px;
    align-items: center;
  }
`;
