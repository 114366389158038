import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { capitalize } from 'lodash';
import { defaultSearchAudit, getSearchAudit } from '../../audit/audit-reducer';
import { setSearchAudit } from '../../audit/audit-actions';
import { AuditTypeCell } from '@med-fe/ui';
import { SuffixIcon, TagRenderer } from '@med-fe/ui';
import { getInvalidDates } from '@med-fe/util';

const { RangePicker } = DatePicker;
const { Option } = Select;

function SearchAudit() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const search = useSelector(getSearchAudit);

  const onSubmit = (formData) => {
    const { dateRange, ...data } = formData;
    const dates = {
      from: null,
      to: null,
    };
    if (dateRange) {
      dates['from'] = dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null;
      dates['to'] = dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null;
    }
    const payload = { ...defaultSearchAudit, size: search.size, ...data, ...dates };

    dispatch(setSearchAudit(payload));
  };

  useEffect(() => {
    return () => {
      dispatch(setSearchAudit(defaultSearchAudit));
    };
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      className='search-container'
      onFinish={(formData) => {
        onSubmit(formData);
      }}
    >
      <Form.Item name='dateRange' label={capitalize('select date range')}>
        <RangePicker
          allowEmpty={[true, true]}
          format={['YYYY-MM-DD']}
          separator='-'
          disabledDate={getInvalidDates}
          onChange={(value) => {
            if (!value) {
              form.resetFields(['dateRange']);
            }
          }}
          inputReadOnly
        />
      </Form.Item>
      <Form.Item key='user' label={capitalize('search by user')} name='user' className='label-text'>
        <Input
          allowClear
          onChange={(value) => {
            if (!value.target.value.trim()) {
              form.setFieldsValue({ user: null });
            }
          }}
          placeholder='Name, Surname or Id'
        />
      </Form.Item>
      <Form.Item
        key='actions'
        label={capitalize('select action type')}
        name='actions'
        className='label-text search-action'
      >
        <Select
          allowClear
          showArrow
          suffixIcon={<SuffixIcon />}
          mode={'multiple'}
          tagRender={(props) => <TagRenderer {...props} />}
          maxTagCount={'responsive'}
        >
          {['Create', 'Delete', 'Update', 'Login'].map((action) => (
            <Option key={action} value={action}>
              <AuditTypeCell value={action} />
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item key='area' label={capitalize('select area')} name='area' className='label-text search-action'>
        <Select allowClear suffixIcon={<SuffixIcon />}>
          {[
            'Address',
            'Billing',
            'Contact',
            'Contact Role',
            'Location',
            'Schedule',
            'Location Infra',
            'Location Veh',
          ].map((area) => (
            <Option key={area} value={area.replace(/\s/g, '')}>
              {area}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item key='location' label={capitalize('search by location number')} name='location' className='label-text'>
        <Input
          onChange={(value) => {
            if (!value.target.value.trim()) {
              form.setFieldsValue({ location: null });
            }
          }}
          allowClear
          placeholder='location number'
        />
      </Form.Item>
      <Form.Item className='search-btn'>
        <Button type='primary' htmlType='submit'>
          SEARCH
        </Button>
      </Form.Item>
    </Form>
  );
}

export default SearchAudit;
