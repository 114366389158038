export const internetLanIPDetailsConfig: any = () => ({
  lanSubnet: {
    label: 'LAN Subnet',
    type: 'text',
  },
  gatewayDHCP: {
    label: 'Gateway DHCP',
    type: 'text',
  },
  range: {
    label: 'Range',
    type: 'text',
  },
});
