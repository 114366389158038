import React from 'react';
import styled from 'styled-components';
import { InfoSection } from './details/InfoSection';

export const Statistics = () => {
  return (
    <>
      <StyledPageWrapper>
        {['Offices', 'Doctors', 'Active Users'].map((title) => (
          <InfoSection key={title} sectionTitle={title} />
        ))}
      </StyledPageWrapper>

      <StyledLogoWrapper>
        <img alt='logo' src={'./images/logo.svg'} />
      </StyledLogoWrapper>
    </>
  );
};

const StyledPageWrapper = styled.div`
  height: calc(100% + 30px);
  overflow-y: auto;
  padding-right: 15px;
`;

const StyledLogoWrapper = styled.div`
  width: 100%;
  padding: 10px 40px 20px;
  display: flex;
  justify-content: flex-end;
`;
