import {ScheduleDto} from '../../models/schedule-dto';
import {get} from 'lodash';

export const mapSchedulesToForm = (schedules: ScheduleDto[]) => {
  return schedules.reduce((result, current) => {
    const open = get(current, 'open', '').split(' ');
    const close = get(current, 'close', '').split(' ');

    return {
      ...result,
      [current.dayOfWeek]: {
        openTime: get(open, '[0]', null),
        closeTime: get(close, '[0]', null),
        openHours: get(open, '[1]') !== 'AM',
        closeHours: get(close, '[1]') === 'PM',
        statusClosed: false,
        validFrom: current.validFrom,
        id: current.id,
      }
    }
  }, {
    Sunday: {statusClosed: true, openHours: false, closeHours: true, openTime: null, closeTime: null},
    Monday: {statusClosed: true, openHours: false, closeHours: true, openTime: null, closeTime: null},
    Tuesday: {statusClosed: true, openHours: false, closeHours: true, openTime: null, closeTime: null},
    Wednesday: {statusClosed: true, openHours: false, closeHours: true, openTime: null, closeTime: null},
    Thursday: {statusClosed: true, openHours: false, closeHours: true, openTime: null, closeTime: null},
    Friday: {statusClosed: true, openHours: false, closeHours: true, openTime: null, closeTime: null},
    Saturday: {statusClosed: true, openHours: false, closeHours: true, openTime: null, closeTime: null},
  });
};
