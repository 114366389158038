export const internetAccessDetailsConfig: any = () => ({
  provider: {
    label: 'Provider',
    type: 'text',
  },
  speed: {
    label: 'Speed',
    type: 'text',
  },
  circuitType: {
    label: 'Circuit Type',
    type: 'text',
  },
  mspStatus: {
    label: 'MSP Status',
    type: 'text',
  },
  completionDate: {
    label: 'Completion Date',
    type: 'date',
  },
  notes: {
    label: 'Notes',
    type: 'text',
  },
  providerSecondary: {
    label: 'Provider Secondary',
    type: 'text',
  },
  speedSecondary: {
    label: 'Speed Secondary',
    type: 'text',
  },
  typeSecondary: {
    label: 'Type Secondary',
    type: 'text',
  },
});
