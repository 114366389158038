import { TagRenderer } from '@med-fe/ui';
import { CheckBoxCell } from '@med-fe/ui';
import { StatusesEnum } from '../../../models/statuses-enum';

export const locations_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
    width: 100,
  },
  {
    title: 'District',
    dataIndex: 'district',
    key: 'district',
    width: 100,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: 500,
  },
  {
    title: 'direct dial',
    dataIndex: 'directDial',
    key: 'directDial',
    width: 110,
  },
  {
    title: 'Timezone',
    dataIndex: 'timezone',
    key: 'timezone',
    width: 80,
  },
  {
    title: 'County',
    dataIndex: 'county',
    key: 'county',
    width: 135,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (value) => <TagRenderer label={StatusesEnum[value]} value={value} closable={false} onClose />,
  },
  {
    title: 'CTC',
    dataIndex: 'ctc',
    key: 'ctc',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {}, // empty column, workaround to have column width fixed
];
