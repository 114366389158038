import { put, call } from 'redux-saga/effects';
import {
  createAddressFailed,
  createAddressSuccess,
  deleteAddressFailed,
  deleteAddressSuccess,
  fetchAddressFailed,
  fetchAddressSuccess,
} from './address-actions';
import { api } from '@med-fe/util';
import { fetchLocations } from '../../components/offices/offices-actions';

export function* fetchAddressStartWorker(): Generator {
  try {
    const url = '/address';
    const data = yield call(api.get, url);
    yield put(fetchAddressSuccess(data));
  } catch (e) {
    yield put(fetchAddressFailed(e));
  }
}

export function* createAddressStartWorker({ payload: { payload, page } }: any): Generator {
  try {
    const url = '/address';
    const data = yield call(api.post, url, payload);
    yield put(createAddressSuccess(payload));
    yield put(fetchLocations({ page: page - 1 }));
  } catch (e) {
    yield put(createAddressFailed(e));
  }
}

export function* deleteAddressStartWorker({ payload }: { payload: string }): Generator {
  try {
    const url = `/address/${payload}`;
    const data = yield call(api.delete, url);
    yield put(deleteAddressSuccess(payload));
  } catch (e) {
    yield put(deleteAddressFailed(e));
  }
}
