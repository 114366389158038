export const marketingConfig = {
  branding: {
    label: 'Branding',
    type: 'text',
  },
  mediaMarket: {
    label: 'Media Market',
    type: 'text',
  },
  msa: {
    label: 'MSA',
    type: 'text',
  },
  top5zip: {
    label: 'Top5',
    type: 'text',
  },
  digitalMetroCategory: {
    label: 'Digital Metro Category',
    type: 'text',
  },
};
