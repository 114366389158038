import { CheckBoxCell } from '@med-fe/ui';
export const pro_services_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'OD on-call',
    dataIndex: 'odOnCall',
    key: 'odOnCall',
    width: 350,
  },
  {
    title: 'on-call phone number',
    dataIndex: 'onCallPhone',
    key: 'onCallPhone',
    width: 140,
  },
  {
    title: 'Dry Eye Program',
    dataIndex: 'dryEyeProgram',
    key: 'dryEyeProgram',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'Preceptor Location',
    dataIndex: 'preceptorLocation',
    key: 'preceptorLocation',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {}, // empty column, workaround to have column width fixed
];
