import { Button, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDistrict, createRegion } from '../../offices/offices-actions';
import { capitalize, get } from 'lodash';
import { getRegions } from '../../offices/offices-selectors';
import { getIsWriteAllAllowed } from '../../../store/user/user-reducer';
import { SuffixIcon } from '@med-fe/ui';

const { Option } = Select;

function AreaRegionDistrictDetails({ modalData }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const regions = useSelector(getRegions);
  const isEditAllowed = useSelector(getIsWriteAllAllowed);

  const onSubmit = (formData: any) => {
    const payload = {
      id: get(modalData, 'id', null),
      ...formData,
    };

    if (modalData.key === 'region') {
      dispatch(createRegion(payload));
    } else {
      dispatch(createDistrict(payload));
    }
  };

  return (
    <Form
      form={form}
      className='region-district-details-form'
      layout='vertical'
      onValuesChange={() => setIsDisabled(false)}
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
        modalData.onClose();
      }}
      initialValues={{ name: modalData.name, ...(!!modalData.regionId && { regionId: modalData.regionId }) }}
    >
      {modalData.key === 'district' && (
        <Form.Item
          key='regionId'
          label='Region'
          name='regionId'
          rules={[{ required: true, message: 'Please select Region' }]}
        >
          <Select placeholder='Select region' suffixIcon={<SuffixIcon />}>
            {regions.map((item: any) => {
              const key = get(item, 'id', item[0]);
              const value = get(item, 'name', item[1]);
              return (
                <Option key={key} value={key}>
                  {value}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        key='name'
        label='Name'
        name='name'
        rules={[{ required: true, message: `Please enter ${capitalize(modalData.key)} Name` }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AreaRegionDistrictDetails;
