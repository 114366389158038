import { Button } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  padding: 4px 0;
`;

export const ExportImportButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-items: start;
  justify-content: start;

  &:disabled {
    pointer-events: none;
    color: #003366;

    &:not(.ant-btn-loading) {
      opacity: 0.5;
    }
  }

  &.ant-btn-loading::before {
    background: #dbeff9;
    opacity: 0.5;
  }

  img {
    margin-right: 3px;
  }
`;

export const ExportImportButtonText = styled.span`
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  margin-right: 5px;
  display: flex;
  justify-items: start;
  justify-content: start;
`;

export const ExportLoaderBox = styled.span`
  margin-right: 5px;
  width: 20px;
`;
