export const FETCH_AUDIT = `FETCH_AUDIT`;
export const FETCH_AUDIT_SUCCESS = `FETCH_AUDIT_SUCCESS`;
export const FETCH_AUDIT_FAILED = `FETCH_AUDIT_FAILED`;

export const SET_SEARCH_AUDIT = 'SET_SEARCH_AUDIT';

export const fetchAudit = (payload: any) => ({
  type: FETCH_AUDIT,
  payload,
});

export const fetchAuditSuccess = (payload: any) => ({
  type: FETCH_AUDIT_SUCCESS,
  payload,
});

export const fetchAuditFailed = (payload: any) => ({
  type: FETCH_AUDIT_FAILED,
  payload,
});

export const setSearchAudit = (payload) => ({
  type: SET_SEARCH_AUDIT,
  payload,
});
