export const PERMISSIONS = {
  readAll: 'read',
  writeAll: 'write',
  locations: { read: ['mod_loc'], write: ['mod_loc_write'] },
  profile: { read: ['mod_profile'], write: ['mod_profile_write'] },
  finance: { read: ['mod_finance'], write: ['mod_finance_write'] },
  marketing: { read: ['mod_marketing'], write: ['mod_marketing_write'] },
  schedule: { read: ['mod_schedule'], write: ['mod_schedule_write'] },
  mvc: { read: ['mod_mvc'], write: ['mod_mvc_write'] },
  pro: { read: ['mod_proserv'], write: ['mod_proserv_write'] },
  it: { read: ['mod_it'], write: ['mod_it_write'] },
  veh: { read: ['mod_veh'], write: ['mod_veh_write'] },
  telecom: { read: ['mod_telecom'], write: ['mod_telecom_write'] },
  specialty: { read: ['mod_loc', 'mod_veh'], write: ['mod_loc_write', 'mod_veh_write'] },
  map: { read: [], write: [] },
  doctors: { read: ['od_dir'], write: ['od_dir_write'] },
  contact: { write: ['mod_loc_contact_write'] },
};
