import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Dropdown, Menu, notification } from 'antd';
import { AuthService, loginRequest } from '@med-fe/data';
import { ProfileButton, ProfileIcon } from '../styled';
import { UserOutlined } from '@ant-design/icons';

export const Profile = ({ profilePanel }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const userName = (accounts && accounts.length && accounts[0]?.name) || '';

  const onSignHandler = () => {
    if (isAuthenticated) {
      notification.destroy();
      instance
        .logoutPopup()
        .then(() => localStorage.removeItem('accessToken'))
        .catch((e) => {
          console.error(e);
        });
    } else {
      instance
        .loginPopup(loginRequest)
        .then(() => AuthService.refreshAccessToken())
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const menu = (
    <Menu className={'profile-menu'}>
      {isAuthenticated && accounts.length ? (
        <>
          <p className='username'>{userName}</p>
          {/* <Menu.Item key='signOut'>
            <Button type={'link'} onClick={onSignHandler}>
              <span>Sign out</span>
            </Button>
          </Menu.Item> */}
        </>
      ) : (
        <Menu.Item key='signIn'>
          <Button type={'link'} onClick={onSignHandler}>
            <span>Sign in</span>
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );

  const dropdown = (
    <Dropdown overlay={menu} trigger={['click']}>
      <ProfileButton type='primary' shape='circle' icon={<UserOutlined />} />
    </Dropdown>
  );

  const profilePanelRender = profilePanel && profilePanel({ accounts, onSignHandler });

  return (
    <>
      {profilePanelRender && (!isAuthenticated ? dropdown : profilePanelRender)}
      {!profilePanelRender && dropdown}
    </>
  );
};
