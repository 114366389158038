import React from 'react';
import { Button, Tooltip, Badge } from 'antd';
import styled from 'styled-components';

const AdvancedSearchButton = ({ onClick, active, filtersApplied }) => {
  const tooltipMessage = !active
    ? 'Return to ordinary search'
    : filtersApplied
    ? `Applied additional filters: ${filtersApplied} `
    : 'Go to advanced search';
  return (
    <Tooltip
      placement='bottom'
      color={'white'}
      overlayInnerStyle={{ color: '#003366' }}
      overlayStyle={{ fontSize: '10px' }}
      title={tooltipMessage}
    >
      <Badge dot={!!filtersApplied} color='white'>
        <StyledButton type='primary' onClick={onClick} className='btn-advanced-search'>
          <img src='./icons/advanced-search.svg' alt='adv-search' />
        </StyledButton>
      </Badge>
    </Tooltip>
  );
};

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0 1px 0 1px;
`;

export default AdvancedSearchButton;
