import { Card } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const SectionCard = ({ cardInfo, period }) => {
  const officeStatusesMap = {
    Active: 'Opened',
    Closed: 'Closed',
    Hold: 'Pending Open',
  };
  const isClosedOffice = cardInfo.title === 'Closed';
  const title = officeStatusesMap.hasOwnProperty(cardInfo.title) ? officeStatusesMap[cardInfo.title] : cardInfo.title;

  let diffClass = cardInfo.difference < 0 ? 'decrease' : cardInfo.difference > 0 ? 'increase' : '';
  let diffText = `${diffClass === 'increase' ? '+' : ''}${cardInfo.difference} %`;

  if (isClosedOffice && cardInfo.difference < 0) {
    diffClass = 'increase';
  } else if (isClosedOffice && cardInfo.difference > 0) {
    diffClass = 'decrease';
    diffText = `+${cardInfo.difference} %`;
  }

  return (
    <StyledCard title={title} bordered={false}>
      <StyledTotal>
        {cardInfo.total} <span> current date</span>
      </StyledTotal>
      <StyledDiff>
        <span className={`difference ${diffClass}`}>{diffText}</span>
        <span>last {period}</span>
      </StyledDiff>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: 10px;
  width: 230px;
  max-height: 120px;
  background-color: rgb(55 146 208 / 9%);

  .ant-card-head {
    padding: 0 15px;
    min-height: 24px;
    border: none;

    .ant-card-head-title {
      padding: 10px 0;
      color: #40668c;
      font-weight: bold;
    }
  }

  .ant-card-body {
    padding: 0 20px 10px;
    height: 100px;
  }
`;

const StyledTotal = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 10px;

  span {
    font-size: 14px;
    font-weight: normal;
  }
`;

const StyledDiff = styled.div`
  .difference {
    padding: 2px;
    border-radius: 3px;
    margin-right: 8px;
    font-weight: bold;

    &.increase {
      color: #45a58e;
      background-color: rgba(69, 165, 142, 0.1);
    }

    &.decrease {
      color: #d14d4d;
      background-color: rgba(209, 77, 77, 0.1);
    }
  }
`;
