export const marketing_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'branding',
    dataIndex: 'branding',
    key: 'branding',
    width: 120,
  },
  {
    title: 'media market',
    dataIndex: 'mediaMarket',
    key: 'mediaMarket',
    width: 220,
  },
  {
    title: 'msa',
    dataIndex: 'msa',
    key: 'msa',
    width: 200,
  },
  {
    title: 'top 5',
    dataIndex: 'top5zip',
    key: 'top5zip',
    width: 120,
  },
  {
    title: 'digital metro category',
    dataIndex: 'digitalMetroCategory',
    key: 'digitalMetroCategory',
    width: 150,
  },
  { }, // empty column, workaround to have column width fixed
];
