import { Avatar } from 'antd';
import React from 'react';
import { TagRenderer } from '@med-fe/ui';
import { DoctorStatusEnum } from '../../../models/doctor-status-enum';
import { SpecialtiesCell } from '../cell-components/SpecialtiesCell';

export const doctors_columns = [
  {
    title: '',
    dataIndex: 'photoUrl',
    key: 'photoUrl',
    width: 58,
    className: 'avatar-cell',
    render: (value) => <Avatar src={value ? value : './icons/avatar-inactive.svg'} size={32} />,
  },
  {
    title: 'Employee Name',
    dataIndex: 'name',
    key: 'action',
    width: 180,
  },
  {
    title: 'Employee Number',
    dataIndex: 'employeeNumber',
    key: 'employeeNumber',
    width: 120,
  },
  {
    title: 'NPI',
    dataIndex: 'npi',
    key: 'npi',
    width: 100,
  },
  {
    title: 'Office Number',
    dataIndex: 'locationNumber',
    key: 'locationNumber',
    width: 225,
  },
  {
    title: 'District',
    dataIndex: 'district',
    key: 'district',
    width: 150,
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
    width: 150,
  },
  {
    title: 'Location City',
    dataIndex: 'city',
    key: 'city',
    width: 180,
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: 60,
  },
  {
    title: 'Specialties',
    dataIndex: 'specialties',
    key: 'specialties',
    width: 225,
    align: 'center',
    className: 'specialties-cell',
    render: (value) => <SpecialtiesCell value={value} />,
  },
  {
    title: 'Hire Date',
    dataIndex: 'startDate',
    key: 'startDate',
    width: 100,
  },
  {
    title: 'Leave Date',
    dataIndex: 'endDate',
    key: 'endDate',
    width: 100,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 85,
    render: (value) => value && <TagRenderer label={DoctorStatusEnum[value]} value={value} closable={false} onClose />,
  },
  {}, // empty column, workaround to have column width fixed
];
