export const specialtyConfig = {
  specialtyContactLenses: {
    label: 'Specialty Contact Lenses',
    type: 'checkbox',
  },
  myopiaControl: {
    label: 'Myopia Control',
    type: 'checkbox',
  },
  dryEyeTreatment: {
    label: 'Dry Eye Treatment',
    type: 'checkbox',
  },
  visionTherapy: {
    label: 'Vision Therapy',
    type: 'checkbox',
  },
  generalMedicalEyecare: {
    label: 'General Medical Eyecare',
    type: 'checkbox',
  },
  iplTreatment: {
    label: 'IPL Treatment',
    type: 'checkbox',
  },
  tearCareTreatment: {
    label: 'TearCare Treatment',
    type: 'checkbox',
  },
  lipiflowTreatment: {
    label: 'Lipiflow Treatment',
    type: 'checkbox',
  },
  iluxTreatment: {
    label: 'iLux Treatment',
    type: 'checkbox',
  },
};
