export const FETCH_SCHEDULES = `FETCH_SCHEDULES`;
export const FETCH_SCHEDULES_SUCCESS = `FETCH_SCHEDULES_SUCCESS`;
export const FETCH_SCHEDULES_FAILED = `FETCH_SCHEDULES_FAILED`;

export const CREATE_SCHEDULE = `CREATE_SCHEDULE`;
export const CREATE_SCHEDULE_SUCCESS = `CREATE_SCHEDULE_SUCCESS`;
export const CREATE_SCHEDULE_FAILED = `CREATE_SCHEDULE_FAILED`;

export const FETCH_EFFECTIVE_DATES = 'FETCH_EFFECTIVE_DATES';
export const FETCH_EFFECTIVE_DATES_SUCCESS = `FETCH_EFFECTIVE_DATES_SUCCESS`;
export const FETCH_EFFECTIVE_DATES_FAILED = `FETCH_EFFECTIVE_DATES_FAILED`;

export const RESET_ALTERNATIVES_SELECTED = `RESET_ALTERNATIVES_SELECTED`;

export const FETCH_SCHEDULES_MODEL_NAMES = `FETCH_SCHEDULES_NAMES_MODELS`;
export const FETCH_SCHEDULE_MODEL_NAMES_SUCCESS =  `FETCH_SCHEDULE_MODEL_NAMES_SUCCESS`;
export const FETCH_SCHEDULE_MODEL_NAMES_FAILED =  `FETCH_SCHEDULE_MODELS_NAMES_FAILED`;

export const FETCH_SCHEDULE_MODELS = `FETCH_SCHEDULE_MODELS`;
export const FETCH_SCHEDULE_MODELS_SUCCESS =  `FETCH_SCHEDULE_MODELS_SUCCESS`;
export const FETCH_SCHEDULE_MODELS_FAILED =  `FETCH_SCHEDULE_MODELS_FAILED`;
export const RESET_CURRENT_SCHEDULE_NAME =  `RESET_CURRENT_SCHEDULE_NAME`;

export const fetchSchedules = (payload: any) => ({
  type: FETCH_SCHEDULES,
  payload
});

export const fetchSchedulesSuccess = (payload: any) => ({
  type: FETCH_SCHEDULES_SUCCESS,
  payload,
});

export const fetchSchedulesFailed = (payload: any) => ({
  type: FETCH_SCHEDULES_FAILED,
  payload,
});

export const createSchedules = (payload: any) => ({
  type: CREATE_SCHEDULE,
  payload,
});

export const createSchedulesSuccess = (payload: any) => ({
  type: CREATE_SCHEDULE_SUCCESS,
  payload,
});

export const createSchedulesFailed = (payload: any) => ({
  type: CREATE_SCHEDULE_FAILED,
  payload,
});

export const fetchEffectiveDates = (payload: any) => ({
  type: FETCH_EFFECTIVE_DATES,
  payload,
});

export const fetchEffectiveDatesSuccess = (payload: any) => ({
  type: FETCH_EFFECTIVE_DATES_SUCCESS,
  payload,
});

export const fetchEffectiveDatesFailed = (payload: any) => ({
  type: FETCH_EFFECTIVE_DATES_FAILED,
  payload,
});

export const resetAlternativeSelected = () => ({
  type: RESET_ALTERNATIVES_SELECTED
});

export const resetCurrentScheduleName = () => ({
  type: RESET_CURRENT_SCHEDULE_NAME
});

export const fetchScheduleModelNames = () => ({
  type: FETCH_SCHEDULES_MODEL_NAMES
});

export const fetchScheduleModelNamesSuccess = (payload: any) => ({
  type: FETCH_SCHEDULE_MODEL_NAMES_SUCCESS,
  payload,
});

export const fetchScheduleModelNamesFailed = (payload: any) => ({
  type: FETCH_SCHEDULE_MODEL_NAMES_FAILED,
  payload,
});

export const fetchScheduleModels = (payload: any) => ({
  type: FETCH_SCHEDULE_MODELS,
  payload,
});

export const fetchScheduleModelsSuccess = (payload: any) => ({
  type: FETCH_SCHEDULE_MODELS_SUCCESS,
  payload,
});

export const fetchScheduleModelsFailed = (payload: any) => ({
  type: FETCH_SCHEDULE_MODELS_FAILED,
  payload,
});
