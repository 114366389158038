import { locations_columns } from './grid-configs/locations';
import { profile_columns } from './grid-configs/profile';
import { finance_columns } from './grid-configs/finance';
import { marketing_columns } from './grid-configs/marketing';
import { schedule_columns } from './grid-configs/schedule';
import { audit_columns } from './grid-configs/audit';
import { mvc_columns } from './grid-configs/mvc';
import { pro_services_columns } from './grid-configs/pro-services';
import { it_services_columns } from './grid-configs/it-services';
import { doctors_columns } from './grid-configs/doctors';
import { veh_columns } from './grid-configs/veh';
import { telecom_columns } from './grid-configs/telecom';
import { specialty_columns } from './grid-configs/specialty';

export const grid_columns_map: any = {
  locations: locations_columns,
  profile: profile_columns,
  finance: finance_columns,
  marketing: marketing_columns,
  schedule: schedule_columns,
  audit: audit_columns,
  mvc: mvc_columns,
  pro: pro_services_columns,
  it: it_services_columns,
  veh: veh_columns,
  telecom: telecom_columns,
  doctors: doctors_columns,
  specialty: specialty_columns,
};
