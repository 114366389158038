import { call, put } from 'redux-saga/effects';
import { api } from '@med-fe/util';
import { showErrorNotification } from '@med-fe/util';
import { fetchAllSpecialtiesSuccess, fetchEquipmentSuccess } from './common-actions';

export function* fetchAllSpecialtiesStartWorker(): Generator {
  try {
    const url = '/specialty';
    const data: any = yield call(api.get, url);
    yield put(fetchAllSpecialtiesSuccess(data));
  } catch (e) {
    showErrorNotification(e);
  }
}

export function* fetchEquipmentStartWorker(): Generator {
  try {
    const url = '/specialty-equipment';
    const data: any = yield call(api.get, url);
    yield put(fetchEquipmentSuccess(data));
  } catch (e) {
    showErrorNotification(e);
  }
}
