import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketingForLocation } from '../../offices/offices-selectors';
import { capitalize } from 'lodash';
import { createLocation } from '../../offices/offices-actions';
import { marketingConfig } from '../configs/marketing-config';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { formItemMap } from '@med-fe/ui';

function MarketingDetails({ locId }) {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector((state) => getMarketingForLocation(state, locId));
  const isEditAllowed = useSelector(getWriteTabsPermissions).marketing;

  useEffect(() => {
    form.setFieldsValue({ ...data });
  }, [locId]);

  const onSubmit = (formData: any) => {
    const payload = {
      ...formData,
      id: locId,
    };

    dispatch(createLocation(payload));
  };

  return (
    <Form
      form={form}
      className='marketing-details-form'
      layout='vertical'
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
      }}
      onValuesChange={() => setIsDisabled(false)}
    >
      {Object.entries(marketingConfig).map(([key, value]: any) => {
        const params = { key, label: capitalize(value.label), name: key };
        return <Form.Item {...params}>{formItemMap(value, !isEditAllowed)}</Form.Item>;
      })}

      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default MarketingDetails;
