import React from 'react';
import styled from 'styled-components';

export const ScheduleTimeCell = ({ value }: any) => <StyledCell>{value || 'CLOSED'}</StyledCell>;

const StyledCell = styled.div`
  background-color: #ffffff;
  border: 1px solid #f6f6f6;
  border-radius: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
