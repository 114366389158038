import React from 'react';
import styled from 'styled-components';
import { Pie } from '@ant-design/charts';

export const SpecialtiesGraph = ({ data }) => {
  const config = {
    appendPadding: 10,
    data,
    height: 150,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
    },
    legend: {
      offsetX: -50,
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return (
    <StyledChartWrapper>
      <StyledTitle>Specialty program</StyledTitle>
      <Pie {...config} />
    </StyledChartWrapper>
  );
};

const StyledChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled.span`
  color: #40668c;
  font-weight: bold;
  font-size: 16px;
  padding-top: 10px;
`;
