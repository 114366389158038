import React from 'react';
import { SPECIALTIES_ICONS_MAP } from '../../../constants';
import styled from 'styled-components';
import { Tooltip } from 'antd';

export function SpecialtiesCell({ value }) {
  return (
    <StyledSpecialties>
      {value
        ? value.map(({ shortName, fullName }) => (
            <Tooltip
              title={fullName}
              key={shortName}
              color={'white'}
              overlayInnerStyle={{ color: '#003366' }}
              overlayStyle={{ fontSize: '11px', fontWeight: 'bold' }}
            >
              <StyledIconWrapper>
                <StyledIcon
                  key={shortName}
                  src={`./icons/specialties/${SPECIALTIES_ICONS_MAP[shortName] || SPECIALTIES_ICONS_MAP.DEFAULT}.svg`}
                  alt={shortName}
                />
              </StyledIconWrapper>
            </Tooltip>
          ))
        : ''}
    </StyledSpecialties>
  );
}

const StyledSpecialties = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledIconWrapper = styled.div`
  vertical-align: middle;
  padding: 0 3px;
  display: table-cell;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`;

const StyledIcon = styled.img`
  height: 32px;
  width: 32px;
`;
