import {mapSchedulesToForm} from "../../common/helpers/mappers";
import {SCHEDULES_STATE_KEY} from "./schedule-reducer";

export const getSchedules = (state: any) => state[SCHEDULES_STATE_KEY].schedules;
export const getAlternativeSelected = (state: any) => state[SCHEDULES_STATE_KEY].alternativeSelected;
export const getEffectiveDates = (state: any) => state[SCHEDULES_STATE_KEY].effectiveDates;
export const getScheduleModelNames = (state: any) => state[SCHEDULES_STATE_KEY].schedulesModelNames;
export const getAlternativeSchedules = (state: any) => getAlternativeSelected(state) ? mapSchedulesToForm(getSchedules(state)) : null;
export const getModelSchedules = (state: any) =>   getCurrentModel(state) ? mapSchedulesToForm(getSchedules(state)) : null;
export const getCurrentModel = (state: any) => state[SCHEDULES_STATE_KEY].currentModel;
