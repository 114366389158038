export const EXPORT_FILE = 'EXPORT_FILE';
export const EXPORT_FILE_SUCCESS = 'EXPORT_FILE_SUCCESS';

export const IMPORT_FILE = 'IMPORT_FILE';
export const IMPORT_FILE_SUCCESS = 'IMPORT_FILE_SUCCESS';
export const IMPORT_FILE_FAILED = 'IMPORT_FILE_FAILED';
export const UPDATE_VALIDATED_IMPORT = 'UPDATE_VALIDATED_IMPORT';
export const UPDATE_VALIDATED_IMPORT_SUCCESS = 'UPDATE_VALIDATED_IMPORT_SUCCESS';
export const UPDATE_VALIDATED_IMPORT_FAILED = 'UPDATE_VALIDATED_IMPORT_FAILED';
export const CLEAN_IMPORT_LAYOUT = 'CLEAN_IMPORT_LAYOUT';

export const exportFile = (payload) => ({
  type: EXPORT_FILE,
  payload,
});

export const exportFileSuccess = () => ({
  type: EXPORT_FILE_SUCCESS,
});

export const importFile = (payload) => ({
  type: IMPORT_FILE,
  payload,
});

export const importFileSuccess = (payload) => ({
  type: IMPORT_FILE_SUCCESS,
  payload,
});

export const importFileFailed = (payload) => ({
  type: IMPORT_FILE_FAILED,
  payload,
});

export const updateValidatedImport = () => ({
  type: UPDATE_VALIDATED_IMPORT,
});

export const cleanImportLayout = () => ({
  type: CLEAN_IMPORT_LAYOUT,
});
