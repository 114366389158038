import { call, put } from 'redux-saga/effects';
import { api, showErrorNotification } from '@med-fe/util';
import {
  fetchEmailGroupsFailed,
  fetchEmailGroupsSuccess,
  fetchUserEmailGroupsFailed,
  fetchUserEmailGroupsSuccess,
  updateUserEmailGroupsSuccess,
  updateUserEmailGroupsFailed,
} from './user-actions';

export function* fetchUserEmailGroupsStartWorker({ payload }): Generator {
  try {
    const url = `/emails/${payload}/groups`;
    const data = yield call(api.get, url);
    yield put(fetchUserEmailGroupsSuccess(data));
  } catch (e) {
    yield put(fetchUserEmailGroupsFailed(e));
    showErrorNotification(null, e);
  }
}

export function* fetchEmailGroupsStartWorker(): Generator {
  try {
    const url = `/emails/groups`;
    const data = yield call(api.get, url);
    yield put(fetchEmailGroupsSuccess(data));
  } catch (e) {
    yield put(fetchEmailGroupsFailed(e));
    showErrorNotification(null, e);
  }
}

export function* updateUserEmailGroupsStartWorker({ payload }): Generator {
  try {
    const url = `/emails`;
    const data: any = yield call(api.post, url, payload);
    yield put(updateUserEmailGroupsSuccess(data));
  } catch (e) {
    yield put(updateUserEmailGroupsFailed(e));
    showErrorNotification(null, e);
  }
}
