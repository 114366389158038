export const vehConfig = {
  teleOptometry: {
    label: 'Tele-optometry',
    type: 'checkbox',
  },
  vehStatus: {
    label: 'VEH Status',
    isVisible: true,
    type: 'select',
    data: [{ id: 'Live' }, { id: 'Prep' }, { id: 'Hold' }, { id: 'VEH Removed' }],
    allowClear: true,
  },
  numberOfEquippedLanes: {
    label: 'Number of Equipped Lanes',
    isVisible: true,
  },
  numberOfEquippedPretest: {
    label: 'Number of Equipped Pretest',
    isVisible: true,
  },
  coverageModel: {
    label: 'Coverage Model',
    isVisible: true,
    type: 'select',
    data: [{ id: 'VEH Only' }, { id: 'Side-by-Side' }, { id: 'Combination' }],
    allowClear: true,
  },
  equipment: {
    label: 'Equipment',
    isVisible: true,
    type: 'select',
    data: [{ id: 'Marco' }, { id: 'Reichert' }],
    allowClear: true,
  },
  vehGoLive: {
    label: 'VEH Go Live',
    isVisible: true,
    type: 'date',
  },
  vehLastDay: {
    label: 'VEH Last Day',
    isVisible: true,
    type: 'date',
  },
  callCenter: {
    label: 'Call Center',
    isVisible: true,
    type: 'date',
  },
  pxpLaunch: {
    label: 'PXP Launch',
    isVisible: true,
    type: 'date',
  },
  vehSystem: {
    label: 'VEH System',
    isVisible: true,
    type: 'select',
    data: [{ id: 'DigitalOptometrics' }, { id: 'VisionX' }],
    allowClear: true,
  },
};
