import {
  FETCH_OFFICES_STATISTICS,
  FETCH_OFFICES_STATISTICS_SUCCESS,
  FETCH_OFFICES_STATISTICS_FAILED,
  FETCH_DOCTORS_STATISTICS,
  FETCH_DOCTORS_STATISTICS_SUCCESS,
  FETCH_DOCTORS_STATISTICS_FAILED,
  FETCH_ACTIVITY_STATISTICS,
  FETCH_ACTIVITY_STATISTICS_FAILED,
  FETCH_ACTIVITY_STATISTICS_SUCCESS,
} from './statistics-actions';

export const STATISTICS_STATE_KEY = 'STATISTICS_STATE_KEY';

type StatisticsState = {
  officesLoading: boolean;
  doctorsLoading: boolean;
  activityLoading: boolean;
  officesData: any;
  doctorsData: any;
  activityData: any;
};

const initialState: StatisticsState = {
  officesLoading: false,
  doctorsLoading: false,
  activityLoading: false,
  officesData: {
    total: '',
    details: [
      { title: 'Open', total: '-', difference: 0 },
      { title: 'Closed', total: '-', difference: 0 },
      { title: 'Pending Open', total: '-', difference: 0 },
    ],
    graphData: [],
  },
  doctorsData: {
    total: '',
    details: [{ title: 'New Hires', total: '-', difference: 0 }],
  },
  activityData: {
    total: '',
    details: [{ title: 'Logged In', total: '-', difference: 0 }],
  },
};

export const statisticsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // OFFICES
    case FETCH_OFFICES_STATISTICS:
      return {
        ...state,
        officesLoading: true,
      };
    case FETCH_OFFICES_STATISTICS_SUCCESS: {
      return {
        ...state,
        officesData: payload,
        officesLoading: false,
      };
    }
    case FETCH_OFFICES_STATISTICS_FAILED: {
      return {
        ...state,
        officesLoading: false,
      };
    }

    // DOCTORS
    case FETCH_DOCTORS_STATISTICS:
      return {
        ...state,
        doctorsLoading: true,
      };
    case FETCH_DOCTORS_STATISTICS_SUCCESS: {
      return {
        ...state,
        doctorsData: payload,
        doctorsLoading: false,
      };
    }
    case FETCH_DOCTORS_STATISTICS_FAILED: {
      return {
        ...state,
        doctorsLoading: false,
      };
    }

    // ACTIVITY
    case FETCH_ACTIVITY_STATISTICS:
      return {
        ...state,
        activityLoading: true,
      };
    case FETCH_ACTIVITY_STATISTICS_SUCCESS: {
      return {
        ...state,
        activityData: payload,
        activityLoading: false,
      };
    }
    case FETCH_ACTIVITY_STATISTICS_FAILED: {
      return {
        ...state,
        activityLoading: false,
      };
    }
    default:
      return state;
  }
};
