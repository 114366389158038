import React from 'react';
import { Column } from '@ant-design/plots';

export const ColumnsGraph = ({ data }) => {
  const config = {
    data,
    height: 150,
    width: 600,
    autoFit: true,
    isStack: true,
    xField: 'month',
    yField: 'value',
    seriesField: 'type',
    label: {
      position: 'middle',
      formatter: ({ value }) => {
        return value ? value : '';
      },
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
    connectedArea: {
      style: (oldStyle, element) => {
        return {
          fill: 'rgba(0,0,0,0.25)',
          stroke: oldStyle.fill,
          lineWidth: 0.5,
        };
      },
    },
  };

  return <Column {...config} />;
};
