import { put, call, select } from 'redux-saga/effects';
import {
  createSchedulesFailed,
  createSchedulesSuccess,
  fetchEffectiveDates,
  fetchEffectiveDatesFailed,
  fetchEffectiveDatesSuccess,
  fetchScheduleModelNamesFailed,
  fetchScheduleModelNamesSuccess,
  fetchScheduleModelsFailed,
  fetchScheduleModelsSuccess,
  fetchSchedulesFailed,
  fetchSchedulesSuccess,
} from './schedule-actions';
import { api, showErrorNotification, showSuccessNotification } from '@med-fe/util';
import { getSearch } from '../../components/offices/offices-selectors';
import { fetchLocations } from '../../components/offices/offices-actions';

export function* fetchSchedulesStartWorker({ payload }: any): Generator {
  try {
    const url = `/schedules/${payload.locationId}?date=${payload.date}`;
    const data = yield call(api.get, url);
    yield put(fetchSchedulesSuccess({ data, selected: true }));
  } catch (e) {
    yield put(fetchSchedulesFailed(e));
    showErrorNotification(e);
  }
}

export function* createSchedulesStartWorker({ payload }: any): Generator {
  try {
    const url = '/schedules';
    const { schedules, id, validFrom, validTo } = payload;
    const search = yield select(getSearch);
    const data = yield call(api.post, url, { schedules, validFrom, validTo, locationId: id });

    yield put(createSchedulesSuccess(data));
    yield put(fetchEffectiveDates(id));
    yield put(fetchLocations(search));

    showSuccessNotification('Alternative schedule created successfully');
  } catch (e) {
    yield put(createSchedulesFailed(e));
    showErrorNotification(null, e);
  }
}

export function* fetchEffectiveDatesStartWorker({ payload }: { payload: number }): Generator {
  try {
    const url = `/schedules/dates/${payload}`;
    const data = yield call(api.get, url);
    yield put(fetchEffectiveDatesSuccess(data));
  } catch (e) {
    yield put(fetchEffectiveDatesFailed(e));
    showErrorNotification(e);
  }
}
export function* fetchScheduleModelNamesStartWorker(): Generator {
  try {
    const url = `/schedule_model/model_names`;
    const data = yield call(api.get, url);
    yield put(fetchScheduleModelNamesSuccess({ data }));
  } catch (e) {
    yield put(fetchScheduleModelNamesFailed(e));
    showErrorNotification(e);
  }
}

export function* fetchScheduleModelsStartWorker({ payload }: any): Generator {
  try {
    const url = `/schedule_model?modelName=${payload}`;
    const data = yield call(api.get, url);
    yield put(fetchScheduleModelsSuccess({ data, currentModel: payload }));
  } catch (e) {
    yield put(fetchScheduleModelsFailed(e));
    showErrorNotification(e);
  }
}
