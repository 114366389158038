import { Button, Form, Select, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSpecialtyForLocation } from '../../offices/offices-selectors';
import { capitalize } from 'lodash';
import { createLocation, unassignStuff, updateContact } from '../../offices/offices-actions';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { formItemMap } from '@med-fe/ui';
import moment from 'moment';
import { getContacts } from '../../offices/offices-selectors';
import { fetchContacts } from '../../offices/offices-actions';
import { specialtyConfig } from '../configs/specialty-config';

function SpecialtyDetails({ locId }) {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector((state) => getSpecialtyForLocation(state, locId));
  const isEditAllowed = useSelector(getWriteTabsPermissions).specialty;

  useEffect(() => {
    form.setFieldsValue({
      ...data,
    });
  }, [locId]);

  const onSubmit = (formData: any) => {
    // console.log(formData);

    const { ...formDataValues } = formData;
    const payload = {
      ...formDataValues,
      id: locId,
    };
    dispatch(createLocation(payload));
  };

  return (
    <Form
      form={form}
      layout='vertical'
      className='specialty-details-form'
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
      }}
      onValuesChange={() => setIsDisabled(false)}
    >
      {Object.entries(specialtyConfig).map(([key, value]: any) => {
        const params = {
          key,
          label: value.label,
          name: key,
          rules: value.rules,
          data: value.data,
          ...(value.type === 'checkbox' && { valuePropName: 'checked' }),
        };

        return <Form.Item {...params}>{formItemMap(value, !isEditAllowed)}</Form.Item>;
      })}

      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default SpecialtyDetails;
