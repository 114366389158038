import React from 'react';

export const AuditTypeCell = ({ value }: any) => {
  const iconName = value.toLowerCase();
  const iconSrc = `./icons/${iconName}-action.svg`;
  const iconStyles = {
    paddingBottom: '1px',
    marginRight: '5px',
  };

  return (
    <>
      <img src={iconSrc} alt={value.toLowerCase()} style={iconStyles} />
      <span>{value}</span>
    </>
  );
};
