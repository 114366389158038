import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select, Row, AutoComplete, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AdvancedSearchButton from '../../../components/common/AdvancedSearchButton';
import { includes, isArray, isEmpty, isNil, isString, lowerCase } from 'lodash';
// import { getAllSpecialties } from '../../../store/common/common-selector';

import {
  getContacts,
  getDistricts,
  getIsLoadingLocationNumbers,
  getLocationsForSearch,
  getRegions,
  getSearch,
  getSpecialties,
  getProServices,
} from '../../offices/offices-selectors';
import {
  fetchDistricts,
  fetchRegions,
  setSearchLocations,
  setSearchRegionId,
  toggleSelectedLocations,
  searchDistrictsByArea,
  fetchLocationsForSearch,
  fetchContacts,
} from '../../offices/offices-actions';
import { defaultSearchLocations } from '../../offices/offices-reducer';
import { AuditTypeCell, SuffixIcon, TagRenderer } from '@med-fe/ui';
import { get } from 'lodash';
import { Colors, configureColorMap } from '../../map/map-marker-theme';
import DotIcon from '../../common/dot-icon';
import { fetchLocationsCoordinates } from '../../map/map-actions';
import MultivalueSearchButton from '../../common/MultivalueSearchButton';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import styled from 'styled-components';
import { getInvalidDates } from '@med-fe/util';
import { getEquipment } from '../../../store/common/common-selector';

interface StaffSearch {
  job?: any;
  name?: any;
  label?: any;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

function SearchLocations({ toggleIsAllSelected, isColored }) {
  const dispatch = useDispatch();
  const initialSearchValue = { search: '', page: 0 };
  const [regionIds, setRegionIds] = useState<any[]>([]);
  const [districtIds, setDistrictIds] = useState<any[]>([]);
  const [locationIds, setLocationIds] = useState<any[]>([]);
  const [isDateTypeSelected, setIsDateTypeSelected] = useState(false);
  const [form] = Form.useForm();
  const [multipleSearch, setMultipleSearch] = useState(false);
  const [locationsForSearch, setLocationsForSearch] = useState([]);
  const fetchedLocationsForSearch = useSelector(getLocationsForSearch);
  const [searchValue, setSearchValue] = useState<any>(initialSearchValue);
  const isLoading = useSelector(getIsLoadingLocationNumbers);

  const [advancedSearch, setAdvancedSearch] = useState<number>(0);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const contacts = useSelector(getContacts);

  const regions = useSelector(getRegions);
  const districts = useSelector(getDistricts);
  const search = useSelector(getSearch);
  const proServices = useSelector(getProServices);
  const specialties = useSelector(getSpecialties);
  const [medicaidLocation, setMedicaidLocation] = useState({ label: 'No', value: false, disabled: true });
  const [davisLocation, setDavisLocation] = useState({ label: 'No', value: false, disabled: true });
  const [staffSearch, setStaffSearch] = useState<StaffSearch>({});
  const equipment = useSelector(getEquipment);
  // const [preceptorLocation, setPreceptorLocation] = useState({ label: 'No', value: false, disabled: true });
  // const [specialtyContactLenses, setSpecialtyContactLenses] = useState({ label: 'No', value: false, disabled: true });
  // const [myopiaControl, setMyopiaControl] = useState({ label: 'No', value: false, disabled: true });
  // const [dryEyeTreatment, setDryEyeTreatment] = useState({ label: 'No', value: false, disabled: true });
  // const [visionTherapy, setVisionTherapy] = useState({ label: 'No', value: false, disabled: true });
  // const [dryEyeProgram, setDryEyeProgram] = useState({ label: 'No', value: false, disabled: true });
  // const [generalMedicalEyecare, setGeneralMedicalEyecare] = useState({ label: 'No', value: false, disabled: true });
  // const [specialtyIds, setSpecialtyIds] = useState<any[]>([]);
  const jobPositions = [
    { value: 'General Manager', job: 'GM' },
    { value: 'General Manager In Training', job: 'GMIT' },
    { value: 'Assistant General Manager', job: 'AGM' },
    { value: 'District Manager', job: 'DM' },
    { value: 'Regional Vice President', job: 'RM' },
    { value: 'Regional Clinical Director', job: 'RCFD' },
    { value: 'Market Clinical Manager', job: 'CFM' },
    { value: 'HR Business Partner', job: 'HRBP' },
    { value: 'Professional Services Analyst', job: 'PSA' },
    { value: 'Director of Practice Development', job: 'DOPD' },
  ];
  // const allSpecialties = useSelector(getAllSpecialties);

  const proServiceSearch = [
    {
      name: 'Preceptor Location',
      id: 'preceptorLocation',
    },
    {
      name: 'Dry Eye Program',
      id: 'dryEyeProgram',
    },
  ];

  const specialtySearch = [
    {
      name: 'Specialty Contact Lenses',
      id: 'specialtyContactLenses',
    },
    {
      name: 'Myopia Control',
      id: 'myopiaControl',
    },
    {
      name: 'Dry Eye Treatment',
      id: 'dryEyeTreatment',
    },
    {
      name: 'Vision Therapy',
      id: 'visionTherapy',
    },
    {
      name: 'General Medical Eyecare',
      id: 'generalMedicalEyecare',
    },
    {
      name: 'IPL Treatment',
      id: 'iplTreatment',
    },
    {
      name: 'TearCare Treatment',
      id: 'tearCareTreatment',
    },
    {
      name: 'Lipiflow Treatment',
      id: 'lipiflowTreatment',
    },
    {
      name: 'iLux Treatment',
      id: 'iluxTreatment',
    },
  ];

  useEffect(() => {
    dispatch(fetchRegions());
    return () => {
      dispatch(setSearchLocations(defaultSearchLocations));
    };
  }, []);

  useEffect(() => {
    const filtersCounter = [
      'davisLocation',
      'medicaidLocation',
      'teleOptometry',
      'address',
      'branding',
      'lanSubnet',
      'contactJob',
      'effectiveDate',
      'vendor',
      'specialtyEquipmentIds',
      'pos',
    ].reduce(
      (acc, key) =>
        (isArray(search[key]) && !isEmpty(search[key])) ||
        (isString(search[key]) && !isEmpty(search[key])) ||
        (!isString(search[key]) && !isArray(search[key]) && !isNil(search[key]))
          ? acc + 1
          : acc,
      0
    );
    setAdvancedSearch(filtersCounter);
  }, [showAdvancedSearch]);

  useEffect(() => {
    if (regionIds.length) {
      dispatch(fetchDistricts(regionIds.map((value) => value.id)));
      dispatch(setSearchRegionId(regionIds.map((value) => value.id)));
    } else {
      dispatch(fetchDistricts());
      dispatch(setSearchRegionId([]));
    }
  }, [regionIds]);

  useEffect(() => {
    if (multipleSearch) {
      dispatch(fetchLocationsForSearch(initialSearchValue));
    } else {
      setLocationIds([]);
      setLocationsForSearch([]);
    }
    setSearchValue(initialSearchValue);
  }, [multipleSearch]);

  useEffect(() => {
    if (multipleSearch) {
      setLocationsForSearch(
        fetchedLocationsForSearch.map((item) => ({
          id: item.id,
          value: `${item.locationNumber} (${item.locationName})`,
        }))
      );
    }
  }, [fetchedLocationsForSearch]);

  useEffect(() => {
    form.validateFields(['dateRange']);
  }, [isDateTypeSelected]);

  const onSubmit = (formData) => {
    const { dateRange, insuranceSearch, ...data } = formData;
    const dates = {
      dateFrom: null,
      dateTo: null,
    };

    if (dateRange) {
      dates['dateFrom'] = dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null;
      dates['dateTo'] = dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : null;
    }

    const payload = {
      ...defaultSearchLocations,
      size: search.size,
      ...data,
      ...dates,
      locationIds: locationIds.map((value) => value.id),
      regionIds: regionIds.map((value) => value.id),
      districtIds: districtIds.map((value) => value.id),
      search: multipleSearch ? '' : searchValue.search,
      contactJob: staffSearch.job,
      contactName: staffSearch.name,
      medicaidLocation: insuranceSearch && insuranceSearch.includes('MED.LOC') ? medicaidLocation.value : null,
      davisLocation: insuranceSearch && insuranceSearch.includes('DAV.LOC') ? davisLocation.value : null,
    };

    dispatch(setSearchLocations(payload));
    dispatch(toggleSelectedLocations([]));
    toggleIsAllSelected(false);

    if (isColored) {
      dispatch(fetchLocationsCoordinates(payload, configureColorMap(payload, regions, districts)));
    }

    if (multipleSearch) {
      setSearchValue(initialSearchValue);
      dispatch(fetchLocationsForSearch({ searchValue }));
    }
    setShowAdvancedSearch(false);
  };

  const onLocationSearchScroll = (event) => {
    const target = event.target || event.currentTarget;
    if (
      !isLoading &&
      Math.round(target.scrollTop) + Math.round(target.offsetHeight) >= Math.round(target.scrollHeight - 10)
    ) {
      setSearchValue({ ...searchValue, page: searchValue.page + 1 });
      dispatch(fetchLocationsForSearch(searchValue));
    }
  };

  const onContactSearch = (value) => {
    setStaffSearch({ ...staffSearch, name: value });
    dispatch(fetchContacts({ job: staffSearch.job, name: value }));
  };

  const onLocationsSearch = (value) => {
    dispatch(fetchLocationsForSearch({ search: value, page: 0 }));
    setSearchValue({ search: value, page: 0 });
  };

  const onStaffPositionSelect = (label, value) => {
    dispatch(fetchContacts({ name: '', job: value.job }));
    setStaffSearch({ job: value.job, label: value.value });
  };

  const locStatusTypes = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Closed', value: 'CLOSED' },
    { label: 'Pending Open', value: 'HOLD' },
  ];

  const dateTypes = [
    { value: 'OPEN', label: 'Open' },
    { value: 'CLOSE', label: 'Closed' },
    { value: 'ACUITY', label: 'Acuity Launch' },
    { value: 'MODIFY', label: 'Modified' },
  ];

  const insuranceConfig = [
    {
      value: 'MED.LOC',
      title: `MED.LOC: ${medicaidLocation.label}`,
      checked: medicaidLocation.value,
      onChange: (e) => {
        e.nativeEvent.stopPropagation();
        setMedicaidLocation({
          value: !medicaidLocation.value,
          label: !medicaidLocation.value ? 'Yes' : 'No',
          disabled: false,
        });
      },
    },
    {
      value: 'DAV.LOC',
      title: `DAV.LOC: ${davisLocation.label}`,
      checked: davisLocation.value,
      onChange: (e) => {
        e.nativeEvent.stopPropagation();
        setDavisLocation({ value: !davisLocation.value, label: !davisLocation.value ? 'Yes' : 'No', disabled: false });
      },
    },
  ];

  const filterDistricts = (regionIds) => {
    let districtIdsFiltered = districtIds.filter((district) =>
      regionIds
        .map((region) => region.value.split('(')[0].trim())
        .includes(district.value.substring(district.value.indexOf('(') + 1, district.value.lastIndexOf(')')))
    );
    setDistrictIds(districtIdsFiltered);
    return districtIdsFiltered;
  };

  return !!regions ? (
    <Form
      form={form}
      layout='vertical'
      className='search-container location-search'
      onFinish={(formData) => {
        onSubmit(formData);
      }}
      validateTrigger={['onSubmit']}
      initialValues={{ statuses: ['ACTIVE'] }}
      style={{ justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex', alignSelf: 'flex-start', flexDirection: 'column' }}>
        <Row wrap={true}>
          <Form.Item key='dateType' label={'Select Date Type'} name='dateType' className='label-text search-action'>
            <Select
              allowClear
              suffixIcon={<SuffixIcon />}
              onSelect={() => setIsDateTypeSelected(true)}
              onClear={() => {
                setIsDateTypeSelected(false);
                form.resetFields(['dateRange']);
              }}
            >
              {dateTypes.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            key='dateRange'
            name='dateRange'
            label='Select Date Range'
            className='label-text'
            rules={[{ required: isDateTypeSelected }]}
          >
            <RangePicker
              allowEmpty={[true, true]}
              format={['YYYY-MM-DD']}
              separator='-'
              disabledDate={getInvalidDates}
              disabled={!isDateTypeSelected}
              inputReadOnly
            />
          </Form.Item>

          <Form.Item key='regionIds' label={'Search by Region'} name='regionIds' className='label-text region-field'>
            <Select
              allowClear
              mode={'multiple'}
              maxTagCount={'responsive'}
              tagRender={(props) => <TagRenderer {...props} valueAsLabel />}
              placeholder='...'
              suffixIcon={<SuffixIcon />}
              filterOption={(inputValue, option: any) => {
                const region = option.value.split('(');
                return get(region, '[0]').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
              }}
              onChange={(value, option: [] | any) => {
                if (!option.length) {
                  setRegionIds([]);
                  setDistrictIds([]);
                  form.resetFields(['districtIds', 'regionIds']);
                } else {
                  setRegionIds(option);
                  form.setFieldsValue({ districtIds: filterDistricts(option).map((value) => value.value) });
                }
              }}
            >
              {regions.map(({ id, name }) => (
                <Select.Option id={id} key={id} value={name}>
                  <div style={{ display: 'flex' }}>
                    {isColored && !search.regionIds.length && !search.districtIds.length ? (
                      <DotIcon color={Colors[regions.map((item) => item.id).indexOf(id)]} />
                    ) : null}
                    {`${name}`}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            key='districtIds'
            label={'Search by District'}
            name='districtIds'
            className='label-text district-field'
          >
            <Select
              allowClear
              mode={'multiple'}
              maxTagCount={'responsive'}
              tagRender={(props) => <TagRenderer {...props} valueAsLabel />}
              placeholder='...'
              suffixIcon={<SuffixIcon />}
              filterOption={(inputValue, option: any) => {
                const district = option.value.split('(');
                return get(district, '[0]').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
              }}
              onChange={(value, option: [] | any) => {
                if (!option.length) {
                  setDistrictIds([]);
                  form.setFieldsValue({ districtIds: [] });
                } else {
                  setDistrictIds(option);
                }
              }}
            >
              {districts.map(({ id, name, regionName }) => (
                <Select.Option id={id} key={id} value={name}>
                  <div style={{ display: 'flex', height: '15px' }}>
                    {isColored && (search.regionIds.length || search.districtIds.length) ? (
                      <DotIcon color={Colors[districts.map((item) => item.id).indexOf(id)]} />
                    ) : null}
                    {`${name} (${regionName})`}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'Search by Name, Email or Number'} className='label-text input-field'>
            <Input.Group compact style={{ display: 'flex' }}>
              {multipleSearch ? (
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  options={locationsForSearch}
                  mode={'multiple'}
                  maxTagCount={'responsive'}
                  dropdownStyle={{ minWidth: '10%' }}
                  tagRender={(props) => <TagRenderer simplified {...props} />}
                  value={locationIds}
                  searchValue={searchValue.search}
                  onChange={(e, value) => {
                    setLocationIds(value);
                  }}
                  onSelect={(e, value) => {
                    setLocationIds([...locationIds, value]);
                    dispatch(fetchLocationsForSearch(searchValue));
                  }}
                  onClear={() => {
                    setLocationIds([]);
                  }}
                  onSearch={onLocationsSearch}
                  onPopupScroll={onLocationSearchScroll}
                />
              ) : (
                <Input
                  allowClear
                  value={searchValue.search}
                  onChange={(e) => setSearchValue({ search: e.target.value })}
                />
              )}
              <MultivalueSearchButton onClick={() => setMultipleSearch(!multipleSearch)} isActive={multipleSearch} />
            </Input.Group>
          </Form.Item>
          <Form.Item key='statuses' label={'Select Office Status'} name='statuses' className='label-text status-type'>
            <Select
              allowClear
              mode={'multiple'}
              tagRender={(props) => <TagRenderer {...props} />}
              options={locStatusTypes}
              suffixIcon={<SuffixIcon />}
            />
          </Form.Item>
        </Row>
        {showAdvancedSearch ? (
          <Row wrap={true}>
            <Form.Item key='address' label={'Search by Address'} name='address' className='label-text address-srch'>
              <StyledInput allowClear placeholder='Enter street, city, etc' />
            </Form.Item>
            <Form.Item
              key='job'
              label={'Search by Job Position'}
              name='job'
              className='label-text'
              rules={[
                {
                  required: staffSearch.job && !staffSearch.name,
                  message: 'Please, enter employee`s name',
                },
              ]}
            >
              <Input.Group compact style={{ display: 'flex' }}>
                <Select
                  allowClear
                  style={{ width: '175px' }}
                  placeholder='Select a job position'
                  options={jobPositions}
                  onSelect={onStaffPositionSelect}
                  onClear={() => setStaffSearch({})}
                  value={staffSearch.label}
                />
                <AutoComplete
                  allowClear
                  disabled={!staffSearch.job}
                  placeholder='Enter employee name'
                  style={{ width: '140px' }}
                  onSearch={onContactSearch}
                  value={staffSearch.name || ''}
                  onSelect={(value) => setStaffSearch({ ...staffSearch, name: value })}
                  options={contacts.map(({ name, ...info }) => ({ ...info, value: name }))}
                />
              </Input.Group>
            </Form.Item>
            <Form.Item
              key='effectiveDate'
              label={'Search by HOO Effective Date'}
              name='effectiveDate'
              className='label-text effective-date-fld'
            >
              <DatePicker allowClear disabledDate={getInvalidDates} inputReadOnly />
            </Form.Item>
            <Form.Item key='vendor' label={'Search by IT Vendor'} name='vendor' className='label-text text-search'>
              <StyledInput allowClear placeholder='Enter vendor' />
            </Form.Item>
            <Form.Item
              key='lanSubnet'
              label={'Search by IT Lan Subnet'}
              name='lanSubnet'
              className='label-text lan-subnet-search'
            >
              <StyledInput allowClear placeholder='Enter lan subnet' />
            </Form.Item>
            <Form.Item key='branding' label={'Search by Branding'} name='branding' className='label-text text-search'>
              <StyledInput allowClear placeholder='Enter branding' />
            </Form.Item>
            <Form.Item
              key='specialty'
              name='specialty'
              label='Search by Specialty'
              className='label-text specialty-field'
            >
              <Select
                allowClear
                mode='multiple'
                tagRender={(props) => <TagRenderer {...props} />}
                options={specialtySearch.map(({ name, id }) => ({ label: name, value: id }))}
                maxTagCount='responsive'
                filterOption={(input, { label }) => includes(lowerCase(label), lowerCase(input))}
              />
            </Form.Item>

            <Form.Item
              key='proServices'
              name='proServices'
              label='Search by Pro Services'
              className='label-text proServices-field'
            >
              <Select
                allowClear
                mode='multiple'
                tagRender={(props) => <TagRenderer {...props} />}
                options={proServiceSearch.map(({ name, id }) => ({ label: name, value: id }))}
                maxTagCount='responsive'
                filterOption={(input, { label }) => includes(lowerCase(label), lowerCase(input))}
              />
            </Form.Item>
            <Form.Item
              key='insuranceSearch'
              label={'Search by Insurance'}
              name='insuranceSearch'
              className='label-text insurance-select'
            >
              <Select
                allowClear
                maxTagCount={'responsive'}
                mode={'multiple'}
                onDeselect={(value) => {
                  if (value === 'MED.LOC') {
                    setMedicaidLocation({ value: false, label: 'No', disabled: false });
                  }
                  if (value === 'DAV.LOC') {
                    setDavisLocation({ value: false, label: 'No', disabled: false });
                  }
                }}
                tagRender={(props) => {
                  const value =
                    props.value === 'MED.LOC'
                      ? `MED.LOC: ${medicaidLocation.label}`
                      : `DAV.LOC: ${davisLocation.label}`;
                  return <TagRenderer {...props} value={value} />;
                }}
              >
                {insuranceConfig.map(({ value, title, checked, onChange }) => (
                  <Select.Option value={value} title={title} key={value}>
                    <StyledDiv>
                      <Checkbox checked={checked} onClick={(e) => e.stopPropagation()} onChange={onChange} />
                      <StyledTypography>{` ${value}`}</StyledTypography>
                    </StyledDiv>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              key='teleOptometry'
              label={'Search by TeleOptometry'}
              name='teleOptometry'
              className='label-text teleoptometry-field'
            >
              <Select
                allowClear
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]}
              />
            </Form.Item>
            <Form.Item
              key='specialtyEquipmentIds'
              label='Search by Specialty Equipment'
              name='specialtyEquipmentIds'
              className='label-text equipment-field'
            >
              <Select
                allowClear
                mode='multiple'
                maxTagCount='responsive'
                tagRender={(props) => <TagRenderer {...props} />}
                placeholder='...'
                suffixIcon={<SuffixIcon />}
                options={equipment.map(({ id, name }) => ({ value: id, label: name }))}
                filterOption={(input, { label }) => includes(lowerCase(label), lowerCase(input))}
              />
            </Form.Item>
            <Form.Item key='pos' label={'Search by POS'} name='pos' className='label-text pos-field'>
              <Select
                allowClear
                options={[
                  { value: 'ENCOMPASS', label: 'Encompass' },
                  { value: 'ALE', label: 'ALE' },
                ]}
              />
            </Form.Item>
          </Row>
        ) : null}
      </div>
      <div style={{ display: 'flex', marginTop: '10px', alignSelf: 'flex-start' }}>
        <Form.Item className='search-btn'>
          <Input.Group compact style={{ display: 'flex' }}>
            <Button type='primary' htmlType='submit'>
              SEARCH
            </Button>
            <AdvancedSearchButton
              active={!showAdvancedSearch}
              onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
              filtersApplied={advancedSearch}
            />
          </Input.Group>
        </Form.Item>
      </div>
    </Form>
  ) : null;
}

const StyledTypography = styled(Typography)`
  font-size: 10px;
  margin-left: 3px;
`;

const StyledInput = styled(Input)`
  padding-left: 0;

  &&&.ant-input-affix-wrapper {
    padding-left: 0;
    padding-right: 4px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1px 0;
`;
export default SearchLocations;
