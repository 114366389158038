import React from 'react';
import { omit } from 'lodash';

export const AuditValueCell = ({ value }: any) => {
  const result = Object.keys(omit(value, 'key'));

  return (
    <>
      {result.map((field: string, index) => (
        <p style={{ marginBottom: 0 }} key={`${value['key']}-${index}-${value[field]}`}>
          <span style={{ fontWeight: 'bold' }}>{`${field}: `} </span>
          <span>{value[field]}</span>
        </p>
      ))}
    </>
  );
};
