import React from 'react';
import {Button} from 'antd';

function LinkCell({linkText, onClickHandler, isPrimaryStyled = true, style}: any) {
  return (
    <Button type={'link'} className="edit-cell" onClick={(e) => onClickHandler(e)} style={style}>
     <span className={`link-cell-text${isPrimaryStyled ? '' : ' link-cell-text-secondary'}`}>
          {linkText}
      </span>
    </Button>
  );
}

export default LinkCell;
