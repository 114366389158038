import { SpecialtyEquipmentDto } from '../../models/specialty-equipment-dto';
import {
  FETCH_ALL_SPECIALTIES,
  FETCH_ALL_SPECIALTIES_SUCCESS,
  FETCH_EQUIPMENT,
  FETCH_EQUIPMENT_SUCCESS,
} from './common-actions';

export const COMMON_STATE_KEY = 'COMMON_REDUCER';

export interface ICommonState {
  specialties: SpecialtyEquipmentDto[];
  equipment: SpecialtyEquipmentDto[];
}

const defaultState: ICommonState = {
  specialties: [],
  equipment: [],
};

export const commonReducer = (state = defaultState, { type, payload }: any) => {
  switch (type) {
    case FETCH_ALL_SPECIALTIES:
    case FETCH_EQUIPMENT:
      return {
        ...state,
      };
    case FETCH_ALL_SPECIALTIES_SUCCESS:
      return {
        ...state,
        specialties: payload,
      };
    case FETCH_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipment: payload,
      };
    default:
      return state;
  }
};
