import { CheckBoxCell } from '@med-fe/ui';

export const veh_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'tele-optometry',
    dataIndex: 'teleOptometry',
    key: 'teleOptometry',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'VEH Status',
    dataIndex: 'vehStatus',
    key: 'vehStatus',
    width: 120,
  },
  {
    title: 'Coverage Model',
    dataIndex: 'coverageModel',
    key: 'coverageModel',
    width: 150,
  },
  {
    title: 'Number Of Equipped Lanes',
    dataIndex: 'numberOfEquippedLanes',
    key: 'numberOfEquippedLanes',
    width: 100,
  },
  {
    title: 'Number Of Equipped Pretest',
    dataIndex: 'numberOfEquippedPretest',
    key: 'numberOfEquippedPretest',
    width: 100,
  },
  {
    title: 'VEH Go Live',
    dataIndex: 'vehGoLive',
    key: 'vehGoLive',
    width: 150,
  },
  {
    title: 'VEH Last Day',
    dataIndex: 'vehLastDay',
    key: 'vehLastDay',
    width: 150,
  },
  {
    title: 'Call Center',
    dataIndex: 'callCenter',
    key: 'callCenter',
    width: 150,
  },
  {
    title: 'PXP Launch',
    dataIndex: 'pxpLaunch',
    key: 'pxpLaunch',
    width: 150,
  },
  {
    title: 'Doctor Network',
    dataIndex: 'doctorNetwork',
    key: 'doctorNetwork',
    width: 150,
  },
  {
    title: 'Doctor',
    dataIndex: 'doctor',
    key: 'doctor',
    width: 200,
  },
  {
    title: 'VEH System',
    dataIndex: 'vehSystem',
    key: 'vehSystem',
    width: 120,
  },
];
