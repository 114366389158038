export const FETCH_ALL_SPECIALTIES = `FETCH_ALL_SPECIALTIES`;
export const FETCH_ALL_SPECIALTIES_SUCCESS = `FETCH_ALL_SPECIALTIES_SUCCESS`;

export const FETCH_EQUIPMENT = `FETCH_EQUIPMENT`;
export const FETCH_EQUIPMENT_SUCCESS = `FETCH_EQUIPMENT_SUCCESS`;

export const fetchAllSpecialties = () => ({
  type: FETCH_ALL_SPECIALTIES,
});

export const fetchAllSpecialtiesSuccess = (payload: any) => ({
  type: FETCH_ALL_SPECIALTIES_SUCCESS,
  payload,
});

export const fetchEquipment = () => ({
  type: FETCH_EQUIPMENT,
});

export const fetchEquipmentSuccess = (payload: any) => ({
  type: FETCH_EQUIPMENT_SUCCESS,
  payload,
});
