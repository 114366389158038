export const mvcConfig = {
 /*  medicalRecordsType: {
    label: 'Medical Records Type',
    type: 'text',
  }, */
  acuityLogicLaunchDate: {
    label: 'Acuity Logic Launch Date',
    type: 'date',
  },
  medicaidLocation: {
    label: 'Medicaid Loc',
    type: 'checkbox',
  },
 /*  davisLocation: {
    label: 'Dav. Location',
    type: 'checkbox',
  }, */
  /* vspLocation: {
    label: 'VSP. Location',
    type: 'checkbox',
  }, */
  cliaExpiration: {
        label: 'Clia Expiration',
        type: 'text',
      },
  groupNpi: {
    label: 'Group NPI',
    type: 'text',
  },
  epId: {
    label: 'Eyemed Provider ID',
    type: 'text',
  },
  bcbs: {
    label: 'BCBS',
    type: 'text',
  },
  medicare: {
    label: 'Medicare',
    type: 'text',
  },
  tricare: {
    label: 'Tricare',
    type: 'text',
  },
  legacyArAcq: {
    label: 'Legacy AR Acquired',
    type: 'checkbox',
  },
  /* legacyArClosed: {
    label: 'Legacy AR Close',
    type: 'date',
  }, */
 /*  transitionArClosed: {
    label: 'Transition AR Closed',
    type: 'date',
  }, */
  /* naLocation: {
    label: 'Greenfield Location',
    type: 'checkbox',
  }, */
  medicaidIntention: {
    label: 'Medicaid Intention',
    type: 'checkbox',
  },
/*   vspStatus: {
    label: 'VSP Status',
    type: 'text',
  }, */
  vintageName: {
    label: 'Vintage Name',
    type: 'text',
  },
  medicareDme: {
      label: 'Medicare DME',
      type: 'checkbox',
    },
};
