import {ScheduleDto} from '../../models/schedule-dto';
import {
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_FAILED,
  CREATE_SCHEDULE_SUCCESS,
  FETCH_EFFECTIVE_DATES,
  FETCH_EFFECTIVE_DATES_FAILED,
  FETCH_EFFECTIVE_DATES_SUCCESS,
  FETCH_SCHEDULES_MODEL_NAMES,
  FETCH_SCHEDULE_MODEL_NAMES_SUCCESS,
  FETCH_SCHEDULES,
  FETCH_SCHEDULES_FAILED,
  FETCH_SCHEDULES_SUCCESS,
  RESET_ALTERNATIVES_SELECTED,
  FETCH_SCHEDULE_MODELS,
  FETCH_SCHEDULE_MODELS_SUCCESS,
  RESET_CURRENT_SCHEDULE_NAME
} from './schedule-actions';

export const SCHEDULES_STATE_KEY = 'SCHEDULES_REDUCER';

export interface IScheduleState {
  loading: boolean,
  schedules: ScheduleDto[],
  schedulesModelNames: string[],
  effectiveDates?: string[],
  error: any,
  alternativeSelected: boolean,
  currentModel: String|null,
}

const defaultState: IScheduleState = {
  loading: false,
  schedules: [],
  schedulesModelNames: [],
  effectiveDates: [],
  error: null,
  alternativeSelected: false,
  currentModel: null,
};

export const schedulesReducer = (state = defaultState, {type, payload}: any) => {
  switch (type) {
    case FETCH_SCHEDULES:
    case CREATE_SCHEDULE:
    case FETCH_EFFECTIVE_DATES:
    case FETCH_SCHEDULE_MODELS:
    case FETCH_SCHEDULES_MODEL_NAMES:
      return state;
    case FETCH_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: payload.data,
        alternativeSelected: !!payload.selected
      }
    case CREATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedules: payload,
      }
    case FETCH_SCHEDULES_FAILED:
    case CREATE_SCHEDULE_FAILED:
    case FETCH_EFFECTIVE_DATES_FAILED:
      return {
        ...state,
        error: payload,
      }
    case FETCH_EFFECTIVE_DATES_SUCCESS:
      return {
        ...state,
        effectiveDates: payload,
      }
    case RESET_ALTERNATIVES_SELECTED:
      return {
        ...state,
        alternativeSelected: false
      }
    case RESET_CURRENT_SCHEDULE_NAME:
      return {
        ...state,
        currentModel: null
      }
    case FETCH_SCHEDULE_MODEL_NAMES_SUCCESS:
      return {
        ...state,
        schedulesModelNames: payload.data,
      }
    case FETCH_SCHEDULE_MODELS_SUCCESS:
      return {
        ...state,
        schedules: payload.data,
        currentModel: payload.currentModel,
      }
    default:
      return state;
  }
};
