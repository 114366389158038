import React from 'react';
import LocationDetails from '../details/locations/LocationDetails';
import StaffList from '../details/locations/StaffList';
import FinanceDetails from '../details/locations/FinanceDetails';
import ProfileDetails from '../details/locations/ProfileDetails';
import MarketingDetails from '../details/locations/MarketingDetails';
import MvcDetails from '../details/locations/MvcDetails';
import ScheduleDetails from '../details/locations/shedule-details/ScheduleDetails';
import ProServiceDetails from '../details/locations/ProServiceDetails';
import ITDetails from '../details/locations/ITDetails';
import VehDetails from '../details/locations/VehDetails';
import TelecomDetails from '../details/locations/TelecomDetails';
import SpecialtyDetails from '../details/locations/SpecialtyDetails';
export const officesTabsConfig = (locId) => ({
  locations: {
    title: 'locations & managers',
    ...(locId && {
      details: {
        location_details: <LocationDetails locId={locId} />,
        'managers_(or_ops_partners)': <StaffList title='managers' locId={locId} />,
        doctors: <StaffList title='doctors' locId={locId} />,
        ps_partners: <StaffList title='ps_partners' locId={locId} />,
        hr_partners: <StaffList title='hr_partners' locId={locId} />,
      },
    }),
  },
  profile: {
    title: 'profile',
    ...(locId && {
      details: {
        profile: <ProfileDetails locId={locId} />,
      },
    }),
  },
  finance: {
    title: 'finance',
    ...(locId && {
      details: {
        billing_info: <FinanceDetails title='billing_info' locId={locId} />,
        office_address: <FinanceDetails title='office_address' locId={locId} />,
        billing_account: <FinanceDetails title='billing_account' locId={locId} />,
      },
    }),
  },
  marketing: {
    title: 'marketing',
    ...(locId && {
      details: {
        marketing: <MarketingDetails locId={locId} />,
      },
    }),
  },
  schedule: {
    title: 'schedule',
    ...(locId && {
      details: {
        schedule: <ScheduleDetails locId={locId} />,
      },
    }),
  },
  mvc: {
    title: 'mvc',
    ...(locId && {
      details: {
        mvc: <MvcDetails locId={locId} />,
      },
    }),
  },
  pro: {
    title: 'pro services',
    ...(locId && {
      details: {
        pro_service: <ProServiceDetails locId={locId} />,
      },
    }),
  },
  it: {
    title: 'IT services',
    ...(locId && {
      details: {
        internet_details: <ITDetails title='internet_details' locId={locId} />,
        internet_access_details: <ITDetails title='internet_access_details' locId={locId} />,
        equipment: <ITDetails title='internet_equipment_details' locId={locId} />,
        lan_ip_info: <ITDetails title='internet_lan_ip_details' locId={locId} />,
        primary_circuit: <ITDetails title='internet_primary_circuit_details' locId={locId} />,
      },
    }),
  },
  veh: {
    title: 'veh',
    ...(locId && {
      details: {
        veh: <VehDetails locId={locId} />,
      },
    }),
  },
  telecom: {
    title: 'telecom',
    ...(locId && {
      details: {
        telecom: <TelecomDetails locId={locId} />,
      },
    }),
  },
  specialty: {
    title: 'specialty',
    ...(locId && {
      details: {
        specialty: <SpecialtyDetails locId={locId} />,
      },
    }),
  },
  map: {
    title: 'map',
  },
});
