import { CheckBoxCell } from '@med-fe/ui';

export const specialty_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'Specialty Contact Lenses',
    dataIndex: 'specialtyContactLenses',
    key: 'specialtyContactLenses',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'Myopia Control',
    dataIndex: 'myopiaControl',
    key: 'myopiaControl',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'Dry Eye Treatment',
    dataIndex: 'dryEyeTreatment',
    key: 'dryEyeTreatment',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'Vision Therapy',
    dataIndex: 'visionTherapy',
    key: 'visionTherapy',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'General Medical Eyecare',
    dataIndex: 'generalMedicalEyecare',
    key: 'generalMedicalEyecare',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'IPL Treatment',
    dataIndex: 'iplTreatment',
    key: 'iplTreatment',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'Tearcare Treatment',
    dataIndex: 'tearCareTreatment',
    key: 'tearCareTreatment',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'Lipiflow Treatment',
    dataIndex: 'lipiflowTreatment',
    key: 'lipiflowTreatment',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'iLux Treatment',
    dataIndex: 'iluxTreatment',
    key: 'iluxTreatment',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
];
