import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Button, Switch, Collapse, Spin, Space } from 'antd';
import { ProfileButton, ProfileIcon } from '@med-fe/ui';
import { fetchEmailGroups, fetchUserEmailGroups, updateUserEmailGroups } from '../../../store/user/user-actions';
import { getEmailGroups, getUserEmail, getUserDataLoading, IUserEmail } from '../../../store/user/user-reducer';
import { CloseIcon } from '../../doctor-panel/DoctorPanel';
import { StyledDrawer } from './styled';

const ProfilePanel = ({ user, onSignOut }) => {
  const dispatch = useDispatch();
  const emailGroups = useSelector(getEmailGroups);
  const userEmail = useSelector(getUserEmail);
  const loading = useSelector(getUserDataLoading);
  const [data, setData] = useState<IUserEmail>({ id: null, email: '', emailGroups: [], emailSubGroups: [] });
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (user && open) {
      dispatch(fetchUserEmailGroups(user.username));
      dispatch(fetchEmailGroups());
    }
  }, [user, open]);

  useEffect(() => {
    setData(userEmail);
  }, [userEmail]);

  const onSwitchChange = (checked: boolean, item: string) => {
    if (checked) {
      setData({ ...data, emailGroups: [...data.emailGroups, item] });
    } else {
      setData({
        ...data,
        emailGroups: data.emailGroups.filter((group) => group !== item),
      });
    }
    setDisabledSave(false);
  };

  const onEmailSubscriptionSubmit = () => {
    dispatch(updateUserEmailGroups(data));
    setDisabledSave(true);
  };

  return (
    <>
      <ProfileButton
        type='primary'
        shape='circle'
        icon={<ProfileIcon src={'./icons/avatar.svg'} alt='avatar' />}
        onClick={() => setOpen(true)}
      />
      <StyledDrawer
        visible={open}
        closable={false}
        onClose={() => {
          setOpen(false);
          setData(userEmail);
        }}
        title={user?.name || ''}
        extra={
          <div>
            <Space>
              {/* <Button type='link' onClick={onSignOut}>
                Sign out
              </Button> */}
              <CloseIcon onClick={() => setOpen(false)} />
            </Space>
          </div>
        }
        footer={
          <Button
            type='primary'
            disabled={disabledSave}
            onClick={onEmailSubscriptionSubmit}
            className={'btn-form-action'}
          >
            SAVE
          </Button>
        }
      >
        <Spin spinning={loading}>
          <Collapse collapsible='header' defaultActiveKey={['0']}>
            <Collapse.Panel header={'Email Subscription'} key={0}>
              <List
                dataSource={Object.keys(emailGroups)}
                renderItem={(item) => (
                  <List.Item>
                    {item.replaceAll('_', ' ')}
                    <Switch
                      checkedChildren='On'
                      unCheckedChildren='Off'
                      checked={data.emailGroups?.includes(item)}
                      onChange={(checked) => onSwitchChange(checked, item)}
                    />
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          </Collapse>
        </Spin>
      </StyledDrawer>
    </>
  );
};

export default ProfilePanel;
