import { FETCH_AUDIT, FETCH_AUDIT_FAILED, FETCH_AUDIT_SUCCESS, SET_SEARCH_AUDIT } from './audit-actions';
import moment from 'moment';
import { DEFAULT_GRID_SIZE } from '@med-fe/util';

export const AUDIT_STATE_KEY = 'AUDIT_REDUCER';

const defaultAudit = {
  total: 0,
  pages: 1,
  contents: [],
};

export const defaultSearchAudit = {
  actions: null,
  from: null,
  page: 0,
  size: DEFAULT_GRID_SIZE,
  to: null,
  user: null,
  location: null,
};

const defaultState = {
  loading: false,
  audit: defaultAudit,
  error: null,
  search: defaultSearchAudit,
};

export const getAudit = (state: any) => state[AUDIT_STATE_KEY].audit;
export const getIsLoadingAudit = (state: any) => state[AUDIT_STATE_KEY].loading;
export const getAuditForGrid = (state: any) =>
  getAudit(state).contents.map((item: any) => ({
    ...item,
    key: item.id,
    auditArea: item.entity,
    date: moment(item.date).format('YYYY-MM-DD HH:mm:ss'),
    from: item.auditDetails.reduce((result, detail) => ({ ...result, [detail.field]: detail.oldValue }), {
      key: item.id,
    }),
    to: item.auditDetails.reduce((result, detail) => ({ ...result, [detail.field]: detail.newValue }), {
      key: item.id,
    }),
  }));
export const getAuditTotal = (state: any) => getAudit(state).total;
export const getSearchAudit = (state: any) => state[AUDIT_STATE_KEY].search;

export const auditReducer = (state = defaultState, { type, payload }: any) => {
  switch (type) {
    case FETCH_AUDIT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AUDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        audit: payload,
      };
    case FETCH_AUDIT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case SET_SEARCH_AUDIT:
      return {
        ...state,
        search: { ...state.search, ...payload },
      };
    default:
      return state;
  }
};
