import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Input, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getSchedulesForLocation, getTimezoneForLocation } from '../../../offices/offices-selectors';
import { DayOfWeek } from '../../../../models/day-enum';
import { getWriteTabsPermissions } from '../../../../store/user/user-reducer';
import { SuffixIcon } from '@med-fe/ui';
import {
  createSchedules,
  fetchEffectiveDates,
  fetchScheduleModelNames,
  fetchScheduleModels,
  fetchSchedules,
  fetchSchedulesSuccess,
  resetAlternativeSelected,
  resetCurrentScheduleName,
} from '../../../../store/schedule/schedule-actions';
import {
  getAlternativeSchedules,
  getCurrentModel,
  getEffectiveDates,
  getModelSchedules,
  getScheduleModelNames,
} from '../../../../store/schedule/schedule-selectors';
import { getInvalidDates, getDateFromMoment, getIsSameOrBefore } from '@med-fe/util';
import { StyledArrowWrapper, StyledDatesWrapper } from './styled';
import { ArrowRightOutlined } from '@ant-design/icons';

function ScheduleDetails({ locId }) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [dayDisabledStatus, setDayDisabledStatus] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });
  const [effectiveDate, setEffectiveDate] = useState<string | null>(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => getSchedulesForLocation(state, locId));
  const initialTimezone = useSelector((state) => getTimezoneForLocation(state, locId));
  const isEditAllowed = useSelector(getWriteTabsPermissions).schedule;
  const effectiveDates = useSelector(getEffectiveDates);
  const scheduleModelNames = useSelector(getScheduleModelNames);
  const alternativeSchedule = useSelector(getAlternativeSchedules);
  const modelSchedules = useSelector(getModelSchedules);
  const currentModel = useSelector(getCurrentModel);
  useEffect(() => {
    setDayDisabledStatus(getDaysDisabledStatus(data));
    dispatch(fetchEffectiveDates(locId));
    dispatch(fetchScheduleModelNames());
  }, [locId]);

  useEffect(() => {
    if (alternativeSchedule) {
      setDayDisabledStatus(getDaysDisabledStatus(alternativeSchedule));
      form.setFieldsValue(alternativeSchedule);
    }
  }, [alternativeSchedule]);

  useEffect(() => {
    if (modelSchedules) {
      setDayDisabledStatus(getDaysDisabledStatus(modelSchedules));
      form.setFieldsValue(modelSchedules);
    }
  }, [currentModel]);

  useEffect(() => {
    return () => {
      dispatch(fetchSchedulesSuccess([]));
      dispatch(resetAlternativeSelected());
      dispatch(resetCurrentScheduleName());
    };
  }, []);

  const getDaysDisabledStatus = (days): any => {
    const dayStatusClosed: any = {};
    Object.keys(days).forEach((day) => (dayStatusClosed[day] = days[day]['statusClosed']));

    return { ...dayStatusClosed };
  };

  const updateClosedStatus = (day, value) => {
    setDayDisabledStatus({ ...dayDisabledStatus, [day]: value.target.checked });
    if (value.target.checked) {
      form.setFieldsValue({ [day]: { openTime: null, closeTime: null } });
    }
  };

  const onSubmit = (formData: any) => {
    const { timezone, effectiveDate, expirationDate, scheduleModel, alternativeSchedule, ...daysData } = formData;
    const validFrom = effectiveDate
      ? getDateFromMoment(effectiveDate)
      : alternativeSchedule
      ? alternativeSchedule
      : '2021-01-01';
    const validTo = expirationDate && getDateFromMoment(expirationDate);
    const schedules = Object.keys(daysData).reduce((acc: any[], day) => {
      let result;

      if (!daysData[day].statusClosed) {
        result = {
          locationId: locId,
          open: `${daysData[day].openTime} ${!daysData[day].openHours ? 'AM' : 'PM'}`,
          close: `${daysData[day].closeTime} ${daysData[day].closeHours ? 'PM' : 'AM'}`,
          dayOfWeek: day,
          ...(!effectiveDate && !alternativeSchedule && { id: data[day].id || null }),
          ...((effectiveDate || alternativeSchedule) && { validFrom }),
          ...(validTo ? { validTo } : {}),
        };
      }

      return result ? [...acc, result] : acc;
    }, []);

    const payload = {
      timezone,
      schedules,
      id: locId,
      validFrom,
      ...(validTo ? { validTo } : {}),
    };

    dispatch(createSchedules(payload));
  };

  const onValuesChange = (formData) => {
    if (!formData.hasOwnProperty('alternativeSchedule') && !formData.hasOwnProperty('effectiveDate')) {
      setIsDisabled(false);
    }

    if (formData.hasOwnProperty('effectiveDate')) {
      if (formData.effectiveDate) {
        setEffectiveDate(getDateFromMoment(formData.effectiveDate));
      } else {
        setEffectiveDate(null);
        form.setFieldsValue({ expirationDate: null });
      }
    }
  };

  const currentScheduleDate = Object.values(data)
    .map((schedule) => schedule['validFrom'])
    .find((validFrom) => validFrom != null);

  return (
    <Form
      form={form}
      className='schedules-details-form'
      layout='vertical'
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
      }}
      onValuesChange={onValuesChange}
      initialValues={data}
    >
      <Form.Item key={'timezone'} name={'timezone'} label={'Time zone'} initialValue={initialTimezone || 'EST'}>
        <Input disabled={!isEditAllowed} />
      </Form.Item>
      <Form.Item key={'scheduleModel'} name={'scheduleModel'} label={'Office Schedule Based On Model'}>
        <Select
          showSearch
          placeholder={'Office Schedule Based on Model'}
          suffixIcon={<SuffixIcon />}
          onSelect={(name) => dispatch(fetchScheduleModels(name))}
        >
          {scheduleModelNames.map((value: string) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {Object.keys(DayOfWeek).map((day) => {
        const pattern = /^(1[0-2]|0?[1-9]):([0-5]?[0-9])$/;
        const validationMessage = 'Please enter valid time';
        return (
          <div key={day}>
            <div className='schedule-label'>{day}</div>
            <div className='schedule-time-row'>
              <Form.Item
                name={[day, 'openTime']}
                rules={[
                  { pattern, message: validationMessage },
                  { required: !dayDisabledStatus[day], message: validationMessage },
                ]}
              >
                <Input className='time-input' disabled={!isEditAllowed || dayDisabledStatus[day]} />
              </Form.Item>
              <Form.Item name={[day, 'openHours']} valuePropName='checked'>
                <Switch
                  checkedChildren='PM'
                  unCheckedChildren='AM'
                  disabled={!isEditAllowed || dayDisabledStatus[day]}
                />
              </Form.Item>

              <span className='time-divider'>-</span>

              <Form.Item
                name={[day, 'closeTime']}
                rules={[
                  { pattern, message: validationMessage },
                  { required: !dayDisabledStatus[day], message: validationMessage },
                ]}
              >
                <Input className='time-input' disabled={!isEditAllowed || dayDisabledStatus[day]} />
              </Form.Item>
              <Form.Item name={[day, 'closeHours']} valuePropName='checked'>
                <Switch
                  checkedChildren='PM'
                  unCheckedChildren='AM'
                  disabled={!isEditAllowed || dayDisabledStatus[day]}
                />
              </Form.Item>
              <Form.Item name={[day, 'statusClosed']} valuePropName='checked'>
                <Checkbox onChange={(value) => updateClosedStatus(day, value)} disabled={!isEditAllowed}>
                  Closed
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        );
      })}

      <StyledDatesWrapper>
        <Form.Item key={'effectiveDate'} name={'effectiveDate'} label={'Effective Date'}>
          <DatePicker format={'YYYY-MM-DD'} disabled={!isEditAllowed} disabledDate={getInvalidDates} inputReadOnly />
        </Form.Item>
        <StyledArrowWrapper>
          <ArrowRightOutlined />
        </StyledArrowWrapper>
        <Form.Item key={'expirationDate'} name={'expirationDate'} label={'Expiration Date'}>
          <DatePicker
            format={'YYYY-MM-DD'}
            disabled={!isEditAllowed || !effectiveDate}
            disabledDate={(currentDate) => getIsSameOrBefore(currentDate, effectiveDate)}
            inputReadOnly
          />
        </Form.Item>
      </StyledDatesWrapper>

      <Form.Item
        key={'alternativeSchedule'}
        name={'alternativeSchedule'}
        label={'Alternative Schedule'}
        initialValue={currentScheduleDate}
      >
        <Select
          placeholder={'Alternative Schedule'}
          suffixIcon={<SuffixIcon />}
          onSelect={(date) => dispatch(fetchSchedules({ date, locationId: locId }))}
        >
          {effectiveDates.map((value: string) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ScheduleDetails;
