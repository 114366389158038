import React, { useEffect, useState } from 'react';
import { capitalize, get, isEqual, omit, pick } from 'lodash';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearOfficeList, createLocation, fetchOfficeList } from '../../offices/offices-actions';
import { formItemMap } from '@med-fe/ui';
import {
  getBillingAccount,
  getBillingInfoForLocation,
  getIsLoading,
  getOfficeList,
  getAddressForLocation,
} from '../../offices/offices-selectors';
import moment from 'moment';
import { billingInfoConfig } from '../configs/billing-info-config';
import { billingAccountInfoConfig } from '../configs/billing-account-info';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { addressConfig } from '../configs/address-config';
import { PosEnum } from 'apps/mod/src/models/pos-enum';

function FinanceDetails({ locId, title }) {
  const selectorMap = {
    billing_info: {
      data: getBillingInfoForLocation,
      config: billingInfoConfig,
    },
    office_address: {
      data: getAddressForLocation,
      config: addressConfig,
    },
    billing_account: {
      data: getBillingAccount,
      config: billingAccountInfoConfig,
    },
  };
  const data = useSelector((state) => selectorMap[title]['data'](state, locId));
  const isEditAllowed = useSelector(getWriteTabsPermissions).finance;
  const isLoading = useSelector(getIsLoading);
  const [isDisabled, setIsDisabled]: any = useState(true);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const officeList = useSelector(getOfficeList);
  const [officeSearchPayload, setOfficeSearchPayload] = useState<any>({ page: 0, officeNumber: '' });
  const [posValue, setPosValue] = useState(data?.pos ? data?.pos : null);

  useEffect(() => {
    dispatch(clearOfficeList());
  }, [locId]);

  const onSubmit = (dataFromForm: any) => {
    const getId = (value) => {
      return typeof value === 'object' ? { id: value.id } : { id: value };
    };
    const { ...formData } = {
      ...dataFromForm,
      ...(Object.entries(dataFromForm)
        .filter(([key]) => key === 'mergedInto')
        .some(([_, value]) => value === undefined) && { mergedInto: null }),
      ...(!!dataFromForm.mergedInto && { mergedInto: getId(dataFromForm.mergedInto) }),
    };
    let payload: any = {
      id: locId,
    };

    switch (title) {
      case 'billing_info':
        payload = {
          ...payload,
          ...formData,
          ...(!!formData.closedDate && { closedDate: formData.closedDate.format('YYYY-MM-DD') }),
        };
        break;
      case 'billing_account':
        payload = {
          ...payload,
          ...pick(formData, ['integration', 'sameOfficeSales']),
          sameOfficeChangeDate: formData?.sameOfficeChangeDate
            ? moment(formData?.sameOfficeChangeDate).format('YYYY-MM-DD')
            : null,
          billing: {
            id: data.id,
            ...omit(formData, ['integration', 'sameOfficeSales', 'sameOfficeChangeDate']),
          },
        };
        break;
      case 'office_address':
        payload = {
          ...payload,
          ...formData,
        };
        break;
    }
    dispatch(createLocation(payload));
    setIsDisabled(true);
  };

  const onOfficeSearch = (officeNumber) => {
    if (officeNumber && officeNumber.length >= 3) {
      setOfficeSearchPayload({ ...officeSearchPayload, officeNumber });
      dispatch(fetchOfficeList({ officeNumber }));
    }
    if (officeNumber === '') {
      dispatch(fetchOfficeList({}));
      setOfficeSearchPayload({ ...officeSearchPayload, officeNumber });
    }
  };

  const onOfficesScroll = (event) => {
    const target = event.target || event.currentTarget;
    if (
      Math.round(target.scrollTop) + Math.round(target.offsetHeight) >= Math.round(target.scrollHeight - 10) &&
      !isLoading &&
      !officeSearchPayload.officeNumber
    ) {
      setOfficeSearchPayload({ ...officeSearchPayload, page: officeSearchPayload.page + 1 });
      dispatch(fetchOfficeList({ page: officeSearchPayload.page + 1, name: officeSearchPayload.name }));
    }
  };

  const onOfficeFocus = () => dispatch(fetchOfficeList({ page: 0 }));

  const onOfficeClear = () => setOfficeSearchPayload((prev) => ({ ...prev, officeNumber: '' }));
  const onPosSelect = (value) => {
    setPosValue(value);
  };

  return (
    <Form
      form={form}
      layout='vertical'
      className='mvc-details-form'
      onFinish={onSubmit}
      onValuesChange={() => setIsDisabled(false)}
      initialValues={{
        ...data,
        ...(!!data.encompassLaunchDate && { encompassLaunchDate: moment(data.encompassLaunchDate) }),
        ...(!!data.closedDate && { closedDate: moment(data.closedDate) }),
        ...(!!data.sameOfficeChangeDate && { sameOfficeChangeDate: moment(data.sameOfficeChangeDate) }),
        ...(!!data.mergedInto && {
          mergedInto:
            data.mergedInto.id === undefined
              ? null
              : {
                  id: data.mergedInto.id,
                  value: data.mergedInto.name,
                },
        }),
      }}
      onFieldsChange={(changed: any) => {
        if (changed?.[0]?.name?.some((el) => el === 'sameOfficeSales')) {
          const initialSameOfficeSalesValue = data?.sameOfficeSales;
          const currentSameOfficeSalesValue = changed?.[0]?.value;

          if (isEqual(currentSameOfficeSalesValue, initialSameOfficeSalesValue)) {
            form.setFieldsValue({
              sameOfficeChangeDate: !!data?.sameOfficeChangeDate ? moment(data?.sameOfficeChangeDate) : null,
            });
          } else {
            form.setFieldsValue({ sameOfficeChangeDate: moment() });
          }
        }
      }}
    >
      {Object.entries(selectorMap[title]['config']()).map(([key, value]: any) => {
        const params = {
          key,
          label: value.label,
          name: key,
          rules: value.rules,
          ...(value.type === 'checkbox' && { valuePropName: 'checked' }),
          disabled: value.disabled,
        };
        const formItemParams = {
          ...value,
          ...(key === 'mergedInto' && {
            onFocus: onOfficeFocus,
            options: officeList
              .filter((office) => office.id !== locId)
              .map(({ id, number }) => ({ id, value: number })),
            onSearch: onOfficeSearch,
            onPopupScroll: onOfficesScroll,
            onClear: onOfficeClear,
            data: data.mergedInto,
            isLoading: isLoading,
          }),
          ...(key === 'pos' && { onSelect: onPosSelect, data: Object.entries(PosEnum) }),
        };
        return !(key === 'encompassLaunchDate' && (posValue === 'ALE' || posValue === null)) ? (
          <Form.Item
            {...params}
            className={value.type === 'checkbox' ? 'checkbox-item' : ''}
            validateTrigger={['onBlur', 'onSubmit']}
          >
            {formItemMap(formItemParams, !isEditAllowed || params.disabled)}
          </Form.Item>
        ) : (
          ''
        );
      })}

      {/* {title !== 'office_address' ? ( */}
      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
      {/* ) : null} */}
    </Form>
  );
}

export default FinanceDetails;
