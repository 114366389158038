export const internetPrimaryCircuitDetailsConfig: any = () => ({
  staticIP: {
    label: 'Static IP',
    type: 'text',
  },
  gateway: {
    label: 'gateway',
    type: 'text',
  },
  dnsPrimary: {
    label: 'DNS Primary',
    type: 'text',
  },
  dnsSecondary: {
    label: 'DNS Secondary',
    type: 'text',
  },
  subnetMask: {
    label: 'Subnet Mask',
    type: 'text',
  },
});
