export const billingAccountInfoConfig: any = () => ({
  merchantBankAccountNumber: {
    label: 'Merchant Bank Account Number',
  },
  drOfRecord: {
    label: 'Dr Of Record',
  },
  oracleLegalEntityNum: {
    label: 'Oracle Legal Entity Num',
  },
  oracleOfficeNum: {
    label: 'Oracle Office Num',
  },
  depositoryBankAccount: {
    label: 'Depository Bank Account',
  },
  worldpayMerchantId: {
    label: 'Worldpay Merchant Id',
  },
  careCreditMerchantId: {
    label: 'Care Credit Merchant Id',
  },
  worldpaySerialNumber:{
    label: 'Worldpay Serial Number'
  },
  integration: {
    label: 'Integration',
    type: 'checkbox',
  },
  sameOfficeSales: {
    label: 'Same Office Sales',
    type: 'checkbox',
  },
  sameOfficeChangeDate: {
    label: 'Same Office Change Date',
    type: 'date'
  },
});
