import React from 'react';
import styled from 'styled-components';

export const TextCell = ({ value, strong }: any) => (
  <Wrap title={value} strong={strong}>
    {value}
  </Wrap>
);

const Wrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ strong }) => (strong ? 'font-weight: 900;' : null)}
`;
