import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider, Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SectionCard } from './SectionCard';
import { ColumnsGraph } from './graphs/ColumnsGraph';
import { SpecialtiesGraph } from './graphs/SpecialtiesGraph';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActivityData,
  getActivityLoading,
  getDoctorsData,
  getDoctorsLoading,
  getOfficesData,
  getOfficesLoading,
} from '../statistics-selector';
import { fetchActivityStatistics, fetchDoctorsStatistics, fetchOfficesStatistics } from '../statistics-actions';

export const InfoSection = ({ sectionTitle }) => {
  const dispatch = useDispatch();
  const dataSelectorMap: any = {
    offices: getOfficesData,
    doctors: getDoctorsData,
    active_users: getActivityData,
  };
  const loadingSelectorMap: any = {
    offices: getOfficesLoading,
    doctors: getDoctorsLoading,
    active_users: getActivityLoading,
  };
  const fetchDataRequestMap: any = {
    offices: fetchOfficesStatistics,
    doctors: fetchDoctorsStatistics,
    active_users: fetchActivityStatistics,
  };
  const selectOptions = [
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: '3 Months', value: 'quarter' },
    { label: '6 Months', value: 'half_year' },
    { label: 'Year', value: 'year' },
  ];
  const title = sectionTitle.toLowerCase().replace(/\s/g, '_');
  const data = useSelector((state) => dataSelectorMap[title](state));
  const loading = useSelector((state) => loadingSelectorMap[title](state));
  const [selectPeriodLabel, setSelectPeriodLabel] = useState('week');

  const handlePeriodChange = ({ label, value }: any) => {
    setSelectPeriodLabel(label.toLowerCase());
    dispatch(fetchDataRequestMap[title]({ period: value }));
  };

  useEffect(() => {
    dispatch(fetchDataRequestMap[title]({ period: 'week' }));
  }, []);

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined />}>
      <StyledSectionWrapper>
        <StyledDivider orientation={'left'}>
          <StyledSectionHeader>
            <StyledTotalTitle>
              {sectionTitle}: {data?.total}
            </StyledTotalTitle>
            <StyledSelect
              defaultValue={'week'}
              onChange={(_, option) => handlePeriodChange(option)}
              options={selectOptions}
            />
          </StyledSectionHeader>
        </StyledDivider>

        <StyledInfoSection>
          <StyledCardsGroup>
            {data?.details.map((cardInfo) => (
              <SectionCard key={cardInfo.title} cardInfo={cardInfo} period={selectPeriodLabel} />
            ))}
            {data?.specialtiesData?.length ? <SpecialtiesGraph data={data.specialtiesData} /> : null}
          </StyledCardsGroup>
          {data?.graphData?.length ? <ColumnsGraph data={data.graphData} /> : null}
        </StyledInfoSection>
      </StyledSectionWrapper>
    </Spin>
  );
};

const StyledSectionWrapper = styled.div`
  min-height: 100px;
`;

const StyledInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCardsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-right: 30px;
`;

const StyledDivider = styled(Divider)`
  &&& {
    border-top-color: rgb(83 119 176 / 25%);
    margin: 15px 0 10px;
  }

  .ant-divider-inner-text {
    font-size: 18px;
    font-weight: 700;
    color: #5377b0;
  }
`;

const StyledSectionHeader = styled.div`
  display: flex;
`;

const StyledTotalTitle = styled.div`
  min-width: 190px;
  text-align: left;
`;

const StyledSelect = styled(Select)`
  width: 120px;

  &&& .ant-select-selector {
    color: #fff;
    background-color: #5377b2;
    height: 27px;

    .ant-select-selection-item {
      line-height: 25px;
    }
  }

  .ant-select-arrow {
    color: #fff;
  }
`;
