export const SET_USER_ROLES = `SET_USER_ROLES`;
export const FETCH_EMAIL_GROUPS = 'FETCH_EMAIL_GROUPS';
export const FETCH_EMAIL_GROUPS_SUCCESS = 'FETCH_EMAIL_GROUPS_SUCCESS';
export const FETCH_EMAIL_GROUPS_FAILED = 'FETCH_EMAIL_GROUPS_FAILED';
export const FETCH_USER_EMAIL_GROUPS = 'FETCH_USER_EMAIL_GROUPS';
export const FETCH_USER_EMAIL_GROUPS_SUCCESS = 'FETCH_USER_EMAIL_GROUPS_SUCCESS';
export const FETCH_USER_EMAIL_GROUPS_FAILED = 'FETCH_USER_EMAIL_GROUPS_FAILED';
export const UPDATE_USER_EMAIL_GROUPS = 'UPDATE_USER_EMAIL_GROUPS';
export const UPDATE_USER_EMAIL_GROUPS_SUCCESS = 'UPDATE_USER_EMAIL_GROUPS_SUCCESS';
export const UPDATE_USER_EMAIL_GROUPS_FAILED = 'UPDATE_USER_EMAIL_GROUPS_FAILED';

export const setUserRoles = (payload: string[]) => ({
  type: SET_USER_ROLES,
  payload,
});

export const fetchEmailGroups = () => ({
  type: FETCH_EMAIL_GROUPS,
});

export const fetchEmailGroupsSuccess = (payload) => {
  return {
    type: FETCH_EMAIL_GROUPS_SUCCESS,
    payload,
  };
};

export const fetchEmailGroupsFailed = (payload) => ({
  type: FETCH_EMAIL_GROUPS_FAILED,
  payload,
});

export const fetchUserEmailGroups = (payload) => ({
  type: FETCH_USER_EMAIL_GROUPS,
  payload,
});

export const fetchUserEmailGroupsSuccess = (payload) => ({
  type: FETCH_USER_EMAIL_GROUPS_SUCCESS,
  payload,
});

export const fetchUserEmailGroupsFailed = (payload) => ({
  type: FETCH_USER_EMAIL_GROUPS_FAILED,
  payload,
});

export const updateUserEmailGroups = (payload) => ({
  type: UPDATE_USER_EMAIL_GROUPS,
  payload,
});

export const updateUserEmailGroupsSuccess = (payload) => ({
  type: UPDATE_USER_EMAIL_GROUPS_SUCCESS,
  payload,
});

export const updateUserEmailGroupsFailed = (payload) => ({
  type: UPDATE_USER_EMAIL_GROUPS_FAILED,
  payload,
});
