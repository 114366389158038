import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { ManagersEnum } from '../../collapsible-panel/managers.enum';
import ManagerDetails from './ManagerDetails';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  getManagersForLocation,
  getDoctorsForLocation,
  getPsPartnersForLocation,
  getHrPartnersForLocation,
  getSelectedStaff,
} from '../../offices/offices-selectors';
import { HrPartnersEnum } from '../../collapsible-panel/hr-partners.enum';
import { getIsWriteAllAllowed } from '../../../store/user/user-reducer';
import { setSelectedStaff, unassignStuff } from '../../offices/offices-actions';
import { PsPartnersEnum } from '../../collapsible-panel/ps-partners.enum';
import { getIsWriteContactAllowed } from '../../../store/user/user-reducer';


function StaffList({ locId, title }) {
  const selectorMap = {
    managers: getManagersForLocation,
    doctors: getDoctorsForLocation,
    ps_partners: getPsPartnersForLocation,
    hr_partners: getHrPartnersForLocation,
  };
  const data = useSelector((state) => selectorMap[title](state, locId));
  const selectedStaff = useSelector(getSelectedStaff);
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [staffOverKey, setStaffOverKey] = useState('');
  const isSelected = ({ id, job }) => id === selectedStaff['id'] && job === selectedStaff['job'];
  const btnClassName = (manager) => `staff-link-text ${isSelected(manager) ? 'selected' : ''}`;
  const modalTitle = `\u002B ADD NEW ${title.split('_').join(' ').toUpperCase()}`;
  const managerDetails = data.find(isSelected);

   const isEditAllowed = useSelector(getIsWriteContactAllowed) || useSelector(getIsWriteAllAllowed);



  useEffect(() => {
    dispatch(setSelectedStaff({}));
  }, [locId]);

  return data ? (
    <>
      {data.map((employee) => (
        <div
          key={title !== 'doctors' ? `${employee.id}${employee.job}` : employee.id}
          className={'stuff-item-container'}
          onMouseLeave={() => setStaffOverKey('null')}
        >
          {title !== 'doctors' && (
            <div className='label-text'>
              {title === 'managers' && ManagersEnum[employee.job]}
              {title === 'ps_partners' && PsPartnersEnum[employee.job]}
              {title === 'hr_partners' && HrPartnersEnum[employee.job]}
            </div>
          )}
          <div
            className={'stuff-actions'}
            onMouseOver={(/*event*/) => setStaffOverKey(`${employee.id}_${employee.job}`)}
          >
            <Button
              type={'link'}
              className={btnClassName(employee)}
              onClick={() => {
                dispatch(setSelectedStaff({ id: employee.id, job: employee.job }));
              }}
            >
              {employee.name}
            </Button>

            {staffOverKey === `${employee.id}_${employee.job}` && (
              <Button
                type={'link'}
                className={'unassign-action'}
                disabled={!isEditAllowed}
                onClick={() => dispatch(unassignStuff({ contactId: employee.id, locationId: locId, job: employee.job}))}
                icon={<img src={'./icons/delete-action.svg'} alt='unassign' title='Unassign' />}
              />
            )}
          </div>
        </div>
      ))}

      <Button
        type='default'
        className={'ant-btn-default btn-form-action'}
        disabled={!isEditAllowed }
        onClick={() => {
          setIsModalVisible(true);
          dispatch(setSelectedStaff({}));
        }}
      >
        {modalTitle}
      </Button>

      {!isNil(selectedStaff) && !isNil(managerDetails) && isSelected(managerDetails) && (
        <ManagerDetails data={managerDetails} title={title.split('_').join(' ')} locId={locId} />
      )}
      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        centered
        footer={null}
      >
        <ManagerDetails
          isCreateMode={true}
          title={title.split('_').join(' ')}
          locId={locId}
          onClose={() => setIsModalVisible(false)}
        />
      </Modal>
    </>
  ) : null;
}

export default StaffList;
