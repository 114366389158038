import React from 'react';
import { DayOfWeek } from '../../../models/day-enum';
import { ScheduleTimeCell } from '@med-fe/ui';

export const schedule_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'timezone',
    dataIndex: 'timezone',
    key: 'timezone',
    width: 60,
  },
  {
    title: DayOfWeek.Monday,
    dataIndex: DayOfWeek.Monday,
    key: DayOfWeek.Monday,
    width: 135,
    align: 'center',
    render: (value: string) => <ScheduleTimeCell value={value} />,
  },
  {
    title: DayOfWeek.Tuesday,
    dataIndex: DayOfWeek.Tuesday,
    key: DayOfWeek.Tuesday,
    width: 135,
    align: 'center',
    render: (value: string) => <ScheduleTimeCell value={value} />,
  },
  {
    title: DayOfWeek.Wednesday,
    dataIndex: DayOfWeek.Wednesday,
    key: DayOfWeek.Wednesday,
    width: 135,
    align: 'center',
    render: (value: string) => <ScheduleTimeCell value={value} />,
  },
  {
    title: DayOfWeek.Thursday,
    dataIndex: DayOfWeek.Thursday,
    key: DayOfWeek.Thursday,
    width: 135,
    align: 'center',
    render: (value: string) => <ScheduleTimeCell value={value} />,
  },
  {
    title: DayOfWeek.Friday,
    dataIndex: DayOfWeek.Friday,
    key: DayOfWeek.Friday,
    width: 135,
    align: 'center',
    render: (value: string) => <ScheduleTimeCell value={value} />,
  },
  {
    title: DayOfWeek.Saturday,
    dataIndex: DayOfWeek.Saturday,
    key: DayOfWeek.Saturday,
    width: 135,
    align: 'center',
    render: (value: string) => <ScheduleTimeCell value={value} />,
  },
  {
    title: DayOfWeek.Sunday,
    dataIndex: DayOfWeek.Sunday,
    key: DayOfWeek.Sunday,
    width: 135,
    align: 'center',
    render: (value: string) => <ScheduleTimeCell value={value} />,
  },
  {
    title: 'Effective date',
    dataIndex: 'effectiveDate',
    key: 'effectiveDate',
    width: 135,
  },
  {}, // empty column, workaround to have column width fixed
];
