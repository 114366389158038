import React from 'react';
import styles from './MapWrapper.module.scss';
import {InteractiveMap} from './InteractiveMap';
import {getLocationsTotal} from '../offices/offices-selectors';
import {useSelector} from 'react-redux';


function MapWrapper() {
  const total = useSelector(getLocationsTotal);

  return (
    <div className={styles['map-container']}>
      <div className={styles['map-content']}>
        <InteractiveMap />
      </div>
      <div className={styles['map-footer']}>
        <div className={styles['total-container']}>
          <div className={styles['total-title']}>TOTAL OFFICES</div>
          <div className={styles['total-count']}>
            <span>{total}</span>
          </div>
        </div>

        <img alt="logo" className={styles['logo']} src={'./images/logo.svg'}/>
      </div>
    </div>
  )
}

export default MapWrapper
