import React from 'react';
import { CheckBoxCell } from '@med-fe/ui';

export const mvc_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
//   {
//     title: 'medical records type',
//     dataIndex: 'medicalRecordsType',
//     key: 'medicalRecordsType',
//     width: 160,
//   },
  {
    title: 'acuity logic launch',
    dataIndex: 'acuityLogicLaunchDate',
    key: 'acuityLogicLaunchDate',
    width: 160,
  },
  {
    title: 'ps analyst',
    dataIndex: 'professionalServicesAnalyst',
    key: 'professionalServicesAnalyst',
    width: 160,
  },
  {
    title: 'hr business partner',
    dataIndex: 'hrBusinessPartner',
    key: 'hrBusinessPartner',
    width: 120,
  },
  {
    title: 'Medicaid Loc.',
    dataIndex: 'medicaidLocation',
    key: 'medicaidLocation',
    width: 80,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
//   {
//     title: 'dav. loc.',
//     dataIndex: 'davisLocation',
//     key: 'davisLocation',
//     width: 80,
//     colType: 'checkbox',
//     render: (value: boolean) => <CheckBoxCell value={value} />,
//   },
  /* {
    title: 'vsp. loc.',
    dataIndex: 'vspLocation',
    key: 'vspLocation',
    width: 80,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  }, */
  {
        title: 'CLIA EXPIRATION',
        dataIndex: 'cliaExpiration',
        key: 'cliaExpiration',
        width: 160,
   },
  {
    title: 'Group NPI',
    dataIndex: 'groupNpi',
    key: 'groupNpi',
    width: 80,
  },
  {
    title: 'Eyemed Provider ID',
    dataIndex: 'epId',
    key: 'epId',
    width: 120,
  },
  {
    title: 'BCBS',
    dataIndex: 'bcbs',
    key: 'bcbs',
    width: 80,
  },
  {
    title: 'Medicare',
    dataIndex: 'medicare',
    key: 'medicare',
    width: 80,
  },
  {
    title: 'Tricare',
    dataIndex: 'tricare',
    key: 'tricare',
    width: 80,
  },
  {
    title: 'Legacy AR Acquired',
    dataIndex: 'legacyArAcq',
    key: 'legacyArAcq',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
//   {
//     title: 'Legacy AR Closed',
//     dataIndex: 'legacyArClosed',
//     key: 'legacyArClosed',
//     width: 110,
//   },
//   {
//     title: 'Transition AR Closed',
//     dataIndex: 'transitionArClosed',
//     key: 'transitionArClosed',
//     width: 130,
//   },
//   {
//     title: 'Greenfield Location',
//     dataIndex: 'naLocation',
//     key: 'naLocation',
//     width: 100,
//     colType: 'checkbox',
//     render: (value: boolean) => <CheckBoxCell value={value} />,
//   },
  {
    title: 'Medicaid Intention',
    dataIndex: 'medicaidIntention',
    key: 'medicaidIntention',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
//   {
//     title: 'VSP Status',
//     dataIndex: 'vspStatus',
//     key: 'vspStatus',
//     width: 80,
//   },
  {
    title: 'Vintage Name',
    dataIndex: 'vintageName',
    key: 'vintageName',
    width: 80,
  },
  {
      title: 'Medicare DME',
      dataIndex: 'medicareDme',
      key: 'medicareDme',
      width: 100,
      colType: 'checkbox',
      render: (value: boolean) => <CheckBoxCell value={value} />,
    },
  {}, // empty column, workaround to have column width fixed
];
