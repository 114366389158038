import React, { useEffect, useState } from 'react';
import { Checkbox, Collapse, Switch } from 'antd';

import ExpandIcon from '../../common/ExpandIcon';
import styled from 'styled-components';
import { SPECIALTIES_ICONS_MAP } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSpecialties } from '../../../store/common/common-selector';
import { setSelectedDoctorSpecialties } from '../doctors-actions';
import { isNil } from 'lodash';

const { Panel } = Collapse;

export const DoctorPanelSpecialties = ({ initialSpecialties, initialSpecialtyProgram, isEditable }) => {
  const dispatch = useDispatch();
  const allSpecialties = useSelector(getAllSpecialties);
  const [specialtyValues, setSpecialtyValues] = useState({});
  const [specialtyProgramPart, setSpecialtyProgramPart] = useState(false);
  const [isDisabledSpecialties, setIsDisabledSpecialties] = useState(false);

  const generateDefaultSpecialtiesValues = () => {
    if (allSpecialties.length && initialSpecialties) {
      setSpecialtyValues(
        allSpecialties.reduce(
          (acc, current) => ({ ...acc, [current.id]: !!initialSpecialties?.find((spec) => current.id === spec.id) }),
          {}
        )
      );
    }
  };

  const getSpecialtiesPayload = (specialties) => {
    return allSpecialties.filter(({ id }) => !!specialties[id]);
  };

  const handleOnChange = (id) => {
    const specialties = { ...specialtyValues, [id]: !specialtyValues[id] };
    setSpecialtyValues(specialties);
    dispatch(setSelectedDoctorSpecialties({ specialties: getSpecialtiesPayload(specialties), specialtyProgramPart }));
  };

  const handleSpecialtyProgram = () => {
    setSpecialtyProgramPart(!specialtyProgramPart);
    setIsDisabledSpecialties(specialtyProgramPart);
    dispatch(
      setSelectedDoctorSpecialties({
        specialties: getSpecialtiesPayload(specialtyValues),
        specialtyProgramPart: !specialtyProgramPart,
      })
    );
  };

  useEffect(() => {
    generateDefaultSpecialtiesValues();
  }, [allSpecialties, initialSpecialties]);

  useEffect(() => {
    if (!isNil(initialSpecialtyProgram)) {
      setSpecialtyProgramPart(initialSpecialtyProgram);
      setIsDisabledSpecialties(!initialSpecialtyProgram);
    }
  }, [initialSpecialtyProgram]);

  useEffect(() => {
    if (!isNil(isEditable) && !isEditable) {
      setSpecialtyProgramPart(initialSpecialtyProgram);
      setIsDisabledSpecialties(!initialSpecialtyProgram);
      console.log('generateDefaultSpecialtiesValues', initialSpecialties);
      generateDefaultSpecialtiesValues();
      dispatch(setSelectedDoctorSpecialties(null));
    }
  }, [isEditable]);

  useEffect(() => {
    return () => {
      setSpecialtyValues({});
      dispatch(setSelectedDoctorSpecialties(null));
    };
  }, []);

  return (
    <Collapse destroyInactivePanel bordered={false} expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}>
      <Panel header='SPECIALTIES' key='1' showArrow>
        <SpecialtyProgramWrapper>
          <span>SPECIALTY PROGRAM</span>
          <SpecialtySwitcher
            disabled={!isEditable}
            checked={specialtyProgramPart}
            onChange={() => handleSpecialtyProgram()}
          />
        </SpecialtyProgramWrapper>
        {allSpecialties?.map(({ shortName, fullName, id }) => (
          <SpecialtyItem key={shortName}>
            <div>
              <img
                src={`./icons/specialties/${SPECIALTIES_ICONS_MAP[shortName] || SPECIALTIES_ICONS_MAP.DEFAULT}.svg`}
                alt={shortName}
              />
              <span>{fullName}</span>
            </div>
            <Checkbox
              disabled={!isEditable || isDisabledSpecialties}
              checked={specialtyValues[id]}
              onChange={() => handleOnChange(id)}
            />
          </SpecialtyItem>
        ))}
      </Panel>
    </Collapse>
  );
};

const SpecialtyProgramWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #003366;
`;

const SpecialtyItem = styled.div`
  padding-bottom: 2px;
  margin: 0 40px 2px 40px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e3e3;

  img {
    height: 50px;
    margin-right: 10px;
  }
`;

const SpecialtySwitcher = styled(Switch)`
  background-color: #40668c;
`;
