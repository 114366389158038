import { ManagersEnum } from '../../collapsible-panel/managers.enum';
import { HrPartnersEnum } from '../../collapsible-panel/hr-partners.enum';
import { isEqual } from 'lodash';
import { PsPartnersEnum } from '../../collapsible-panel/ps-partners.enum';
import { PHONE_VALIDATION_RULE } from '@med-fe/util';
import { EMAIL_VALIDATION_RULE } from '@med-fe/util';
import { EMAIL_REQUIRED_RULE } from '@med-fe/util';
import { NPI_VALIDATION_RULE } from '@med-fe/util';
import { NAME_REQUIRED_RULE } from '@med-fe/util';

export const staffDetailsConfig = (title, isCreateMode) => ({
  job: {
    label: 'Job Position',
    isVisible: title !== 'doctors' && title !== 'VEH Doctor',
    type: 'select',
    data: [
      ...(isEqual(title, 'managers') ? Object.entries(ManagersEnum) : []),
      ...(isEqual(title, 'ps partners') ? Object.entries(PsPartnersEnum) : []),
      ...(isEqual(title, 'hr partners') ? Object.entries(HrPartnersEnum) : []),
    ],
    ...(isCreateMode && { rules: [{ required: true, message: 'Please select Job Position' }] }),
  },
  name: {
    label: 'Name',
    isVisible: true,
    type: 'text',
    disabled: true,
    rules: [NAME_REQUIRED_RULE],
  },
  npi: {
      label: 'Npi',
      isVisible: true,
      type: 'text',
      disabled: true,
      rules: [NPI_VALIDATION_RULE],
  },
  phone: {
    label: 'Phone',
    isVisible: true,
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
  },
  email: {
    label: 'Email',
    isVisible: true,
    type: 'text',
    disabled: true,
    required: true,
    rules: [EMAIL_REQUIRED_RULE,EMAIL_VALIDATION_RULE],
  },
  startDate: {
    label: 'Start Date',
    isVisible: true,
    type: 'date',
    disabled: true,
  },
});
