import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { upperCase } from 'lodash';

export const SelectAllButton = ({ whatSelect, isAllSelected, onClick }) => (
  <StyledButton type='ghost' onClick={onClick}>
    {isAllSelected ? `UNSELECT ALL ${upperCase(whatSelect)}` : `SELECT ALL ${upperCase(whatSelect)}`}
  </StyledButton>
);

const StyledButton = styled(Button)`
  margin-left: 30px;
  color: #003366;
  border-color: #003366;
  padding: 0 15px;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
`;
