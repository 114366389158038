import { call, put, select } from 'redux-saga/effects';
import {
  fetchDoctorsFailed,
  fetchDoctorsSuccess,
  fetchDoctorByIdSuccess,
  fetchDoctorByIdFailed,
  updateDoctorSuccess,
  updateDoctorFailed,
  uploadDoctorPhotoSuccess,
  uploadDoctorPhotoFailed,
  uploadDoctorPhoto,
  fetchDoctors,
  fetchDoctorById,
} from './doctors-actions';
import { api, showErrorNotification } from '@med-fe/util';
import { getSearchDoctors } from './doctors-selectors';

export function* fetchDoctorsStartWorker({ payload }: any): Generator {
  try {
    const url = '/employees/search';
    const data: any = yield call(api.post, url, payload);
    yield put(fetchDoctorsSuccess(data));
  } catch (e) {
    yield put(fetchDoctorsFailed(e));
    showErrorNotification(null, e);
  }
}

export function* fetchDoctorByIdStartWorker({ payload }: { payload: string }): Generator {
  try {
    const url = `/employees/${payload}`;
    const data = yield call(api.get, url);
    yield put(fetchDoctorByIdSuccess(data));
  } catch (e) {
    yield put(fetchDoctorByIdFailed(e));
    showErrorNotification(null, e);
  }
}

export function* updateDoctorStartWorker({ payload }: any): Generator {
  try {
    const search = yield select(getSearchDoctors);
    const url = '/employees';
    const data: any = yield call(api.post, url, payload.finalData);

    if (payload.doctorImageData) {
      yield call(uploadDoctorPhotoStartWorker, {
        payload: { employeeId: payload.finalData.id, file: payload.doctorImageData },
      });
    }

    yield put(updateDoctorSuccess(data));
    yield put(fetchDoctorById(payload.finalData.id));
    yield put(fetchDoctors(search));
  } catch (e) {
    yield put(updateDoctorFailed(e));
    showErrorNotification(e.response.data.reason);
  }
}

export function* uploadDoctorPhotoStartWorker({ payload }: any): Generator {
  try {
    const url = '/employees/photo';
    const formdata = new FormData();
    formdata.append('employeeId', payload.employeeId);
    formdata.append('file', payload.file);
    const data: any = yield call(api.post, url, formdata);
    yield put(uploadDoctorPhotoSuccess(data));
  } catch (e) {
    yield put(uploadDoctorPhotoFailed(e));
    showErrorNotification(null, e);
  }
}
