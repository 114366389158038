import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Form, Input, DatePicker, Button, Switch, Upload, Spin } from 'antd';
import Icon, { CameraOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import { fetchDoctorById, updateDoctor } from '../doctors/doctors-actions';
import { getDoctor, getIsLoadingDoctor, getSelectedDoctorSpecialties } from '../doctors/doctors-selectors';
import { getIsWriteAllAllowed, getIsWriteDoctorsAllowed } from '../../store/user/user-reducer';
import DoctorPanelOffices from '../doctors/components/DoctorPanelOffices';
import styles from './DoctorPanel.module.scss';
import { getInvalidDates, showErrorNotification, getDateFromMoment, getMomentDate } from '@med-fe/util';
import { DoctorPanelSpecialties } from '../doctors/components/DoctorPanelSpecialties';
import { NPI_VALIDATION_RULE } from '@med-fe/util';
import { EMAIL_VALIDATION_RULE } from '@med-fe/util';
import { EMAIL_REQUIRED_RULE } from '@med-fe/util';

const { TextArea } = Input;

function DoctorPanel({ hideDetails, selectedDoctorId }) {
  const dispatch = useDispatch();
  const doctor = useSelector(getDoctor);
  const loading = useSelector(getIsLoadingDoctor);
  const isWriteAllAllowed = useSelector(getIsWriteAllAllowed);
  const isWriteDoctorsAllowed = useSelector(getIsWriteDoctorsAllowed);
  const selectedDoctorSpecialties = useSelector(getSelectedDoctorSpecialties);
  const isEditAllowed = isWriteAllAllowed || isWriteDoctorsAllowed;

  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [doctorImageData, setDoctorImageData] = useState(null);
  const [doctorImageUrl, setDoctorImageUrl] = useState(null);

  const handleChangeIsEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleChangeIsOnline = () => {
    setIsOnline(!isOnline);
  };

  const handleFinishEditing = (formData) => {
    let finalData = {
      ...doctor,
      teaser: formData.teaser,
      startDate: formData.startDate && getDateFromMoment(formData.startDate),
      endDate: formData.endDate && getDateFromMoment(formData.endDate),
      name: formData.name,
      preferredName: formData.preferredName,
      personalEmail: formData.personalEmail,
      email: formData.email,
      employeeNumber: formData.employeeNumber,
      npi: formData.npi,
      bio: formData.bio,
      status: isOnline ? 'ACTIVE' : 'INACTIVE',
    };

    if (selectedDoctorSpecialties) {
      const { specialtyProgramPart, specialties } = selectedDoctorSpecialties;
      finalData = {
        ...finalData,
        specialtyProgramPart,
        specialties,
      };
    }

    dispatch(updateDoctor({ finalData, doctorImageData }));
    form.resetFields();
    setDoctorImageData(null);
    handleChangeIsEditable();
  };

  const beforeImageUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      showErrorNotification('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      showErrorNotification('Image must be smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const customUploadPhotoRequest = (options) => {
    const { onSuccess, onError, file } = options;

    try {
      onSuccess(setDoctorImageData(file));
    } catch (e) {
      onError(showErrorNotification('Error, please try again'));
    }
  };

  const handleImageChange = async ({ file }) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setDoctorImageUrl(src);
  };

  useEffect(() => {
    if (typeof selectedDoctorId === 'number') {
      dispatch(fetchDoctorById(selectedDoctorId));
    }
    setIsEditable(false);
    setDoctorImageUrl(null);
    setDoctorImageData(null);
  }, [selectedDoctorId]);

  const initializeForm = () => {
    form.setFieldsValue({
      teaser: doctor.teaser,
      name: doctor.name,
      preferredName: doctor.preferredName,
      personalEmail: doctor.personalEmail,
      email: doctor.email,
      employeeNumber: doctor.employeeNumber,
      npi: doctor.npi,
      bio: doctor.bio,
      startDate: doctor.startDate && getMomentDate(doctor.startDate),
      endDate: doctor.endDate && getMomentDate(doctor.endDate),
    });
  };

  useEffect(() => {
    setIsOnline(doctor.status === 'ACTIVE');

    initializeForm();
  }, [doctor]);

  return (
    <Spin spinning={loading}>
      <DoctorPanelContainer className={styles['doctor-panel']} isOnline={isOnline} $loading={loading}>
        <Form layout='vertical' form={form} onFinish={handleFinishEditing}>
          <div className={styles['doctor-panel-top']}>
            <div className={styles['doctor-panel-line']} />
            <div className={styles['doctor-panel-top-left']}>
              {isEditable && (
                <ImgCrop shape={'round'} onModalOk={() => true} grid>
                  <Upload
                    name='avatar'
                    showUploadList={false}
                    customRequest={customUploadPhotoRequest}
                    beforeUpload={beforeImageUpload}
                    onChange={handleImageChange}
                  >
                    <CameraOutlined className={styles['camera-icon']} />
                  </Upload>
                </ImgCrop>
              )}
              <Avatar
                className={styles['doctor-panel-avatar']}
                src={
                  doctorImageUrl
                    ? doctorImageUrl
                    : doctor.photoUrl
                    ? doctor.photoUrl
                    : isEditable || './icons/avatar-inactive.svg'
                }
                size={90}
              />
            </div>
            <div className={styles['doctor-panel-top-right']}>
              <div className={styles['doctor-panel-top-info']}>
                <Button type={'link'} className={'edit-cell'}>
                  <span className={styles['doctor-panel-top-name']}>{doctor.name}</span>
                </Button>
                <span className={styles['doctor-panel-top-id']}>{doctor.employeeNumber}</span>
                <Button className={styles['doctor-panel-button']} onClick={hideDetails}>
                  <CloseIcon className={styles['doctor-panel-close-icon']} />
                </Button>
              </div>
              <div className={styles['doctor-panel-top-edit']}>
                {isEditable ? (
                  <>
                    <CustomSwitch
                      className={styles['doctor-panel-top-switch']}
                      onClick={handleChangeIsOnline}
                      checked={!isOnline}
                      checkedChildren='Inactive'
                      unCheckedChildren='Active'
                    />
                    <Button className={styles['doctor-panel-button-save']} htmlType='submit'>
                      SAVE
                    </Button>
                    <Button
                      type={'ghost'}
                      className={styles['doctor-panel-button-cancel']}
                      onClick={() => {
                        setIsEditable(false);
                        initializeForm();
                        setDoctorImageUrl(null);
                        setDoctorImageData(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    className={styles['doctor-panel-button-edit']}
                    onClick={handleChangeIsEditable}
                    disabled={!isEditAllowed}
                  >
                    <EditIcon className={styles['doctor-panel-edit-icon']} />
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className={`${styles['doctor-panel-row']} location-details-form`}>
            <Form.Item className={styles['doctor-panel-label']} label='Teaser (max. 150 characters)' name='teaser'>
              {isEditable ? <Input maxLength={150} /> : <strong>{doctor.teaser}</strong>}
            </Form.Item>
            <Form.Item className={styles['doctor-panel-label-short']} label='Hire date' name='startDate'>
              {isEditable ? (
                <DatePicker disabled={!isWriteAllAllowed} suffixIcon={null} disabledDate={getInvalidDates} />
              ) : (
                <Field>{doctor.startDate || '-'}</Field>
              )}
            </Form.Item>
            <Form.Item className={styles['doctor-panel-label-short']} label='Leave date' name='endDate'>
              {isEditable ? (
                <DatePicker suffixIcon={null} disabledDate={getInvalidDates} />
              ) : (
                <Field>{doctor.endDate || '-'}</Field>
              )}
            </Form.Item>
          </div>

          <div className={`${styles['doctor-panel-row']} location-details-form`}>
            <Form.Item className={styles['doctor-panel-label']} label='Employee Name' name='name' required={isEditable}>
              {isEditable ? <Input disabled={!isWriteAllAllowed} /> : <Field>{doctor.name}</Field>}
            </Form.Item>
            <Form.Item
              className={styles['doctor-panel-label']}
              label='Office Email'
              name='email'
              rules={[EMAIL_REQUIRED_RULE, EMAIL_VALIDATION_RULE]}
            >
              {isEditable ? <Input /> : <Field>{doctor.email}</Field>}
            </Form.Item>
          </div>

          <div className={`${styles['doctor-panel-row']} location-details-form`}>
            <Form.Item className={styles['doctor-panel-label']} label='Preferred Name' name='preferredName'>
              {isEditable ? <Input disabled={!isWriteAllAllowed} /> : <Field>{doctor.preferredName}</Field>}
            </Form.Item>
            <Form.Item className={styles['doctor-panel-label']} label='NPI' name='npi' rules={[NPI_VALIDATION_RULE]}>
              {isEditable ? <Input disabled={doctor.npi} /> : <Field>{doctor.npi}</Field>}
            </Form.Item>
          </div>

          <div className={`${styles['doctor-panel-row']} location-details-form`}>
            <Form.Item className={styles['doctor-panel-label-short']} label='Employee Number' name='employeeNumber'>
              {isEditable ? <Input disabled={true} /> : <Field>{doctor.employeeNumber}</Field>}
            </Form.Item>
          </div>

          <div className={`location-details-form`}>
            <Form.Item label='Biography (max. 8000 characters)' name='bio'>
              {isEditable ? (
                <TextArea className={styles['doctor-panel-field-editable']} maxLength={8000} />
              ) : (
                <Field className={styles['doctor-panel-field']}>{doctor.bio}</Field>
              )}
            </Form.Item>
          </div>
        </Form>
      </DoctorPanelContainer>

      {selectedDoctorId && selectedDoctorId === doctor?.id && (
        <DoctorPanelSpecialties
          initialSpecialties={doctor.specialties}
          initialSpecialtyProgram={doctor.specialtyProgramPart}
          isEditable={isEditable}
        />
      )}
      {doctor.locations && <DoctorPanelOffices locations={doctor?.locations} />}
    </Spin>
  );
}

export default DoctorPanel;

const Field = styled.p`
  text-align: left;
  margin: 0;
`;

const DoctorPanelContainer = styled.div`
  padding: 20px 30px;
  position: relative;

  &:after {
    border-color: transparent transparent transparent ${({ isOnline }) => (isOnline ? '#45a58e' : '#D14D4D')};
  }
`;

const CustomSwitch = styled(Switch)`
  .ant-switch-handle:before {
    background-color: ${(props) => (props.checked ? '#D14D4D' : '#45A58E')};
  }
`;

const CloseIconSvg = () => (
  <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5 3.41375L7.91375 0.5L9.5 2.08625L6.58625 5L9.5 7.91375L7.91375 9.5L5 6.58625L2.08625 9.5L0.5 7.91375L3.41375 5L0.5 2.08625L2.08625 0.5L5 3.41375Z' />
  </svg>
);
export const CloseIcon = (props) => <Icon component={CloseIconSvg} {...props} />;

const EditIconSvg = () => (
  <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 17 17' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.2664 5.89244L11.0649 6.69105L3.20136 14.5556H2.40286V13.757L10.2664 5.89244V5.89244ZM13.3909 0.666748C13.1739 0.666748 12.9483 0.753554 12.7834 0.918484L11.195 2.50703L14.4498 5.76223L16.0381 4.17369C16.3766 3.83515 16.3766 3.28828 16.0381 2.94973L14.0072 0.918484C13.8336 0.744873 13.6166 0.666748 13.3909 0.666748V0.666748ZM10.2664 3.43584L0.666992 13.0365V16.2917H3.92175L13.5211 6.69105L10.2664 3.43584V3.43584Z' />
  </svg>
);
const EditIcon = (props) => <Icon component={EditIconSvg} {...props} />;
