import { IDoctorsState, ISearchDoctors, DOCTORS_STATE_KEY } from './doctors-reducer';

const generateDoctorLocationsInfo = (locations: any, key: string): string =>
  locations
    .map((item) => item[key])
    .filter((value, index, self) => self.indexOf(value) === index)
    .join(', ');

const distinctArray = (array) =>
  array
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((item) => ({
      locationNumber: item.locationNumber,
      locationName: item.locationName,
      primaryLocation: item.primaryLocation,
    }));

export const getIsLoading = (state: any) => state[DOCTORS_STATE_KEY].loading;
export const getIsLoadingDoctor = (state: any) => state[DOCTORS_STATE_KEY].loadingDoctor;
export const getError = (state: any) => state[DOCTORS_STATE_KEY].error;
export const getDoctors = (state: any) => state[DOCTORS_STATE_KEY].doctors;
export const getDoctor = (state: any) => state[DOCTORS_STATE_KEY].doctor;
export const getLocationById = (state: any, id: string) =>
  state[DOCTORS_STATE_KEY].doctors.contents.find((loc) => loc.id === id);
export const getSearchDoctors = (state: IDoctorsState): ISearchDoctors => state[DOCTORS_STATE_KEY].search;
export const getDoctorsTotal = (state: any) => getDoctors(state).total;
export const getDoctorsForGrid = (state: any) =>
  getDoctors(state).contents.map((content: any) => {
    const { locations, ...data } = content;
    let locationsInfo = {
      district: generateDoctorLocationsInfo(locations, 'district'),
      region: generateDoctorLocationsInfo(locations, 'region'),
      city: generateDoctorLocationsInfo(locations, 'city'),
      state: generateDoctorLocationsInfo(locations, 'state'),
      locationNumber: distinctArray(locations),
    };

    return {
      key: content.id,
      ...data,
      ...locationsInfo,
    };
  });
export const getSelectedDoctorSpecialties = (state: any) => state[DOCTORS_STATE_KEY].selectedDoctorSpecialties;
