import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { OfficeGridTabs } from './OfficeGridTabs';

const { TabPane } = Tabs;

export const OfficeTabs = ({ tabs, invalidOffices }) => {
  return (
    <StyledOfficeTabs className={'office-tabs'} tabPosition={'left'}>
      {tabs.map((item) => (
        <TabPane
          key={item.locationNumber}
          tab={
            <span className={invalidOffices.includes(item.locationNumber) && 'invalid-office-tab'}>
              {item.locationNumber}
            </span>
          }
        >
          <OfficeGridTabs tabs={item.modifiedTabs} locationNumber={item.locationNumber} />
        </TabPane>
      ))}
    </StyledOfficeTabs>
  );
};

const StyledOfficeTabs = styled(Tabs)`
  max-height: 350px;

  > {
    .ant-tabs-nav {
      .ant-tabs-tab {
        font-size: 13px;
        padding: 2px 25px;

        .invalid-office-tab {
          color: #d14d4d;
        }

        + .ant-tabs-tab {
          margin-top: 10px;
        }
      }
    }
  }
`;
