import React, { useEffect } from 'react';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Header from './components/header/Header';
import Audit from './components/audit/Audit';
import Offices from './components/offices/Offices';

import styles from './App.module.scss';
import { setNotificationsConfig, reactApp } from '@med-fe/util';
import { get, flow } from 'lodash';
import { setUserRoles } from './store/user/user-actions';
import {
  getIsAccessDenied,
  getIsReadDoctorsAllowed,
  getReadTabsPermissions,
  getIsWriteDoctorsAllowed,
  getWriteTabsPermissions,
} from './store/user/user-reducer';
import Doctors from './components/doctors/Doctors';
import { fetchAllSpecialties, fetchEquipment } from './store/common/common-actions';
import { Login } from '@med-fe/ui';
import { Statistics } from './components/statistics/Statistics';
import { InteractionType } from '@azure/msal-browser';
import SIGN_IN_LOGO from './assets/images/sign-in-logo.svg';
const routes = [
  {
    path: '/offices',
    title: 'Offices',
    content: () => <Offices />,
  },
  {
    path: '/doctors',
    title: 'Doctors',
    content: () => <Doctors />,
  },
  {
    path: '/audit',
    title: 'Audit',
    content: () => <Audit />,
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    content: () => <Statistics />,
  },
];

export default function App() {
  setNotificationsConfig();
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const isAccessDenied = useSelector(getIsAccessDenied);
  const routerBasename = reactApp().routerBasename;
  const readTabsPermissions = useSelector(getReadTabsPermissions);
  const writeTabsPermissions = useSelector(getWriteTabsPermissions);
  const isReadDoctorsAllowed = useSelector(getIsReadDoctorsAllowed);
  const isWriteDoctorsAllowed = useSelector(getIsWriteDoctorsAllowed);

  useEffect(() => {
    dispatch(setUserRoles(get(instance.getActiveAccount(), 'idTokenClaims.roles', [])));

    if (get(accounts, 'length')) {
      dispatch(fetchAllSpecialties());
      dispatch(fetchEquipment());
    }
  }, [accounts]);

  const filteredRoutes = () => {
    const isNoTabsReadAllowed = Object.values(readTabsPermissions ?? {}).every((tab) => !tab);
    const isNoTabsWriteAllowed = Object.values(writeTabsPermissions ?? {}).every((tab) => !tab);
    const filterOfficeRoute = (routes) =>
      isNoTabsReadAllowed && isNoTabsWriteAllowed ? routes.filter(({ title }) => title !== 'Offices') : routes;
    const filterDoctorsRoute = (routes) =>
      !(isReadDoctorsAllowed || isWriteDoctorsAllowed) ? routes.filter(({ title }) => title !== 'Doctors') : routes;

    return flow(filterOfficeRoute, filterDoctorsRoute)(routes);
  };

  return (
    <Router basename={routerBasename}>
      <div className={styles.App}>
        <div className={styles.container}>
          <Header routes={filteredRoutes()} />
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={(result) => (
              <section className='login-screen'>
                <section className='login-card'>
                  <img src={SIGN_IN_LOGO} width={120} height={120} />
                  <div>{result.error?.message}</div>
                </section>
              </section>
            )}
            loadingComponent={() => (
              <section className='login-screen'>
                <section className='login-card'>
                  <img src={SIGN_IN_LOGO} width={120} height={120} />
                  <div>Validating Login</div>
                </section>
              </section>
            )}
          >
            {isAccessDenied ? (
              <p className={styles['unauthenticated-text']}>
                You have no permission. Please contact your administrator.
              </p>
            ) : (
              <main className={styles.main}>
                <Switch>
                  <Route exact path='/'>
                    <Redirect to={filteredRoutes()[0].path} />
                  </Route>
                  {filteredRoutes().map((route, index) => (
                    <Route key={index} path={route.path} children={<route.content />} />
                  ))}
                </Switch>
              </main>
            )}
          </MsalAuthenticationTemplate>

          {/* <UnauthenticatedTemplate>
            <Login instance={instance} />
          </UnauthenticatedTemplate> */}
        </div>
      </div>
    </Router>
  );
}
