import {
  CREATE_ADDRESS,
  CREATE_ADDRESS_FAILED, CREATE_ADDRESS_SUCCESS, DELETE_ADDRESS, DELETE_ADDRESS_FAILED, DELETE_ADDRESS_SUCCESS,
  FETCH_ADDRESS,
  FETCH_ADDRESS_FAILED,
  FETCH_ADDRESS_SUCCESS,
} from './address-actions';
import {AddressDto} from '../../models/address-dto';

export const ADDRESS_STATE_KEY = 'ADDRESS_REDUCER';

export const getAddress = (state: any) => state[ADDRESS_STATE_KEY].address;

export interface IAddressState {
  loading: boolean,
  address: AddressDto[],
  error: any,
}

const defaultState: IAddressState = {
  loading: false,
  address: [],
  error: null,
};

export const addressReducer = (state = defaultState, {type, payload}: any) => {
  switch (type) {
    case FETCH_ADDRESS:
      return state;
    case FETCH_ADDRESS_SUCCESS:
      return {
        ...state,
        address: payload,
      }
    case FETCH_ADDRESS_FAILED:
      return {
        ...state,
        error: payload,
      }
    case CREATE_ADDRESS:
      return state;
    case CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        address: [...state.address, payload],
      }
    case CREATE_ADDRESS_FAILED:
      return {
        ...state,
        error: payload,
      }
    case DELETE_ADDRESS:
      return state;
    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        address: state.address.filter(item => item.id !== payload),
      }
    case DELETE_ADDRESS_FAILED:
      return {
        ...state,
        error: payload,
      }
    default:
      return state;
  }
};
