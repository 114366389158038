import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getExportLoading, getImportLoading, getImportValidationData } from '../header-reducer';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { useLocation } from 'react-router-dom';
import { ExportImportButton, ExportImportButtonText } from '@med-fe/ui';
import { ValidationModal } from './ValidationModal';
import { UploadModal } from './UploadModal';

function Import() {
  const { pathname } = useLocation();
  const writeTabsPermissions = useSelector(getWriteTabsPermissions);
  const isExportLoading = useSelector(getExportLoading);
  const isImportLoading = useSelector(getImportLoading);
  const importValidationData = useSelector(getImportValidationData);
  const isAnyTabsWriteAllowed = Object.values(writeTabsPermissions ?? {}).some((tab) => tab);
  const [openUpload, setOpenUpload] = useState<boolean>(false);

  const updateUploadVisibility = () => {
    setOpenUpload(!openUpload);
  };

  useEffect(() => {
    if (importValidationData.length) {
      setOpenUpload(false);
    }
  }, [importValidationData]);

  return (
    <>
      <ExportImportButton
        type={'link'}
        icon={<img alt='import' src={'./images/import.svg'} />}
        onClick={updateUploadVisibility}
        loading={isImportLoading}
        disabled={!isAnyTabsWriteAllowed || pathname !== '/offices' || isImportLoading || isExportLoading}
      >
        <ExportImportButtonText>{isImportLoading ? 'Importing' : 'Import'}</ExportImportButtonText>
      </ExportImportButton>

      <UploadModal visible={openUpload} onCancel={() => setOpenUpload(false)} />
      <ValidationModal visible={!!importValidationData.length} data={importValidationData} />
    </>
  );
}

export default Import;
