export const FETCH_LOCATIONS_COORDINATES = `FETCH_LOCATIONS_COORDINATES`;
export const CONFIGURE_COLOR_MAP = `CONFIGURE_COLOR_MAP`;
export const FETCH_LOCATIONS_COORDINATES_SUCCESS = `FETCH_LOCATIONS_COORDINATES_SUCCESS`;
export const FETCH_LOCATIONS_COORDINATES_FAILED = `FETCH_LOCATIONS_COORDINATES_FAILED`;


export const fetchLocationsCoordinates = (payload: any, colorMap: any) => ({
  type: FETCH_LOCATIONS_COORDINATES,
  payload,
  params: {colorMap}
});

export const fetchLocationsCoordinatesSuccess = (payload: any, search: any) => ({
  type: FETCH_LOCATIONS_COORDINATES_SUCCESS,
  payload,
  params: {search}
});

export const fetchLocationsCoordinatesFailed = (payload: any) => ({
  type: FETCH_LOCATIONS_COORDINATES_FAILED,
  payload,
});
