import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContacts,
  getDistricts,
  getRegions,
  getSearchRegionId,
  getSelectedLocations,
} from '../../offices/offices-selectors';
import {
  fetchContacts,
  fetchDistricts,
  fetchRegions,
  regionDistrictBulkUpdate,
  regionDistrictBulkUpdateStaff,
} from '../../offices/offices-actions';
import { getIsWriteAllAllowed } from '../../../store/user/user-reducer';
import { formItemMap } from '@med-fe/ui';
import { AutoComplete, Button, Form } from 'antd';
import { isNil, omit } from 'lodash';
import moment from 'moment';
import { ManagersEnum } from '../../collapsible-panel/managers.enum';
import { PsPartnersEnum } from '../../collapsible-panel/ps-partners.enum';
import { HrPartnersEnum } from '../../collapsible-panel/hr-partners.enum';
import { PHONE_VALIDATION_RULE } from '@med-fe/util';

interface RegionDistrictBulkUpdate {
  districtId?: number;
  locationIds?: number[];
  regionId?: number;
  job?: 'PM' | 'RM' | 'CFM' | 'PSA' | 'HRBP' | 'RCFD' | 'CFD';
  contact?: ContactDTO;
}

interface ContactDTO {
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
  startDate?: any;
}

function AreaRegionDistrictBulkEdit({ modalData, isAllSelected, tab }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [regionId, setRegionId]: any = useState(null);
  const [districtId, setDistrictId]: any = useState(null);
  const isEditAllowed = useSelector(getIsWriteAllAllowed);
  const selectedLocations = useSelector(getSelectedLocations);

  const regions = useSelector(getRegions);
  const districts = useSelector(getDistricts);
  const searchRegionId = useSelector(getSearchRegionId);
  const jobPositions = ['DM', 'RM', 'CFM', 'PSA', 'HRBP', 'RCFD', 'CFD'].map((id) => ({
    id,
    name: { ...ManagersEnum, ...PsPartnersEnum, ...HrPartnersEnum }?.[id],
  }));
  const [isLocationRequired, setIsLocationRequired] = useState(false);
  const [jobPositionId, setJobPositionId] = useState<any>(null);
  const contacts = useSelector(getContacts);
  const [contact, setContact]: any = useState(null);

  useEffect(() => {
    if (!isNil(regionId) || !isNil(districtId)) {
      setIsLocationRequired(true);
    }
  }, [regionId, districtId]);

  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        ...contact,
        name: contact.value,
        ...(!!contact.startDate && { startDate: moment(contact.startDate) }),
      });
    }
    // eslint-disable-next-line
  }, [contact]);

  const locationAndManagerConfig: any = () => ({
    regionId: {
      label: 'Region',
      type: 'select',
      placeholder: 'Select a Region',
      rules: [{ required: isLocationRequired, message: 'Please select Region' }],
    },
    districtId: {
      label: 'District',
      type: 'select',
      placeholder: 'Select a District',
      rules: [{ required: isLocationRequired, message: 'Please select District' }],
    },
    jobPosition: {
      label: 'Job Position',
      type: 'select',
      placeholder: 'Select a Job Position',
      rules: [{ required: !isNil(jobPositionId), message: 'Please select District' }],
    },
    ...(!isNil(jobPositionId) && {
      name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Search by Name/Surname',
        rules: [{ required: true, message: 'Please fill Name' }],
      },
      phone: {
        label: 'Phone',
        type: 'phone-field',
        rules: [PHONE_VALIDATION_RULE],
      },
      email: {
        label: 'Email',
        type: 'text',
        rules: [],
      },
      startDate: {
        label: 'Start Date',
        type: 'date',
        rules: [],
      },
    }),
  });

  const marketingConfig: any = () => ({
    branding: {
      label: 'Branding',
      type: 'text',
      placeholder: 'Enter branding',
    },
  });

  const onSubmit = (formData: any) => {
    const officesPayload: RegionDistrictBulkUpdate = {
      ...omit(formData, 'jobPosition'),
      locationIds: selectedLocations,
      job: jobPositionId,
      contact: {
        id: contact?.id,
        ...formData,
        ...(!!formData.startDate && { startDate: formData.startDate.format('YYYY-MM-DD') }),
      },
    };
    dispatch(regionDistrictBulkUpdate({ payload: officesPayload, isAllSelected }));
  };

  const onRegionSelect = (value) => {
    setRegionId(value);
    form.setFieldsValue({ districtId: null });
  };

  const onDistrictSelect = (value) => {
    setDistrictId(value);
  };

  const onJobPositionSelect = (value) => {
    setJobPositionId(value);
    const payload = {
      locationIds: selectedLocations,
      job: value,
    };
    dispatch(regionDistrictBulkUpdateStaff({ payload, isAllSelected }));
  };

  const config = tab === 'locations' ? locationAndManagerConfig() : tab === 'marketing' ? marketingConfig() : [];

  useEffect(() => {
    dispatch(fetchRegions());

    return () => {
      dispatch(fetchDistricts(searchRegionId));
      form.resetFields();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (regionId) {
      dispatch(fetchDistricts(regionId));
    }
    // eslint-disable-next-line
  }, [regionId]);

  return (
    <Form
      form={form}
      className='region-district-bulk-update-form'
      layout='vertical'
      onValuesChange={() => setIsDisabled(false)}
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
        modalData.onClose();
      }}
    >
      {Object.entries(config).map(([key, value]: [string, any]) => {
        const data = () => {
          switch (key) {
            case 'regionId':
              return regions;
            case 'districtId':
              return districts;
            case 'jobPosition':
              return jobPositions;
          }
        };
        const formItemParams = {
          ...value,
          data: data(),
          ...(key === 'regionId' && { onSelect: onRegionSelect }),
          ...(key === 'districtId' && { onSelect: onDistrictSelect }),
          ...(key === 'jobPosition' && { onSelect: onJobPositionSelect }),
        };
        return (
          <Form.Item
            key={key}
            label={value.label}
            name={key}
            rules={value.rules}
            validateTrigger={['onBlur', 'onSubmit']}
          >
            {key === 'name' ? (
              <AutoComplete
                allowClear
                options={contacts.map(({ name, id, ...info }) => ({ ...info, key: id, id, value: name }))}
                placeholder={value?.placeholder}
                onChange={(value) => {
                  if (!value) {
                    form.setFieldsValue({ name: null, phone: null, email: null, startDate: null, id: null });
                    setContact(null);
                    dispatch(fetchContacts({ name: '' }));
                  } else {
                    dispatch(fetchContacts({ name: value }));
                  }
                }}
                onSelect={(value, option) => setContact(option)}
              />
            ) : (
              formItemMap(formItemParams, !isEditAllowed || value.disabled)
            )}
          </Form.Item>
        );
      })}

      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AreaRegionDistrictBulkEdit;
