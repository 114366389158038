import { Configuration, PopupRequest, PublicClientApplication } from '@azure/msal-browser';
import { reactApp } from '@med-fe/util';

// Config object to be passed to Msal on creation

export const msalConfig: Configuration = {
  auth: {
    clientId: reactApp().clientId,
    authority: `https://login.microsoftonline.com/${reactApp().tenantId}`,
    redirectUri: reactApp().redirectUri ? reactApp().redirectUri : reactApp().apiUrl,
    postLogoutRedirectUri: '/',
  },
};

const href = window.location.href;

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [href.includes('mod.myeyedr.com') || href.includes('pace.myeyedr.com')
        ? `openid api://b785e81b-4788-48dc-881c-c5feda775be1/.default`
        : `openid api://6c9fa750-e9c6-4311-9255-58b46446ff51/.default`]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const msalInstance = new PublicClientApplication(msalConfig);
