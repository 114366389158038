import { Button, Dropdown, Menu, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportFile } from '../header-actions';
import { useLocation } from 'react-router-dom';
import styles from '../../navbar/navbarMenu.module.scss';
import { getExportLoading, getImportLoading } from '../header-reducer';
import { getReadTabsPermissions, getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { ExportImportButton, ExportImportButtonText } from '@med-fe/ui';

function Export() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isLoading = useSelector(getExportLoading);
  const isImportLoading = useSelector(getImportLoading);
  const isReadTabsAllowed = useSelector(getReadTabsPermissions);
  const isWriteTabsAllowed = useSelector(getWriteTabsPermissions);
  const isNoTabsReadAllowed = Object.values(isReadTabsAllowed ?? {}).every((tab) => !tab);
  const isNoTabsWriteAllowed = Object.values(isWriteTabsAllowed ?? {}).every((tab) => !tab);
  const isDisabled = isLoading || isImportLoading || (isNoTabsReadAllowed && isNoTabsWriteAllowed);
  const [current, setCurrent] = useState('0');

  const handleItem = (type) => {
    if (!isLoading) {
      const path = pathname.includes('offices') ? 'locations' : pathname.includes('doctors') ? 'employees' : 'audit';
      let payload: any = { path, type };

      if (type.includes('template')) {
        payload = { ...payload, isTemplate: true };
      }

      dispatch(exportFile(payload));
    }
  };

  const menu = (
    <Menu
      onClick={(e) => {
        setCurrent(e.key);
      }}
      selectedKeys={[current]}
      className={styles['menu']}
    >
      <Menu.Item className={styles['menu-item']} key='csv' onClick={(e) => handleItem('csv')}>
        <Typography className={styles['link']}>CSV</Typography>
      </Menu.Item>
      <Menu.Item key='xlsx' className={styles['menu-item']} onClick={(e) => handleItem('xlsx')}>
        <Typography className={styles['link']}>Excel</Typography>
      </Menu.Item>
      {pathname.includes('offices') && (
        <Menu.Item key='template' className={styles['menu-item']} onClick={(e) => handleItem('xlsx-template')}>
          <Typography className={styles['link']}>Template</Typography>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={isLoading ? <div /> : menu} disabled={isDisabled}>
      <ExportImportButton type={'link'} loading={isLoading} icon={<img alt='export' src={'./images/export.svg'} />}>
        <ExportImportButtonText>{isLoading ? 'Exporting' : 'Export'}</ExportImportButtonText>
      </ExportImportButton>
    </Dropdown>
  );
}

export default Export;
