import {
  FETCH_LOCATIONS_COORDINATES,
  FETCH_LOCATIONS_COORDINATES_FAILED,
  FETCH_LOCATIONS_COORDINATES_SUCCESS,
} from './map-actions';
import { CoordinatesDto } from '../../models/address-dto';
import { get } from 'lodash';

export const LOCATIONS_COORDINATES_STATE_KEY = 'LOCATIONS_COORDINATES_REDUCER';

export const getLocationsCoordinates = (state: any) => state[LOCATIONS_COORDINATES_STATE_KEY].coordinates;
export const getColorLevel = (state: any) => state[LOCATIONS_COORDINATES_STATE_KEY].colorLevel;
export const getColorMap = (state: any) => state[LOCATIONS_COORDINATES_STATE_KEY].colorMap;

export interface ICoordinatesState {
  loading: boolean;
  coordinates: CoordinatesDto[];
  error: any;
  colorLevel: string;
  colorMap: any;
}

const defaultState: ICoordinatesState = {
  loading: false,
  coordinates: [],
  error: null,
  colorLevel: 'districts',
  colorMap: {},
};

const defineColorLevel = (search) => {
  if (search.regionIds.length || search.districtIds.length) {
    return 'districts';
  } else {
    return 'regions';
  }
};

var tempColorMap = {};

export const coordinatesReducer = (state = defaultState, { type, payload, params }: any) => {
  switch (type) {
    case FETCH_LOCATIONS_COORDINATES: {
      tempColorMap = params.colorMap;
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_LOCATIONS_COORDINATES_SUCCESS:
      return {
        ...state,
        loading: false,
        coordinates: get(payload, 'contents', []),
        colorLevel: defineColorLevel(params.search),
        colorMap: tempColorMap,
      };
    case FETCH_LOCATIONS_COORDINATES_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
