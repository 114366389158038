import { call, put } from 'redux-saga/effects';
import { api } from '@med-fe/util';
import { showErrorNotification } from '@med-fe/util';
import { fetchLocationsCoordinatesFailed, fetchLocationsCoordinatesSuccess } from './map-actions';

export function* fetchLocationsCoordinatesStartWorker({ payload }: any): Generator {
  try {
    const url = '/locations/map';
    const maxSize = 100000; // according to BE implementation
    const data: any = yield call(api.post, url, { ...payload, page: 0, size: maxSize });
    yield put(fetchLocationsCoordinatesSuccess(data, payload));
  } catch (e) {
    yield put(fetchLocationsCoordinatesFailed(e));
    showErrorNotification(null, e);
  }
}
