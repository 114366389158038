import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Logo = styled.img`
  width: ${({ width }) => `${width}px` ?? '100%'};
  height: ${({ height }) => `${height}px` ?? '100%'};
  opacity: ${({ opacity }) => opacity ?? 1};
`;

export const StyledText = styled(Text)`
  white-space: pre;
  text-align: center;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 240px;
  height: 320px;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 32px;
`;
