export const configureColorMap = (search, regions, districts) => {
  var colorsMap = new Map(null);
  if (!search.regionIds.length && !search.districtIds.length) {
    regions.map((element) => {
      colorsMap.set(element.id, Colors[1]);
    });
  } else if (search.regionIds.length || search.districtIds.length) {
    districts.map((element) => {
      colorsMap.set(element.id, Colors[districts.map((item) => item.id).indexOf(element.id)]);
    });
  }
  return colorsMap;
};

export const Colors = [
  '#c39bd3',
  '#0057b7',
  '#ffd700',
  '#109bdc',
  '#008000',
  '#FFA500',
  '#b33636',
  '#bfeaff',
  '#a781e4',
  '#beff00',
  '#ff6b19',
  '#80d6ff',
  '#b0bbff',
  '#81cce4',
  '#48c9b0',
  '#17a589',
  '#a3e4d7',
  '#85c1e9',
  '#3498db',
  '#2874a6',
  '#17a589',
  '#99BBE2',
  '#2874a6',
  '#2ecc71',
  '#16a085',
  '#2980b9',
  '#9b59b6',
  '#ad8c5c',
  '#fec3ff',
  '#fff472',
  '#cb4335',
  '#fec3ff',
  '#b33636',
  '#ffa6a6',
  '#ff1c1c',
  '#e99fa3',
  '#cf9fe9',
  '#916fa3',
  '#ffa500',
  '#f8c7fc',
  '#ffb900',
  '#ed9582',
  '#f783db',
  '#b7ce21',
  '#e1f783',
  '#ab5cad',
  '#fff1f1',
  '#ff4d4d',
  '#fee1ff',
  '#796a40',
  '#e6ca80',
  '#ab9352',
  '#a55e5e',
  '#e6c280',
  '#ffe3fe',
  '#ffe4e3',
];
