import React, {useEffect, useState} from 'react';
import BaseGrid from '../grid/BaseGrid';
import {fetchAudit, setSearchAudit} from './audit-actions';
import {useDispatch, useSelector} from 'react-redux';
import {getAuditTotal, getIsLoadingAudit, getSearchAudit} from './audit-reducer';
import SearchAudit from '../header/search/SearchAudit';

function Audit() {
  const dispatch = useDispatch();
  const search = useSelector(getSearchAudit);
  const total = useSelector(getAuditTotal);
  const loading = useSelector(getIsLoadingAudit);

  useEffect(() => {
    dispatch(fetchAudit(search));
  }, [search]);

  const onPageChange = (searchData) => {
    dispatch(setSearchAudit(searchData))
  }

  return (
    <>
      <SearchAudit />
      <BaseGrid id={'audit'} total={total} search={search} setSearch={onPageChange} loading={loading} />
    </>
  )
}

export default Audit;
