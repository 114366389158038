import React from 'react';
import { Button, Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cleanImportLayout, updateValidatedImport } from '../header-actions';
import { getImportLoading } from '../header-reducer';
import { OfficeTabs } from './OfficeTabs';

const { Text } = Typography;

export const ValidationModal = ({ visible, data }) => {
  const dispatch = useDispatch();
  const isImportLoading = useSelector(getImportLoading);
  const invalidOffices = data.filter((item) => item.hasError).map((item) => item.locationNumber);

  return (
    <StyledValidationModal
      visible={visible}
      onCancel={() => dispatch(cleanImportLayout())}
      footer={
        <>
          <Button type={'ghost'} onClick={() => dispatch(cleanImportLayout())}>
            Cancel
          </Button>
          <Button
            type={'primary'}
            loading={isImportLoading}
            disabled={invalidOffices.length}
            onClick={() => dispatch(updateValidatedImport())}
          >
            Submit
          </Button>
        </>
      }
    >
      <StyledTitle>
        <Text strong>You are about to update {data.length} offices</Text>
      </StyledTitle>
      <OfficeTabs tabs={data} invalidOffices={invalidOffices} />
    </StyledValidationModal>
  );
};

const StyledValidationModal = styled(Modal)`
  .ant-modal-content {
    width: initial;
    max-width: 800px;
  }
`;

const StyledTitle = styled.div`
  margin: 15px 0 25px;
  width: 100%;
  text-align: center;
`;
