export const FETCH_OFFICES_STATISTICS = 'FETCH_OFFICES_STATISTICS';
export const FETCH_OFFICES_STATISTICS_SUCCESS = 'FETCH_OFFICES_STATISTICS_SUCCESS';
export const FETCH_OFFICES_STATISTICS_FAILED = 'FETCH_OFFICES_STATISTICS_FAILED';

export const FETCH_DOCTORS_STATISTICS = 'FETCH_DOCTORS_STATISTICS';
export const FETCH_DOCTORS_STATISTICS_SUCCESS = 'FETCH_DOCTORS_STATISTICS_SUCCESS';
export const FETCH_DOCTORS_STATISTICS_FAILED = 'FETCH_DOCTORS_STATISTICS_FAILED';

export const FETCH_ACTIVITY_STATISTICS = 'FETCH_ACTIVITY_STATISTICS';
export const FETCH_ACTIVITY_STATISTICS_SUCCESS = 'FETCH_ACTIVITY_STATISTICS_SUCCESS';
export const FETCH_ACTIVITY_STATISTICS_FAILED = 'FETCH_ACTIVITY_STATISTICS_FAILED';

// OFFICES
export const fetchOfficesStatistics = (payload) => ({
  type: FETCH_OFFICES_STATISTICS,
  payload,
});

export const fetchOfficesStatisticsSuccess = (payload) => ({
  type: FETCH_OFFICES_STATISTICS_SUCCESS,
  payload,
});

export const fetchOfficesStatisticsFailed = () => ({
  type: FETCH_OFFICES_STATISTICS_FAILED,
});

// DOCTORS
export const fetchDoctorsStatistics = (payload) => ({
  type: FETCH_DOCTORS_STATISTICS,
  payload,
});

export const fetchDoctorsStatisticsSuccess = (payload) => ({
  type: FETCH_DOCTORS_STATISTICS_SUCCESS,
  payload,
});

export const fetchDoctorsStatisticsFailed = () => ({
  type: FETCH_DOCTORS_STATISTICS_FAILED,
});

// ACTIVITY
export const fetchActivityStatistics = (payload) => ({
  type: FETCH_ACTIVITY_STATISTICS,
  payload,
});

export const fetchActivityStatisticsSuccess = (payload) => ({
  type: FETCH_ACTIVITY_STATISTICS_SUCCESS,
  payload,
});

export const fetchActivityStatisticsFailed = () => ({
  type: FETCH_ACTIVITY_STATISTICS_FAILED,
});
