import React, { ComponentClass, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { GM_API_KEY } from '@med-fe/util';

import { CoordinatesDto } from '../../models/address-dto';
import { getColorLevel, getColorMap, getLocationsCoordinates } from './map-reducer';
import { fetchLocationsCoordinates } from './map-actions';
import { getSearch } from '../offices/offices-selectors';
import { configureColorMap } from './map-marker-theme';
import { getDistricts, getRegions } from '../offices/offices-selectors';

export const InteractiveMap = () => {
  const dispatch = useDispatch();
  const data = useSelector(getLocationsCoordinates);
  const colorLevel = useSelector(getColorLevel);
  const colorMap = useSelector(getColorMap);
  const search = useSelector(getSearch);
  const [selectedLoc, setSelectedLoc] = useState<CoordinatesDto | any>(null);
  const defaultZoom = 5;

  const regions = useSelector(getRegions);
  const districts = useSelector(getDistricts);

  const [center, setCenter] = useState({
    lat: 40.287961380758,
    lng: -98.9276356655445,
  });

  useEffect(() => {
    setSelectedLoc(null);
  }, [data]);

  useEffect(() => {
    dispatch(fetchLocationsCoordinates(search, configureColorMap(search, regions, districts)));
  }, []);

  const containerStyle = {
    width: '100%',
    height: '500px',
  };

  const defineMarkerColor = (loc: CoordinatesDto, colorLevel: string) => {
    if (colorLevel === 'regions') {
      return loc.region ? colorMap.get(loc.region.id) : 'white';
    }
    if (colorLevel === 'districts') {
      return loc.district ? colorMap.get(loc.district.id) : 'white';
    }
  };

  return (
    <LoadScript googleMapsApiKey={GM_API_KEY}>
      <GoogleMap zoom={defaultZoom} center={center} mapContainerStyle={containerStyle}>
        {' '}
        <>
          {!!data.length &&
            data.map((loc: CoordinatesDto) => (
              <Marker
                key={loc.id}
                position={{
                  lat: +get(loc, 'address.lat'),
                  lng: +get(loc, 'address.lng'),
                }}
                icon={{
                  fillColor: defineMarkerColor(loc, colorLevel),
                  fillOpacity: 1,
                  strokeColor: '#000000',
                  strokeWeight: 1,
                  scale: 1,
                  path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                }}
                onClick={() => {
                  setSelectedLoc(loc);
                }}
              >
                {loc.address && loc.address.lat && selectedLoc && selectedLoc.id === loc.id && (
                  <InfoWindow
                    onCloseClick={() => {
                      setSelectedLoc(null);
                    }}
                  >
                    <div>
                      <h3>{selectedLoc.locationNumber}</h3>
                      <h4>{selectedLoc.locationName}</h4>
                      <p>{`${selectedLoc.address.city}, ${selectedLoc.address.street}`}</p>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}{' '}
        </>
      </GoogleMap>
    </LoadScript>
  );
};
