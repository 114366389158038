import {
  CLEAN_IMPORT_LAYOUT,
  EXPORT_FILE,
  EXPORT_FILE_SUCCESS,
  IMPORT_FILE,
  IMPORT_FILE_FAILED,
  IMPORT_FILE_SUCCESS,
  UPDATE_VALIDATED_IMPORT,
  UPDATE_VALIDATED_IMPORT_FAILED,
  UPDATE_VALIDATED_IMPORT_SUCCESS,
} from './header-actions';

export const HEADER_EXPORT_STATE_KEY = 'HEADER_EXPORT_REDUCER';

export const getExportLoading = (state: any) => state[HEADER_EXPORT_STATE_KEY].exportLoading;
export const getImportLoading = (state: any) => state[HEADER_EXPORT_STATE_KEY].importLoading;
export const getImportValidationData = (state: any) => state[HEADER_EXPORT_STATE_KEY].importValidationData;

export interface HeaderState {
  exportLoading: boolean;
  importLoading: boolean;
  importFailed: boolean;
  importValidationData: any[];
}

const defaultState: HeaderState = {
  exportLoading: false,
  importLoading: false,
  importFailed: false,
  importValidationData: [],
};

export const headerReducer = (state = defaultState, { type, payload }: any) => {
  switch (type) {
    case EXPORT_FILE: {
      return {
        ...state,
        exportLoading: true,
      };
    }
    case IMPORT_FILE: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case EXPORT_FILE_SUCCESS: {
      return {
        ...state,
        exportLoading: false,
      };
    }
    case IMPORT_FILE_SUCCESS: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: payload,
      };
    }
    case IMPORT_FILE_FAILED: {
      return {
        ...state,
        importLoading: false,
        importFailed: true,
      };
    }
    case UPDATE_VALIDATED_IMPORT: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case UPDATE_VALIDATED_IMPORT_SUCCESS:
    case UPDATE_VALIDATED_IMPORT_FAILED: {
      return {
        ...state,
        importLoading: false,
      };
    }
    case CLEAN_IMPORT_LAYOUT: {
      return {
        ...state,
        ...defaultState,
      };
    }
    default:
      return state;
  }
};
