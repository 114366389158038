import { PHONE_VALIDATION_RULE } from '@med-fe/util';

export const proServiceConfig = {
  odOnCall: {
    label: 'OD on-call',
    type: 'text',
  },
  onCallPhone: {
    label: 'on-call phone number',
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
  },
  locationEquipments: {
    label: 'Specialty Equipment',
    isVisible: true,
    type: 'select',
    mode: 'multiple',
    allowClear: true,
  },
  preceptorLocation: {
    label: 'Preceptor Location',
    type: 'checkbox',
  },
  dryEyeProgram: {
    label: 'Dry Eye Program',
    type: 'checkbox',
  },
};
