export const internetDetailsConfig: any = () => ({
  mspAccountNumber: {
    label: 'MSP Account Number',
    type: 'text',
  },
  vendor: {
    label: 'Vendor',
    type: 'text',
  },
  tier: {
    label: 'Tier',
    type: 'text',
  },
  networkStandardized: {
    label: 'Network Standardized',
    type: 'checkbox',
  },
});
