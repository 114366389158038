export const it_services_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'MSP Account Number',
    dataIndex: 'mspAccountNumber',
    key: 'mspAccountNumber',
    width: 130,
  },
  {
    title: 'Network Standardized',
    dataIndex: 'networkStandardized',
    key: 'networkStandardized',
    width: 130,
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    key: 'vendor',
    width: 100,
  },
  {
    title: 'Tier',
    dataIndex: 'tier',
    key: 'tier',
    width: 60,
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
    width: 80,
  },
  {
    title: 'Speed',
    dataIndex: 'speed',
    key: 'speed',
    width: 70,
  },
  {
    title: 'Circuit Type',
    dataIndex: 'circuitType',
    key: 'circuitType',
    width: 100,
  },
  {
    title: 'MSP Status',
    dataIndex: 'mspStatus',
    key: 'mspStatus',
    width: 100,
  },
  {
    title: 'Completion Date',
    dataIndex: 'completionDate',
    key: 'completionDate',
    width: 120,
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: 120,
  },
  {
    title: 'Provider Secondary',
    dataIndex: 'providerSecondary',
    key: 'providerSecondary',
    width: 120,
  },
  {
    title: 'Speed Secondary',
    dataIndex: 'speedSecondary',
    key: 'speedSecondary',
    width: 120,
  },
  {
    title: 'Type Secondary',
    dataIndex: 'typeSecondary',
    key: 'typeSecondary',
    width: 120,
  },
  {
    title: 'Router Model',
    dataIndex: 'routerModel',
    key: 'routerModel',
    width: 120,
  },
  {
    title: 'Router Model Count',
    dataIndex: 'routerModelCount',
    key: 'routerModelCount',
    width: 150,
},
  {
    title: 'Switches',
    dataIndex: 'switches',
    key: 'switches',
    width: 120,
  },
  {
    title: 'WAPs',
    dataIndex: 'waps',
    key: 'waps',
    width: 120,
  },
  {
    title: 'VVX Phones',
    dataIndex: 'vvxPhones',
    key: 'vvxPhones',
    width: 120,
  },
  {
    title: 'Cordless Phones',
    dataIndex: 'cordlessPhones',
    key: 'cordlessPhones',
    width: 120,
  },
  {
    title: 'LAN Subnet',
    dataIndex: 'lanSubnet',
    key: 'lanSubnet',
    width: 120,
  },
  {
    title: 'Gateway DHCP',
    dataIndex: 'gatewayDHCP',
    key: 'gatewayDHCP',
    width: 120,
  },
  {
    title: 'DHCP Range',
    dataIndex: 'range',
    key: 'range',
    width: 120,
  },
  {
    title: 'Static IP',
    dataIndex: 'staticIP',
    key: 'staticIP',
    width: 120,
  },
  {
    title: 'Gateway',
    dataIndex: 'gateway',
    key: 'gateway',
    width: 120,
  },
  {
    title: 'DNS Primary',
    dataIndex: 'dnsPrimary',
    key: 'dnsPrimary',
    width: 120,
  },
  {
    title: 'DNS Secondary',
    dataIndex: 'dnsSecondary',
    key: 'dnsSecondary',
    width: 120,
  },
  {
    title: 'Subnet Mask',
    dataIndex: 'subnetMask',
    key: 'subnetMask',
    width: 120,
  },
  { }, // empty column, workaround to have column width fixed
];
