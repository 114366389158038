import { AuditAreaCell, AuditTypeCell, AuditValueCell } from '@med-fe/ui';

export const audit_columns = [
  {
    title: 'action type',
    dataIndex: 'action',
    key: 'actionType',
    width: 94,
    ellipsis: true,
    render: (value: string) => <AuditTypeCell value={value} />,
  },
  {
    title: 'user',
    dataIndex: 'user',
    key: 'user',
    width: 124,
    ellipsis: true,
  },
  {
    title: 'date and time',
    dataIndex: 'date',
    key: 'date',
    width: 140,
    ellipsis: true,
  },
  {
    title: 'area',
    dataIndex: 'auditArea',
    key: 'auditArea',
    width: 92,
    ellipsis: true,
    render: (value) => <AuditAreaCell value={value} />,
  },
  {
    title: 'from',
    dataIndex: 'from',
    key: 'from',
    ellipsis: true,
    render: (value: string) => <AuditValueCell value={value} />,
  },
  {
    title: 'to',
    dataIndex: 'to',
    key: 'to',
    ellipsis: true,
    render: (value: string) => <AuditValueCell value={value} />,
  },
  {
    title: 'locations',
    dataIndex: 'locations',
    key: 'locations',
    ellipsis: true,
  },
  {}, // empty column, workaround to have column width fixed
];
