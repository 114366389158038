import React from 'react';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';


const MultivalueSearchButton = ({ isActive, onClick }) => {
    const color = isActive ? '#003366' : '#d7d7d7';
    return (
        <Tooltip
            placement='bottom'
            color={'white'}
            overlayInnerStyle={{ color: '#003366' }}
            overlayStyle={{ fontSize: '10px' }}
            title={!isActive ? 'Search by multiple fields' : 'Multiple search by office name and number'}>
            <IconButton type={"link"} style={{ display: "flex", backgroundColor: "#FFFFFF", borderColor: color, height: "24px", alignItems: "center" }}
                onClick={onClick}>
                <Svg >
                    <g>
                        <path color={color} d="M678.854,590.465c-12.177,16.881-25.729,32.833-40.643,47.746c-14.912,14.912-30.865,28.465-47.746,40.643l208.663,208.663   c9.763,9.764,25.592,9.764,35.355,0l53.032-53.032c9.763-9.764,9.763-25.593,0-35.356L678.854,590.465z" />
                        <path color={color} d="M363.501,727c77.808,0,149.902-24.452,209.028-66.083c34.303-24.152,64.236-54.087,88.389-88.388   c41.631-59.126,66.083-131.222,66.083-209.029c0-200.756-162.745-363.5-363.5-363.5C162.746,0,0.001,162.744,0.001,363.5   C0,564.255,162.745,727,363.501,727z M183.25,333.25c0-11.046,8.954-20,20-20h91.5h19v-19v-91.5c0-11.046,8.954-20,20-20h60   c11.046,0,20,8.954,20,20v110.5h110.5c11.046,0,20,8.954,20,20v60c0,11.046-8.954,20-20,20h-22.612h-87.888v88.888v21.612   c0,11.046-8.954,20-20,20h-60c-11.046,0-20-8.954-20-20v-110.5h-110.5c-11.046,0-20-8.954-20-20L183.25,333.25L183.25,333.25z" />
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                </Svg>
            </IconButton>
        </Tooltip >
    )
}

const IconButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    fill: none;
    margin: 0;
    padding: 2px 2px 0 2px;
    background-color: #FFFFFF;
    height: min-content;
`;

const Svg = styled.svg.attrs({
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
    id: 'Capa_1',
    x: '0px',
    y: '0px',
    width: '15px',
    height: '15px',
    viewBox: '0 0 894.839 894.839',
    xmlSpace: 'preserve',
    fill: 'currentColor'
})``

export default MultivalueSearchButton;