import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { importFile } from '../header-actions';
import { useDispatch, useSelector } from 'react-redux';
import { getExportLoading, getImportLoading } from '../header-reducer';

const { Dragger } = Upload;

export const UploadModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const isExportLoading = useSelector(getExportLoading);
  const isImportLoading = useSelector(getImportLoading);

  const handleChanges = (options) => {
    if (options?.file?.status !== 'removed') {
      dispatch(importFile({ file: options.file }));
    }
  };

  return (
    <StyledImportModal title='Upload Bulk Update' visible={visible} footer={null} onCancel={onCancel}>
      <Dragger
        disabled={isImportLoading || isExportLoading}
        accept={'.xlsx'}
        maxCount={1}
        beforeUpload={() => false}
        fileList={[]}
        onChange={handleChanges}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        {isImportLoading ? (
          <p className='ant-upload-hint'>Processing...</p>
        ) : (
          <>
            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            <p className='ant-upload-hint'>Support for a single file only</p>
          </>
        )}
      </Dragger>
    </StyledImportModal>
  );
};

const StyledImportModal = styled(Modal)`
  .ant-modal-content {
    width: initial;
  }
`;
