import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get, indexOf, isEmpty, isNull, upperCase } from 'lodash';
import { Button, Drawer, Dropdown, Menu, Modal, Pagination, Table, Tooltip } from 'antd';

import { getAuditForGrid } from '../audit/audit-reducer';
import { getIsWriteAllAllowed } from '../../store/user/user-reducer';
import {
  getFinanceForGrid,
  getLocationsForGrid,
  getMarketingForGrid,
  getMvcForGrid,
  getProfileForGrid,
  getScheduleForGrid,
  getProServicesForGrid,
  getItServicesForGrid,
  getSelectedLocations,
  getVehForGrid,
  getTelecomForGrid,
  getSpecialtyForGrid,
} from '../offices/offices-selectors';
import { toggleSelectedLocations } from '../offices/offices-actions';
import { getDoctorsForGrid } from '../doctors/doctors-selectors';

import { EditActionCell } from '@med-fe/ui';
import CollapsiblePanel from '../collapsible-panel/CollapsiblePanel';
import DoctorPanel from '../../components/doctor-panel/DoctorPanel';
import LocationDetails from '../details/locations/LocationDetails';
import AreaRegionDistrictDetails from '../details/locations/AreaRegionDistrictDetails';
import LinkCell from './cell-components/LinkCell';
import AreaRegionDistrictBulkEdit from '../details/locations/AreaRegionDistrictBulkEdit';

import { grid_columns_map } from './grid-configs';

import menuStyles from '../navbar/navbarMenu.module.scss';
import styles from './BaseGrid.module.scss';
import { TextCell, SelectAllButton } from '@med-fe/ui';

function BaseGrid({ id, activeTab, total, search, setSearch, loading, isAllSelected, toggleIsAllSelected }: any) {
  const selectorMap: any = {
    locations: getLocationsForGrid,
    profile: getProfileForGrid,
    finance: getFinanceForGrid,
    marketing: getMarketingForGrid,
    schedule: getScheduleForGrid,
    mvc: getMvcForGrid,
    audit: getAuditForGrid,
    pro: getProServicesForGrid,
    it: getItServicesForGrid,
    veh: getVehForGrid,
    telecom: getTelecomForGrid,
    doctors: getDoctorsForGrid,
    specialty: getSpecialtyForGrid,
  };
  const data = useSelector((state) => selectorMap[id](state));
  const dispatch = useDispatch();
  const isWriteAllAllowed = useSelector(getIsWriteAllAllowed);
  const isBulkEditAllowed = isWriteAllAllowed && ['locations', 'marketing'].some((tab) => tab === activeTab);
  const locations = useSelector(getLocationsForGrid);
  const selectedLocationIDs = useSelector(getSelectedLocations);

  const { page, size } = search;
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [startSelection, setStartSelection] = useState(null);
  const [endSelection, setEndSelection] = useState(null);
  const tabsWithBulk = ['locations', 'marketing'];

  const columns = useMemo(
    () =>
      grid_columns_map[id].map((col: any) => {
        const columnConfig = {
          ...col,
          title: () => <TextCell value={upperCase(col?.title)} />,
        };

        if (col.key === 'action') {
          columnConfig['render'] = (text: string, record: any, rowIndex: any) => {
            const onClickHandler = (e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedRow(rowIndex);
              setSelectedLocation(record.key);
            };

            return <EditActionCell text={text} status={record.status} onClickHandler={onClickHandler} />;
          };
        } else if ((col.key === 'region' || col.key === 'district') && id !== 'doctors' && isWriteAllAllowed) {
          columnConfig['ellipsis'] = false;
          columnConfig['render'] = (text: string, record: any) => {
            if (!text) {
              return null;
            }

            const onClickHandler = (e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsModalVisible(true);
              let id;

              if (col.key === 'region') {
                id = record.regionId;
              } else {
                id = record.districtId;
              }

              setModalData({
                mode: 'edit',
                key: col.key,
                id,
                regionId: col.key === 'district' ? record.regionId : null,
                name: text,
                onClose: () => setIsModalVisible(false),
              });
            };
            return <LinkCell linkText={text} onClickHandler={onClickHandler} />;
          };
        } else if (col.key === 'locationNumber') {
          columnConfig['ellipsis'] = false;
          columnConfig['render'] = (locations: any) => {
            if (!locations.length) {
              return null;
            }

            // TODO: handle location click
            return locations.map((item, index) => (
              <Tooltip
                title={item.locationName}
                key={item.locationNumber}
                color={'white'}
                placement={'topLeft'}
                overlayInnerStyle={{ color: item.primaryLocation ? '#003366' : '#5377b0' }}
                overlayStyle={{ fontSize: '11px' }}
              >
                <span>
                  <LinkCell
                    linkText={item.locationNumber}
                    onClickHandler={() => {}}
                    isPrimaryStyled={item.primaryLocation}
                    style={{ margin: 0, padding: 0 }}
                  />
                  {index !== locations.length - 1 && ', '}
                </span>
              </Tooltip>
            ));
          };
        }
        if (!columnConfig?.render && !isEmpty(col)) {
          columnConfig['render'] = (text) => <TextCell value={text} />;
        }

        return columnConfig;
      }),
    [id]
  );

  const modalTitle = () => {
    const title = `${get(modalData, 'title', '').toUpperCase()}`;

    if (title) {
      return title;
    }

    const mode = `${get(modalData, 'mode', '').toUpperCase()}`;
    const modalName = `${get(modalData, 'key', '').toUpperCase()}`;

    return mode === 'ADD' ? `+ ${mode} NEW ${modalName}` : `${mode} ${modalName}`;
  };

  const modalContent = (key) => {
    switch (key) {
      case 'region':
      case 'district':
        return <AreaRegionDistrictDetails modalData={modalData} />;
      case 'location':
        return <LocationDetails onClose={() => setIsModalVisible(false)} isCreateMode={true} />;
      case 'bulkEditRegionDistrict':
        return <AreaRegionDistrictBulkEdit isAllSelected={isAllSelected} modalData={modalData} tab={activeTab} />;
    }
  };

  const menu = (
    <Menu className={menuStyles['menu']}>
      {['location', 'region', 'district'].map((key) => (
        <Menu.Item className={menuStyles['menu-item']} key={`add-${key}`}>
          <a
            onClick={() => {
              setModalData({ mode: 'add', key, onClose: () => setIsModalVisible(false) });
              setIsModalVisible(true);
            }}
            className={menuStyles['link']}
          >
            {`Add ${capitalize(key)}`}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    return () => {
      dispatch(toggleSelectedLocations([]));
      setStartSelection(null);
      setEndSelection(null);
    };
  }, []);

  useEffect(() => {
    setSelectedRow(null);
    setSelectedLocation(null);
  }, [activeTab, search]);

  useEffect(() => {
    if (tabsWithBulk.includes(activeTab) && !isNull(startSelection) && !isNull(endSelection)) {
      const locList = locations.map((item) => item.key);
      const start = indexOf(locList, startSelection);
      const end = indexOf(locList, endSelection);
      const selectedLocList = start === end ? [] : locList.slice(start, end + 1);

      if (!selectedLocList.length) {
        setStartSelection(null);
        setEndSelection(null);
      }

      dispatch(toggleSelectedLocations(selectedLocList));
    }
  }, [startSelection, endSelection]);

  const onRow = (record) => ({
    onDoubleClick: (event) => {
      if (id === 'audit' || id === 'doctors' || isAllSelected || !tabsWithBulk.includes(activeTab)) return;

      event.preventDefault();
      event.stopPropagation();

      dispatch(toggleSelectedLocations([record.key]));

      if (isBulkEditAllowed) {
        setModalData({
          mode: 'edit',
          key: 'bulkEditRegionDistrict',
          title: 'Update Details',
          onClose: () => setIsModalVisible(false),
        });
        setIsModalVisible(true);
      }

      setSelectedRow(null);
      setSelectedLocation(null);
    },
    onClick: (event) => {
      if (id === 'audit' || isAllSelected || !tabsWithBulk.includes(activeTab)) return;

      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget !== event.target) {
          const { key } = record;
          dispatch(
            toggleSelectedLocations(
              selectedLocationIDs.includes(key)
                ? selectedLocationIDs.filter((item) => item !== key)
                : [...selectedLocationIDs, key]
            )
          );
        }
      }
      if (event.shiftKey) {
        event.stopPropagation();
        // @ts-ignore
        document.getSelection().removeAllRanges();

        if (event.currentTarget !== event.target) {
          const { key } = record;

          if (isNull(startSelection)) {
            setStartSelection(key);
          } else {
            setEndSelection(key);
          }
        }
      }
    },
  });

  return (
    <>
      <div className={styles['table-container']}>
        <div className={styles['table-wrapper']}>
          <Table
            className={styles['table-common']}
            onRow={onRow}
            rowClassName={(record, i) =>
              isAllSelected || selectedLocationIDs.includes(record.key) || i === selectedRow ? 'table-selected-row' : ''
            }
            dataSource={data}
            columns={columns}
            pagination={false}
            loading={loading}
            scroll={{ x: '100%', y: 'calc(100vh - 17.5em)' }}
          />

          {id !== 'audit' &&
            id !== 'doctors' &&
            (isAllSelected || !!selectedLocationIDs.length ? (
              <Button
                disabled={!isBulkEditAllowed || !tabsWithBulk.includes(activeTab)}
                className={`${styles['add-btn']} ${
                  isBulkEditAllowed && tabsWithBulk.includes(activeTab) ? '' : styles['disabled']
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setModalData({
                    mode: 'edit',
                    key: 'bulkEditRegionDistrict',
                    title: 'Update Details',
                    onClose: () => setIsModalVisible(false),
                  });
                  setIsModalVisible(true);
                }}
              >
                <img src={'./icons/action-edit.svg'} alt='edit' />
              </Button>
            ) : (
              <Dropdown overlay={menu} trigger={['click']} placement='topLeft' disabled={!isWriteAllAllowed}>
                <Button
                  className={`${styles['add-btn']} ${isWriteAllAllowed ? '' : styles['disabled']}`}
                  onClick={(e) => e.preventDefault()}
                >
                  <img src={'./icons/action-add.svg'} alt='add' />
                </Button>
              </Dropdown>
            ))}
        </div>

        <div className={styles['pagination-container']}>
          <div className={styles['pagination-actions-wrapper']}>
            <Pagination
              current={page + 1}
              defaultPageSize={size}
              pageSizeOptions={['15', '25', '50', '100']}
              showSizeChanger={true}
              total={total}
              showTotal={(total) =>
                id !== 'audit' && (
                  <div className={styles['total-container']}>
                    <div className={styles['total-title']}>
                      {!!selectedLocationIDs.length || isAllSelected ? (
                        <div className={styles['total-selected-rows']}>
                          <span>SELECTED</span>
                          <span
                            onClick={() => {
                              dispatch(toggleSelectedLocations([]));
                              setStartSelection(null);
                              setEndSelection(null);
                              toggleIsAllSelected(false);
                            }}
                          >
                            <img src={'./icons/action-close.svg'} alt='close' />
                          </span>
                        </div>
                      ) : id === 'doctors' ? (
                        'TOTAL EMPLOYEES'
                      ) : (
                        'TOTAL OFFICES'
                      )}
                    </div>
                    <div className={styles['total-count']}>
                      <span>{(isAllSelected ? total : selectedLocationIDs.length) || total}</span>
                    </div>
                  </div>
                )
              }
              onChange={(pageNumber, pageSize) => {
                setSearch({ size: pageSize, page: size !== pageSize ? 0 : pageNumber - 1 });
              }}
            />
            {id === 'audit' || id === 'doctors' || !tabsWithBulk.includes(activeTab) ? null : (
              <SelectAllButton
                whatSelect='offices'
                isAllSelected={isAllSelected}
                onClick={() => {
                  toggleIsAllSelected(!isAllSelected);
                  dispatch(toggleSelectedLocations([]));
                }}
              />
            )}
          </div>

          <img alt='logo' className={styles['logo']} src={'./images/logo.svg'} />
        </div>
      </div>

      <Drawer
        placement='right'
        visible={!!selectedLocation}
        width={id === 'doctors' ? 478 : 325}
        closable={false}
        className='drawer-panel'
        forceRender={true}
        onClose={() => {
          setSelectedRow(null);
          setSelectedLocation(null);
        }}
      >
        {id === 'doctors' ? (
          <DoctorPanel
            selectedDoctorId={selectedLocation}
            hideDetails={() => {
              setSelectedRow(null);
              setSelectedLocation(null);
            }}
          />
        ) : (
          <CollapsiblePanel
            id={activeTab}
            locId={selectedLocation}
            hideDetails={() => {
              setSelectedRow(null);
              setSelectedLocation(null);
            }}
          />
        )}
      </Drawer>

      <Modal
        title={modalTitle()}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        centered
        footer={null}
      >
        {modalContent(get(modalData, 'key'))}
      </Modal>
    </>
  );
}

export default BaseGrid;
