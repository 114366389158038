import { call, put } from 'redux-saga/effects';
import { api, showSuccessNotification, showErrorNotification } from '@med-fe/util';
import {
  fetchActivityStatisticsFailed,
  fetchActivityStatisticsSuccess,
  fetchDoctorsStatisticsFailed,
  fetchDoctorsStatisticsSuccess,
  fetchOfficesStatisticsFailed,
  fetchOfficesStatisticsSuccess,
} from './statistics-actions';

export function* fetchOfficesStatisticsStartWorker({ payload }): Generator {
  try {
    const url = `/dashboard/${payload.period}/locations`;
    const data: any = yield call(api.get, url);
    yield put(fetchOfficesStatisticsSuccess(data));
  } catch (e) {
    yield put(fetchOfficesStatisticsFailed());
  }
}

export function* fetchDoctorsStatisticsStartWorker({ payload }): Generator {
  try {
    const url = `/dashboard/${payload.period}/doctors`;
    const data: any = yield call(api.get, url);
    yield put(fetchDoctorsStatisticsSuccess(data));
  } catch (e) {
    yield put(fetchDoctorsStatisticsFailed());
  }
}

export function* fetchActivityStatisticsStartWorker({ payload }): Generator {
  try {
    const url = `/dashboard/${payload.period}/users`;
    const data: any = yield call(api.get, url);
    yield put(fetchActivityStatisticsSuccess(data));
  } catch (e) {
    yield put(fetchActivityStatisticsFailed());
  }
}
