import React from 'react';
import { Button, Collapse, Popover } from 'antd';

import ExpandIcon from '../../common/ExpandIcon';

const { Panel } = Collapse;

function DoctorPanelOffices({ locations }) {
  const popoverContent = (loc) => (<>
    <div className='label-text'>Location Name</div>
    <div>{loc.locationName}</div>
    <div className='label-text'>Street 1</div>
    <div>{loc.street}</div>
    {loc.street2 && (
      <>
        <div className='label-text'>Street 2</div>
        <div>{loc.street2}</div>
      </>
    )}
    <div className='label-text'>Location Zip</div>
    <div>{loc.zip}</div>
    <div className='label-text'>Location Phone Number</div>
    <div>{loc.phone}</div>
  </>);

  return (
    <Collapse
      destroyInactivePanel
      bordered={false}
      expandIcon={({ isActive}) => <ExpandIcon isActive={isActive} />}
    >
      <Panel header="OFFICES" key="1" showArrow>
        {locations.map((loc) => (
            <Popover
              content={() => popoverContent(loc)}
              title={loc.locationNumber}
              trigger="hover"
              key={loc.locationNumber}
            >
              <Button
                type={'link'}
                className={'staff-link-text'}
              >
                {loc.locationNumber}
              </Button>
            </Popover>
          )
        )}
      </Panel>
    </Collapse>
  );
}

export default DoctorPanelOffices;
