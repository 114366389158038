import { PHONE_VALIDATION_RULE } from '@med-fe/util';

export const telecomConfig = {
  officeQuickCode: {
    label: 'Office Quick Code',
    type: 'text',
  },
  pagesURL: {
    label: 'Pages URL',
    type: 'text',
  },
  commCenter: {
    label: 'Comm Center',
    type: 'text',
  },
  directDial: {
    label: 'Direct Dial',
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
  },
  helloAgain: {
    label: 'Hello Again',
    type: 'phone-field',
    rules: [PHONE_VALIDATION_RULE],
  },
};
