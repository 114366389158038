import styled from 'styled-components';
import { Button } from 'antd';
import { Profile } from '../profile';

export const ProfileButton = styled(Button)`
  margin: 0 20px;
  min-width: 24px;
  width: 24px;
  height: 24px;
  justify-self: flex-end;
`;
export const ProfileIcon = styled.img`
  width: 12px;
  height: 24px;
  vertical-align: top;
`;

export const StyledProfile = styled(Profile)`
  align-self: flex-end;
`;
