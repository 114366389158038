import React from 'react';
import { Tag } from 'antd';

export const TagRenderer = ({ label, value, closable = true, onClose, valueAsLabel = false, simplified = false }) => {
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const title = simplified ? label.substring(0, value.indexOf(' ')) : valueAsLabel ? value : label;

  return (
    <div className='tag-container'>
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        className={`status-${value.toString().toLowerCase()}`}
      >
        {title}
      </Tag>
    </div>
  );
}
