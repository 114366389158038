import {AddressDto} from '../../models/address-dto';

export const FETCH_ADDRESS = `FETCH_ADDRESS`;
export const FETCH_ADDRESS_SUCCESS = `FETCH_ADDRESS_SUCCESS`;
export const FETCH_ADDRESS_FAILED = `FETCH_ADDRESS_FAILED`;
export const CREATE_ADDRESS = `CREATE_ADDRESS`;
export const CREATE_ADDRESS_SUCCESS = `CREATE_ADDRESS_SUCCESS`;
export const CREATE_ADDRESS_FAILED = `CREATE_ADDRESS_FAILED`;
export const DELETE_ADDRESS = `DELETE_ADDRESS`;
export const DELETE_ADDRESS_SUCCESS = `DELETE_ADDRESS_SUCCESS`;
export const DELETE_ADDRESS_FAILED = `DELETE_ADDRESS_FAILED`;

export const fetchAddress = () => ({
  type: FETCH_ADDRESS,
});

export const fetchAddressSuccess = (payload: any) => ({
  type: FETCH_ADDRESS_SUCCESS,
  payload,
});

export const fetchAddressFailed = (payload: any) => ({
  type: FETCH_ADDRESS_FAILED,
  payload,
});


export const createAddress = (payload: any) => ({
  type: CREATE_ADDRESS,
  payload,
});

export const createAddressSuccess = (payload: any) => ({
  type: CREATE_ADDRESS_SUCCESS,
  payload,
});

export const createAddressFailed = (payload: any) => ({
  type: CREATE_ADDRESS_FAILED,
  payload,
});

export const deleteAddress = (payload: AddressDto) => ({
  type: DELETE_ADDRESS,
  payload,
});

export const deleteAddressSuccess = (payload: any) => ({
  type: DELETE_ADDRESS_SUCCESS,
  payload,
});

export const deleteAddressFailed = (payload: any) => ({
  type: DELETE_ADDRESS_FAILED,
  payload,
});

