import {createStore, compose, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

// export const composeEnhancers =
//   (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const composeEnhancers = (process.env.NODE_ENV !== 'production'
    && window
    && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) /* eslint-disable-line no-underscore-dangle */
  || compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware),
);

const store = createStore(
  rootReducer,
  enhancer,
);

sagaMiddleware.run(rootSaga);

export default store;
