import React from 'react';

export const telecom_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'Office Quick code',
    dataIndex: 'officeQuickCode',
    key: 'officeQuickCode',
    width: 130,
  },
  {
    title: 'Pages URL',
    dataIndex: 'pagesURL',
    key: 'pagesURL',
    width: 100,
    render: (value: string) =>
      value && (
        <a target='_blank' href={value} rel='noreferrer'>
          Link
        </a>
      ),
  },
  {
    title: 'Comm Center',
    dataIndex: 'commCenter',
    key: 'commCenter',
    width: 100,
  },
  {
    title: 'Direct Dial',
    dataIndex: 'directDial',
    key: 'directDial',
    width: 130,
  },
  {
    title: 'Hello Again',
    dataIndex: 'helloAgain',
    key: 'helloAgain',
    width: 130,
  },
  {}, // empty column, workaround to have column width fixed
];
