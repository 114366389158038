import React, { useEffect, useState } from 'react';
import { capitalize, get } from 'lodash';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createLocation, fetchDistricts, fetchRegions } from '../../offices/offices-actions';
import { getDistricts, getLocationDetails, getRegions, getSearchRegionId } from '../../offices/offices-selectors';
import { locationConfig } from '../configs/location-address-config';
import { formItemMap } from '@med-fe/ui';
import { StatusesEnum } from '../../../models/statuses-enum';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { getIsWriteAllAllowed } from '../../../store/user/user-reducer';

interface ILocationDetailsProps {
  locId?: string;
  isCreateMode?: boolean;
  onClose?: any;
}

function LocationDetails({ locId, isCreateMode = false, onClose }: ILocationDetailsProps) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [regionId, setRegionId]: any = useState(null);
  const [districtId, setDistrictId]: any = useState(null);
  const [isDisabled, setIsDisabled]: any = useState(true);
  const name = 'location-details';
  const classNameForm = `${name}-form`;

  const regions = useSelector(getRegions);
  const districts = useSelector(getDistricts);
  const searchRegionId = useSelector(getSearchRegionId);
  const data: any = useSelector((state) => {
    if (locId) {
      // Edit mode
      return getLocationDetails(state, locId);
    } else {
      // Create mode
      return null;
    }
  });
  const tabWritePermissions = useSelector(getWriteTabsPermissions).locations;
  const isWriteAllAllowed = useSelector(getIsWriteAllAllowed);
  const isEditAllowed = tabWritePermissions || isWriteAllAllowed;
  const disCheck = isEditAllowed && !isDisabled ? false : true;

  useEffect(() => {
    if (isCreateMode) {
      form.setFieldsValue({ status: 'HOLD' });
      dispatch(fetchRegions());
    } else {
      form.setFieldsValue({ ...data, status: get(data, 'status', null) });
      setRegionId(get(data, 'region', null));
    }

    return () => {
      dispatch(fetchDistricts(searchRegionId));
      dispatch(fetchRegions());
      form.resetFields();
    };
  }, [locId]);

  useEffect(() => {
    if (regionId) {
      dispatch(fetchDistricts(regionId));
    }
  }, [regionId]);

  const onSubmit = (formData) => {
    const {
      region,
      district,
      county,
      status,
      locationName,
      locationNumber,
      directDial,
      timezone,
      ctc = false,
      ...address
    } = formData;
    const isAddressTouched = form.isFieldsTouched(Object.keys(address));
    const fullAddress = { ...address, lng: data?.lng, lat: data?.lat, type: data?.type };
    let payload = {
      ...(form.isFieldTouched('region') && (region ? { region: { id: region } } : { region: null })),
      ...(form.isFieldTouched('district') &&
        (district ? { district: { id: district } } : region && { district: null })),
      ...(county && form.isFieldTouched('county') && { county }),
      ...(directDial && form.isFieldTouched('directDial') && { directDial }),
      ...(timezone && form.isFieldTouched('timezone') && { timezone }),
      ...(ctc && form.isFieldTouched('ctc') && { ctc }),
      ...(isAddressTouched && {
        address: {
          id: get(data, 'id', null),
          ...fullAddress,
        },
      }),
    };
    if (isCreateMode) {
      payload = {
        ...payload,
        locationName,
        locationNumber,
        status: status || 'HOLD',
      };
    } else {
      payload = {
        id: locId,
        ...payload,
        ...(status && form.isFieldTouched('status') && { status }),
        locationName,
      };
    }
    dispatch(createLocation(payload));
    setIsDisabled(true);
    if (isCreateMode) {
      onClose();
    }
  };

  function resetDistrict() {
    form.setFieldsValue({ district: null });
    form.validateFields(['district']);
  }

  const onRegionSelect = (value) => {
    setRegionId(value);
    resetDistrict();
  };

  const onDistrictSelect = (value) => {
    setDistrictId(value);
  };

  return (
    <Form
      form={form}
      name={name}
      layout='vertical'
      onFinish={onSubmit}
      autoComplete='off'
      className={classNameForm}
      onValuesChange={() => setIsDisabled(false)}
    >
      {Object.entries(locationConfig(isCreateMode)).map(([key, value]: [string, any]) => {
        const data = () => {
          switch (key) {
            case 'region':
              return regions;
            case 'district':
              return districts;
            case 'status':
              return Object.entries(StatusesEnum);
          }
        };
        const formItemParams = {
          ...value,
          data: data(),
          ...(key === 'region' && { onSelect: onRegionSelect }),
          ...(key === 'district' && { onSelect: onDistrictSelect }),
        };
        const checkboxProp: any = key === 'ctc' && { valuePropName: 'checked' };
        return value.isVisible ? (
          <Form.Item
            key={key}
            label={value.label}
            name={key}
            rules={
              key === 'district' || key === 'region'
                ? [{ required: !!regionId || !!districtId, message: `Please select ${capitalize(key)}` }]
                : value.rules
            }
            validateTrigger={['onBlur', 'onSubmit']}
            className={`${classNameForm}-${key}`}
            {...checkboxProp}
          >
            {formItemMap(formItemParams, !isEditAllowed)}
          </Form.Item>
        ) : null;
      })}

      <Form.Item>
        <Button type='primary' htmlType='submit' disabled={disCheck} className={'btn-form-action'}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default LocationDetails;
