import { combineReducers } from 'redux';
import { ADDRESS_STATE_KEY, addressReducer } from './address/address-reducer';
import { LOCATIONS_STATE_KEY, locationsReducer } from '../components/offices/offices-reducer';
import { AUDIT_STATE_KEY, auditReducer } from '../components/audit/audit-reducer';
import { USER_STATE_KEY, userReducer } from './user/user-reducer';
import { coordinatesReducer, LOCATIONS_COORDINATES_STATE_KEY } from '../components/map/map-reducer';
import { SCHEDULES_STATE_KEY, schedulesReducer } from './schedule/schedule-reducer';
import { DOCTORS_STATE_KEY, doctorsReducer } from '../components/doctors/doctors-reducer';
import { headerReducer, HEADER_EXPORT_STATE_KEY } from '../components/header/header-reducer';
import { COMMON_STATE_KEY, commonReducer } from './common/common-reducer';
import { STATISTICS_STATE_KEY, statisticsReducer } from '../components/statistics/statistics-reducer';

const rootReducer = combineReducers({
  [ADDRESS_STATE_KEY]: addressReducer,
  [LOCATIONS_STATE_KEY]: locationsReducer,
  [AUDIT_STATE_KEY]: auditReducer,
  [USER_STATE_KEY]: userReducer,
  [LOCATIONS_COORDINATES_STATE_KEY]: coordinatesReducer,
  [SCHEDULES_STATE_KEY]: schedulesReducer,
  [DOCTORS_STATE_KEY]: doctorsReducer,
  [HEADER_EXPORT_STATE_KEY]: headerReducer,
  [COMMON_STATE_KEY]: commonReducer,
  [STATISTICS_STATE_KEY]: statisticsReducer,
});

export default rootReducer;
