import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Select } from 'antd';
import { setSearchDoctors } from '../../doctors/doctors-actions';
import { defaultSearchDoctors } from '../../doctors/doctors-reducer';
import { getSearchDoctors } from '../../doctors/doctors-selectors';
import { SuffixIcon, TagRenderer } from '@med-fe/ui';
import { get, includes, lowerCase } from 'lodash';
import { getDistricts, getRegions } from '../../offices/offices-selectors';
import { fetchDistricts, fetchRegions } from '../../offices/offices-actions';
import { getAllSpecialties } from '../../../store/common/common-selector';

function SearchDoctors() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const search = useSelector(getSearchDoctors);
  const regions = useSelector(getRegions);
  const districts = useSelector(getDistricts);

  const [regionIds, setRegionIds] = useState<any[]>([]);
  const [districtIds, setDistrictIds] = useState<any[]>([]);
  const specialties = useSelector(getAllSpecialties);

  const docStatusTypes = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
  ];

  const filterDistricts = (regionIds) => {
    let districtIdsFiltered = districtIds.filter((district) =>
      regionIds
        .map((region) => region.value.split('(')[0].trim())
        .includes(district.value.substring(district.value.indexOf('(') + 1, district.value.lastIndexOf(')')))
    );
    setDistrictIds(districtIdsFiltered);
    return districtIdsFiltered;
  };

  const onSubmit = (formData) => {
    const payload = {
      ...defaultSearchDoctors,
      size: search.size,
      ...formData,
      regionIds: regionIds.map((value) => value.id),
      districtIds: districtIds.map((value) => value.id),
    };
    dispatch(setSearchDoctors(payload));
  };

  useEffect(() => {
    dispatch(fetchRegions());
    dispatch(fetchDistricts());

    return () => {
      dispatch(setSearchDoctors(defaultSearchDoctors));
    };
  }, []);

  useEffect(() => {
    dispatch(fetchDistricts(regionIds.map((value) => value.id)));
  }, [regionIds]);

  return (
    <Form
      form={form}
      layout='vertical'
      className='search-container'
      onFinish={(formData) => {
        onSubmit(formData);
      }}
    >
      <Form.Item
        key='employeeSearch'
        label={'Search by Doctor'}
        name='employeeSearch'
        className='label-text input-field'
      >
        <Input allowClear placeholder='Enter Name or Employee Number' />
      </Form.Item>
      <Form.Item
        key='locationSearch'
        label={'Search by Location'}
        name='locationSearch'
        className='label-text input-field'
      >
        <Input allowClear placeholder='Enter Name or Address' />
      </Form.Item>
      <Form.Item key='regionIds' label={'Search by Region'} name='regionIds' className='label-text region-field'>
        <Select
          allowClear
          mode={'multiple'}
          maxTagCount={'responsive'}
          tagRender={(props) => <TagRenderer {...props} />}
          placeholder='...'
          options={regions.map(({ id, name }) => ({ id, value: `${name}`, key: id }))}
          suffixIcon={<SuffixIcon />}
          filterOption={(inputValue, option: any) => {
            const region = option.value.split('(');
            return get(region, '[0]').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
          }}
          onChange={(value, option: [] | any) => {
            if (!option.length) {
              setRegionIds([]);
              setDistrictIds([]);
              form.resetFields(['districtIds', 'regionIds']);
            } else {
              setRegionIds(option);
              form.setFieldsValue({ districtIds: filterDistricts(option).map((value) => value.value) });
            }
          }}
        />
      </Form.Item>
      <Form.Item
        key='districtIds'
        label={'Search by District'}
        name='districtIds'
        className='label-text district-field'
      >
        <Select
          allowClear
          mode={'multiple'}
          maxTagCount={'responsive'}
          tagRender={(props) => <TagRenderer {...props} />}
          placeholder='...'
          options={districts.map(({ id, name, regionName }) => ({ id, value: `${name} (${regionName})`, key: id }))}
          suffixIcon={<SuffixIcon />}
          filterOption={(inputValue, option: any) => {
            const district = option.value.split('(');
            return get(district, '[0]').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
          }}
          onChange={(value, option: [] | any) => {
            if (!option.length) {
              setDistrictIds([]);
              form.setFieldsValue({ districtIds: [] });
            } else {
              setDistrictIds(option);
            }
          }}
        />
      </Form.Item>
      <Form.Item key='statuses' label={'Select Doctor Status'} name='statuses' className='label-text status-type'>
        <Select
          allowClear
          mode={'multiple'}
          tagRender={(props) => <TagRenderer {...props} />}
          options={docStatusTypes}
          suffixIcon={<SuffixIcon />}
        />
      </Form.Item>
      <Form.Item key='bioSearch' label={"Search by Dorctor's Bio"} name='bioSearch' className='label-text input-field'>
        <Input allowClear />
      </Form.Item>
      <Form.Item
        key='specialtyProgramPart'
        name='specialtyProgramPart'
        label='Search by Specialty Program Part'
        className='label-text specialty-part-field'
      >
        <Select
          allowClear
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]}
        />
      </Form.Item>
      <Form.Item
        key='specialtyIds'
        name='specialtyIds'
        label='Search by Specialty'
        className='label-text specialty-field'
      >
        <Select
          allowClear
          mode='multiple'
          tagRender={(props) => <TagRenderer {...props} />}
          options={specialties.map(({ fullName, id }) => ({ label: fullName, value: id }))}
          maxTagCount='responsive'
          filterOption={(input, { label }) => includes(lowerCase(label), lowerCase(input))}
        />
      </Form.Item>
      <Form.Item className='search-btn'>
        <Button type='primary' htmlType='submit'>
          SEARCH
        </Button>
      </Form.Item>
    </Form>
  );
}

export default SearchDoctors;
