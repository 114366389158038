import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDoctors, setSearchDoctors } from './doctors-actions';
import { getIsLoading, getDoctorsTotal, getSearchDoctors } from './doctors-selectors';

import BaseGrid from '../grid/BaseGrid';
import SearchDoctors from '../header/search/SearchDoctors';

function Doctors() {
  const dispatch = useDispatch();
  const total = useSelector(getDoctorsTotal);
  const search = useSelector(getSearchDoctors);
  const loading = useSelector(getIsLoading);

  useEffect(() => {
    dispatch(fetchDoctors(search));
  }, [search]);

  const onPageChange = (searchData) => {
    dispatch(setSearchDoctors(searchData));
  };

  return (
    <>
      <SearchDoctors />
      <BaseGrid id={'doctors'} total={total} search={search} setSearch={onPageChange} loading={loading} />
    </>
  );
}

export default Doctors;
