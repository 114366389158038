import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProServiceForLocation } from '../../offices/offices-selectors';
import { capitalize } from 'lodash';
import { createLocation } from '../../offices/offices-actions';
import { proServiceConfig } from '../configs/pro-service-config';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { formItemMap } from '@med-fe/ui';
import { getEquipment } from '../../../store/common/common-selector';

function ProServiceDetails({ locId }) {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector((state) => getProServiceForLocation(state, locId));
  const isEditAllowed = useSelector(getWriteTabsPermissions).pro;
  const equipment = useSelector(getEquipment);

  useEffect(() => {
    form.setFieldsValue({ ...data });
  }, [locId]);

  const onSubmit = (formData: any) => {
    const payload = {
      ...formData,
      id: locId,
      locationEquipments: equipment.filter(({ id }) => formData.locationEquipments.includes(id)),
    };

    dispatch(createLocation(payload));
  };

  return (
    <Form
      form={form}
      layout='vertical'
      className='pro-service-details-form'
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
      }}
      onValuesChange={() => setIsDisabled(false)}
    >
      {Object.entries(proServiceConfig).map(([key, value]: any) => {
        const params = {
          key,
          label: value.label,
          name: key,
          ...(value.type === 'checkbox' && { valuePropName: 'checked' }),
          rules: value.rules,
        };
        return (
          <Form.Item
            {...params}
            className={value.type === 'checkbox' ? 'checkbox-item' : ''}
            validateTrigger={['onBlur', 'onSubmit']}
          >
            {formItemMap({ ...value, data: key === 'locationEquipments' ? equipment : null }, !isEditAllowed)}
          </Form.Item>
        );
      })}

      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ProServiceDetails;
