import { AutoComplete, Button, Checkbox, Divider, Form, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { get, omit } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignmentCount, getContacts } from '../../offices/offices-selectors';
import {
  fetchContacts,
  fetchLocationAssignmentCount,
  fetchLocationAssignmentCountSuccess,
  updateContact,
  setSelectedStaff,
} from '../../offices/offices-actions';
import { staffDetailsConfig } from '../configs/staff-details-config';
import { formItemMap } from '@med-fe/ui';
import { getIsWriteAllAllowed } from '../../../store/user/user-reducer';
import styled from 'styled-components';
import { getAllSpecialties } from '../../../store/common/common-selector';
import { SPECIALTIES_ICONS_MAP } from '../../../constants';
import { getIsWriteContactAllowed } from '../../../store/user/user-reducer';

interface IManagerDetailsProps {
  isCreateMode?: boolean;
  data?: any;
  locId: string;
  title: string;
  onClose?: any;
}

function ManagerDetails({ isCreateMode = false, data, locId, title, onClose }: IManagerDetailsProps) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const classNameForm = 'manager-details-form';
  const [isDisabled, setIsDisabled] = useState(true);
  const { locations: assignmentCount } = useSelector(getAssignmentCount);
  const contacts = useSelector(getContacts);
  //const isEditAllowed = useSelector(getIsWriteContactAllowed);
  const allSpecialties = useSelector(getAllSpecialties);
  const contactId = get(data, 'id', null);
  const [contact, setContact]: any = useState(null);
  const [specialtyValues, setSpecialtyValues] = useState({});
  const [isDisabledSpecialties, setIsDisabledSpecialties] = useState(false);
  const [isDisabledProgram, setIsDisabledProgram] = useState(false);
  const [isTouchedSpecialties, setIsTouchedSpecialties] = useState(false);
  //const isWriterAllAllowed = useSelector(getIsWriteAllAllowed);

  const isEditAllowed = useSelector(getIsWriteContactAllowed) || useSelector(getIsWriteAllAllowed);
  const disCheck = (isEditAllowed && !isDisabled) ? false : true;

  useEffect(() => {
    if (!!contactId) {
      if (title === 'managers') {
        dispatch(fetchLocationAssignmentCount(contactId));
      }

      if (title === 'doctors') {
        updateSpecialtyValues(data.specialties);
        setIsDisabledSpecialties(!data.specialtyProgramPart);
      }
    }

    return () => {
      setSpecialtyValues({});
      dispatch(fetchLocationAssignmentCountSuccess({ id: null, locations: 0 }));
      form.resetFields();
    };
  }, [data]);

  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        ...contact,
        name: contact.value,
        ...(!!contact.startDate && { startDate: moment(contact.startDate) }),
      });
      dispatch(fetchLocationAssignmentCount(contact.id));
      setIsDisabledProgram(true);
      setIsDisabledSpecialties(true);
      updateSpecialtyValues(contact.specialties);
    } else {
      form.setFieldsValue(null);
    }
  }, [contact]);

  useEffect(() => {
    if (allSpecialties.length) {
      const docsSpecialties = contact ? contact?.specialties : data?.specialties;

      if (docsSpecialties) {
        setSpecialtyValues(
          allSpecialties.reduce(
            (acc, current) => ({ ...acc, [current.id]: !!docsSpecialties?.find((spec) => current.id === spec.id) }),
            {}
          )
        );
      } else {
        setIsDisabledSpecialties(true);
      }
    }
  }, [allSpecialties]);

  const onSubmit = (formData) => {
    const data = omit(formData, ['job', 'search']);
    const payload = {};
    const specialties = allSpecialties.filter(({ id }) => !!specialtyValues[id]);

    if (isCreateMode) {
      if (!!contact) {
        payload['assign'] = {
          job: title === 'doctors' ? 'OD' : formData.job,
          contactId: contact.id,
          locationId: locId,
        };
      } else {
        payload['info'] = {
          name: data.name,
          npi: data.npi,
          phone: data.phone,
          email: data.email === undefined ? '' : data.email,
          startDate: data.startDate,
          specialtyProgramPart: data.specialtyProgramPart,
          specialties,
        };
        payload['assign'] = { job: title === 'doctors' ? 'OD' : formData.job, locationId: locId };
      }
    } else {
      if (
        form.isFieldsTouched(['email', 'name', 'phone', 'startDate', 'npi', 'specialtyProgramPart']) ||
        isTouchedSpecialties
      ) {
        payload['info'] = {
          id: contactId,
          locId,
          ...data,
          ...(!!formData.startDate && { startDate: formData.startDate.format('YYYY-MM-DD') }),
          specialties,
        };
      }
      if (form.isFieldTouched('job')) {
        payload['assign'] = { job: formData.job, contactId, locationId: locId };
      }
    }
    setIsDisabled(true);
    dispatch(updateContact(payload));
    form.resetFields();
    setContact(null);
    if (isCreateMode) {
      onClose();
    } else {
      dispatch(setSelectedStaff({}));
    }
  };

  const updateSpecialtyValues = (specialties = []) => {
    setSpecialtyValues({ ...specialties.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}) });
  };

  const handleOnChange = (id, event?) => {
    setSpecialtyValues({ ...specialtyValues, [id]: !specialtyValues[id] });
    setIsDisabled(false);
    setIsTouchedSpecialties(true);
  };

  const handleSpecialtyProgram = (checked, isManualChange = false) => {
    setIsDisabledSpecialties(!checked);
  };

  return (
    <>
      {isCreateMode ? null : (
        <>
          <Divider />
          <div className='manager-details-form-header'>
            <span>{` ${title.toUpperCase()} DETAILS`}</span>
            <Button onClick={() => dispatch(setSelectedStaff({}))}>&#x2715;</Button>
          </div>
        </>
      )}
      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        className={classNameForm}
        onFinish={onSubmit}
        onValuesChange={() => setIsDisabled(false)}
        initialValues={
          isCreateMode ? null : { ...data, ...(get(data, 'startDate', false) && { startDate: moment(data.startDate) }) }
        }
      >
        {isCreateMode ? (
          <Form.Item
            key={'search'}
            label={'Search by Name/Surname'}
            name={'search'}
            className={`${classNameForm}-${'search'}`}
          >
            <AutoComplete
              allowClear
              options={contacts.map(({ name, ...info }) => ({ ...info, value: name }))}
              placeholder='...'
              filterOption={(inputValue, option: any) => {
                return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
              }}
              onChange={(value) => {
                if (!value) {
                  form.resetFields();
                  setContact(null);
                } else {
                  dispatch(fetchContacts({ name: value }));
                }
              }}
              onSelect={(value, option) => setContact(option)}
            />
          </Form.Item>
        ) : null}
        {Object.entries(staffDetailsConfig(title, isCreateMode)).map(([key, value]: any) =>
          value.isVisible ? (
            title !== 'doctors' && value.label !== 'Npi' ? (
              <Form.Item
                key={key}
                label={value.label}
                name={key}
                className={`${classNameForm}-${key}`}
                rules={value.rules}
                validateTrigger={['onBlur', 'onSubmit']}
              >
                {formItemMap(value, (!!contact && key !== 'job') || (value.disabled && !isEditAllowed))}
              </Form.Item>
            ) : title === 'doctors' ? (
              <Form.Item
                key={key}
                label={value.label}
                name={key}
                className={`${classNameForm}-${key}`}
                rules={value.rules}
                validateTrigger={['onBlur', 'onSubmit']}
              >
                {formItemMap(value, (!!contact && key !== 'job') || (value.disabled && !isEditAllowed))}
              </Form.Item>
            ) : null
          ) : null
        )}

        {!!assignmentCount && ((isCreateMode && !!contact) || contactId) ? (
          <div className='assigned-text'>{`Assigned to ${assignmentCount} locations`}</div>
        ) : null}

        {title === 'doctors' && (
          <>
            <SpecialtyProgramWrapper>
              <span>SPECIALTY PROGRAM</span>
              <Form.Item key={'specialtyProgramPart'} name={'specialtyProgramPart'} valuePropName='checked'>
                <SpecialtySwitcher
                  size={'small'}
                  disabled={isDisabledProgram}
                  onChange={(checked) => handleSpecialtyProgram(checked, true)}
                />
              </Form.Item>
            </SpecialtyProgramWrapper>

            {allSpecialties?.map(({ shortName, fullName, id }) => (
              <SpecialtyItem key={shortName}>
                <div>
                  <img
                    src={`./icons/specialties/${SPECIALTIES_ICONS_MAP[shortName] || SPECIALTIES_ICONS_MAP.DEFAULT}.svg`}
                    alt={shortName}
                  />
                  <span>{fullName}</span>
                </div>
                <Checkbox
                  checked={specialtyValues[id]}
                  disabled={isDisabledSpecialties}
                  onChange={(event) => handleOnChange(id, event)}
                />
              </SpecialtyItem>
            ))}
          </>
        )}

        <Form.Item>
          <Button type='primary' htmlType='submit' disabled={disCheck} className={'btn-form-action'}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default ManagerDetails;

const SpecialtyProgramWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  margin: 20px 0 5px;
  color: #003366;
`;

const SpecialtyItem = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-bottom: 2px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    height: 25px;
    margin-right: 10px;
  }
`;

const SpecialtySwitcher = styled(Switch)`
  background-color: #40668c;
`;
