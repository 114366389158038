import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getItAccessDetailsForLocation,
  getItDetailsForLocation,
  getItEquipmentDetailsForLocation,
  getItLanIPDetailsForLocation,
  getItPrimaryCircuitDetailsForLocation,
} from '../../offices/offices-selectors';
import { capitalize } from 'lodash';
import { createLocation } from '../../offices/offices-actions';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { formItemMap } from '@med-fe/ui';
import { internetDetailsConfig } from '../configs/internet-details-config';
import { internetAccessDetailsConfig } from '../configs/internet-access-details-config';
import { internetEquipmentDetailsConfig } from '../configs/internet-equipment-details-config';
import { internetLanIPDetailsConfig } from '../configs/internet-lan-ip-details-config';
import { internetPrimaryCircuitDetailsConfig } from '../configs/internet-primary-circuit-details-config';
import moment from 'moment';

function ITDetails({ locId, title }) {
  const selectorMap = {
    internet_details: {
      data: getItDetailsForLocation,
      config: internetDetailsConfig,
    },
    internet_access_details: {
      data: getItAccessDetailsForLocation,
      config: internetAccessDetailsConfig,
    },
    internet_equipment_details: {
      data: getItEquipmentDetailsForLocation,
      config: internetEquipmentDetailsConfig,
    },
    internet_lan_ip_details: {
      data: getItLanIPDetailsForLocation,
      config: internetLanIPDetailsConfig,
    },
    internet_primary_circuit_details: {
      data: getItPrimaryCircuitDetailsForLocation,
      config: internetPrimaryCircuitDetailsConfig,
    },
  };

  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector((state) => selectorMap[title]['data'](state, locId));

  const isEditAllowed = useSelector(getWriteTabsPermissions).it;
  const initialITDetails = useSelector((state) => {
    return {
      ...selectorMap.internet_details.data(state, locId),
      ...selectorMap.internet_access_details.data(state, locId),
      ...selectorMap.internet_equipment_details.data(state, locId),
      ...selectorMap.internet_lan_ip_details.data(state, locId),
      ...selectorMap.internet_primary_circuit_details.data(state, locId),
    };
  });

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      ...(!!data.completionDate && { completionDate: moment(data.completionDate) }),
    });
  }, [locId]);

  const onSubmit = (formData: any) => {
    let payload = {
      id: locId,
      locationInfra: [
        {
          ...initialITDetails,
          ...formData,
          ...(!!formData.completionDate && { completionDate: formData.completionDate.format('YYYY-MM-DD') }),
        },
      ],
    };

    dispatch(createLocation(payload));
  };

  return (
    <Form
      form={form}
      layout='vertical'
      className='it-details-form'
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
      }}
      onValuesChange={() => setIsDisabled(false)}
    >
      {Object.entries(selectorMap[title]['config']()).map(([key, value]: any) => {
        const params = {
          key,
          label: capitalize(value.label),
          name: key,
          ...(value.type === 'checkbox' && { valuePropName: 'checked' }),
        };

        return (
          <Form.Item
            {...params}
            className={value.type === 'checkbox' ? 'checkbox-item' : ''}
            validateTrigger={['onBlur', 'onSubmit']}
          >
            {formItemMap(value, !isEditAllowed || key === 'modifiedDate')}
          </Form.Item>
        );
      })}

      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ITDetails;
