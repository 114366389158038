import React from 'react';
import { Card, Tabs, Tooltip, Typography, Badge } from 'antd';
import styled from 'styled-components';

const { TabPane } = Tabs;
const { Text } = Typography;

export const OfficeGridTabs = ({ locationNumber, tabs }) => {
  const tooltipInnerStyle = {
    color: 'black',
    fontSize: '11px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    padding: '7px 0 7px 7px',
  };

  const generateValue = (value) => {
    let result = value;
    if (typeof value === 'boolean') {
      if (value) {
        result = 'Yes';
      } else {
        result = 'No';
      }
    }

    return result;
  };

  return (
    <StyledOfficeGridTabs className={'common-tabs'}>
      {tabs.map((tab) => (
        <StyledTabPane
          key={`${locationNumber}_${tab.name}`}
          tab={
            tab.hasError ? (
              <>
                <span>{tab.name}</span>
                <Badge dot offset={[5, -7]}></Badge>
              </>
            ) : (
              <span>{tab.name}</span>
            )
          }
        >
          {tab.fields.map((field) => (
            <StyledCard key={`${locationNumber}_${tab.name}_${field.fieldName}`} title={field.fieldName}>
              <div>
                <Text strong>From: </Text>
                <Text>{generateValue(field.currentValue)}</Text>
              </div>
              <div>
                <Text strong>To: </Text>
                {field.errorMessage ? (
                  <Tooltip
                    title={field.errorMessage}
                    color='white'
                    overlayInnerStyle={tooltipInnerStyle}
                    placement='left'
                    autoAdjustOverflow={false}
                  >
                    <Text style={{ color: '#d14d4d' }}>{generateValue(field.newValue)}</Text>
                  </Tooltip>
                ) : (
                  <Text>{generateValue(field.newValue)}</Text>
                )}
              </div>
            </StyledCard>
          ))}
        </StyledTabPane>
      ))}
    </StyledOfficeGridTabs>
  );
};

const StyledOfficeGridTabs = styled(Tabs)`
  .ant-tabs-nav {
    .ant-tabs-nav-more {
      padding: 0 16px;
    }

    .ant-tabs-tab {
      position: relative;
      overflow: visible;

      .invalid-tab-asterisk {
        color: #d14d4d;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`;

const StyledTabPane = styled(TabPane)`
  height: 300px;
  overflow: auto;

  .ant-tabs-nav-more {
    padding: 0 16px;
  }
`;

const StyledCard = styled(Card)`
  font-size: 12px;
  margin-bottom: 10px;

  .ant-card-head {
    min-height: 25px;
    padding: 0 15px;

    .ant-card-head-title {
      font-size: 12px;
      padding: 5px 0;
      font-weight: bold;
    }
  }

  .ant-card-body {
    padding: 8px 15px;

    > :first-child {
      margin-bottom: 5px;
    }
  }
`;
