export const FETCH_DOCTORS = `FETCH_DOCTORS`;
export const FETCH_DOCTORS_SUCCESS = `FETCH_DOCTORS_SUCCESS`;
export const FETCH_DOCTORS_FAILED = `FETCH_DOCTORS_FAILED`;
export const SET_SEARCH_DOCTORS = `SET_SEARCH_DOCTORS`;

export const FETCH_DOCTOR_BY_ID = `FETCH_DOCTOR_BY_ID`;
export const FETCH_DOCTOR_BY_ID_SUCCESS = `FETCH_DOCTOR_BY_ID_SUCCESS`;
export const FETCH_DOCTOR_BY_ID_FAILED = `FETCH_DOCTOR_BY_ID_FAILED`;

export const UPDATE_DOCTOR = `UPDATE_DOCTOR`;
export const UPDATE_DOCTOR_SUCCESS = `UPDATE_DOCTOR_SUCCESS`;
export const UPDATE_DOCTOR_FAILED = `UPDATE_DOCTOR_FAILED`;

export const UPLOAD_DOCTOR_PHOTO = `UPLOAD_DOCTOR_PHOTO`;
export const UPLOAD_DOCTOR_PHOTO_SUCCESS = `UPLOAD_DOCTOR_PHOTO_SUCCESS`;
export const UPLOAD_DOCTOR_PHOTO_FAILED = `UPLOAD_DOCTOR_PHOTO_FAILED`;

export const SET_SELECTED_DOCTOR_SPECIALTIES = 'SET_SELECTED_DOCTOR_SPECIALTIES';

export const fetchDoctors = (payload: any) => ({
  type: FETCH_DOCTORS,
  payload,
});

export const fetchDoctorsSuccess = (payload: any) => ({
  type: FETCH_DOCTORS_SUCCESS,
  payload,
});

export const fetchDoctorsFailed = (payload: any) => ({
  type: FETCH_DOCTORS_FAILED,
  payload,
});

export const setSearchDoctors = (payload: any) => ({
  type: SET_SEARCH_DOCTORS,
  payload,
});

export const fetchDoctorById = (payload: any) => ({
  type: FETCH_DOCTOR_BY_ID,
  payload,
});

export const fetchDoctorByIdSuccess = (payload: any) => ({
  type: FETCH_DOCTOR_BY_ID_SUCCESS,
  payload,
});

export const fetchDoctorByIdFailed = (payload: any) => ({
  type: FETCH_DOCTOR_BY_ID_FAILED,
  payload,
});

export const updateDoctor = (payload: any) => ({
  type: UPDATE_DOCTOR,
  payload,
});

export const updateDoctorSuccess = (payload: any) => ({
  type: UPDATE_DOCTOR_SUCCESS,
  payload,
});

export const updateDoctorFailed = (payload: any) => ({
  type: UPDATE_DOCTOR_FAILED,
  payload,
});

export const uploadDoctorPhoto = (payload: any) => ({
  type: UPLOAD_DOCTOR_PHOTO,
  payload,
});

export const uploadDoctorPhotoSuccess = (payload: any) => ({
  type: UPLOAD_DOCTOR_PHOTO_SUCCESS,
  payload,
});

export const uploadDoctorPhotoFailed = (payload: any) => ({
  type: UPLOAD_DOCTOR_PHOTO_FAILED,
  payload,
});

export const setSelectedDoctorSpecialties = (payload: any) => ({
  type: SET_SELECTED_DOCTOR_SPECIALTIES,
  payload,
});
