import React from 'react';
import { CheckBoxCell } from '@med-fe/ui';

export const finance_columns = [
  {
    title: 'Location number',
    dataIndex: 'locationNumber',
    key: 'action',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Location name',
    dataIndex: 'locationName',
    key: 'locationName',
    width: 250,
  },
  {
    title: 'legal name',
    dataIndex: 'legalName',
    key: 'legalName',
    width: 260,
  },
  {
    title: 'tax id',
    dataIndex: 'taxid',
    key: 'taxid',
    width: 160,
  },
  {
    title: 'merchant bank account number',
    dataIndex: 'merchantBankAccountNumber',
    key: 'merchantBankAccountNumber',
    width: 160,
  },
  {
    title: 'dr of record',
    dataIndex: 'drOfRecord',
    key: 'drOfRecord',
    width: 100,
  },
  {
    title: 'ORACLE LEGAL ENTITY NUM',
    dataIndex: 'oracleLegalEntityNum',
    key: 'oracleLegalEntityNum',
    width: 80,
  },
  {
    title: 'ORACLE OFFICE NUM',
    dataIndex: 'oracleOfficeNum',
    key: 'oracleOfficeNum',
    width: 120,
  },
  {
    title: 'depository bank account',
    dataIndex: 'depositoryBankAccount',
    key: 'depositoryBankAccount',
    width: 80,
  },
  {
    title: 'worldpay merchantid',
    dataIndex: 'worldpayMerchantId',
    key: 'worldpayMerchantId',
    width: 150,
  },
  {
    title: 'care credit merchantid',
    dataIndex: 'careCreditMerchantId',
    key: 'careCreditMerchantId',
    width: 160,
  },
  {
    title: 'worldpay serial number',
    dataIndex: 'worldpaySerialNumber',
    key: 'worldpaySerialNumber',
    width: 100,
  },
  {
    title: 'close date',
    dataIndex: 'closedDate',
    key: 'closedDate',
    width: 100,
  },
  {
    title: 'vintage name',
    dataIndex: 'vintageName',
    key: 'vintageName',
    width: 100,
  },
  {
    title: 'merged into',
    dataIndex: 'mergedInto',
    width: 90,
    key: 'mergedInto',
  },
  {
    title: 'deposit method',
    dataIndex: 'depositMethod',
    width: 100,
    key: 'depositMethod',
  },
  {
    title: 'integration',
    dataIndex: 'integration',
    key: 'integration',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'same office sales',
    dataIndex: 'sameOfficeSales',
    key: 'sameOfficeSales',
    width: 100,
    colType: 'checkbox',
    render: (value: boolean) => <CheckBoxCell value={value} />,
  },
  {
    title: 'same office change date',
    dataIndex: 'sameOfficeChangeDate',
    key: 'sameOfficeChangeDate',
    width: 100,
  },
  {
    title: 'acuity logic launch',
    dataIndex: 'acuityLogicLaunchDate',
    key: 'acuityLogicLaunchDate',
    width: 160,
  },
  {
    title: 'POS',
    dataIndex: 'pos',
    key: 'pos',
    width: 160,
  },
  {
    title: 'Encompass Launch Date',
    dataIndex: 'encompassLaunchDate',
    key: 'encompassLaunchDate',
    width: 160,
  },
  {}, // empty column, workaround to have column width fixed
];
